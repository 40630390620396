import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
  UUID: string;
};

export type Aggregate = {
  __typename?: 'Aggregate';
  cypherCode: Scalars['String'];
  externalId: Scalars['String'];
  id: Scalars['UUID'];
  logic?: Maybe<AggregationLogic>;
  name: Scalars['String'];
  objectClassType: Scalars['String'];
  objectName: Scalars['String'];
  output?: Maybe<Output>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type AggregatedData = {
  __typename?: 'AggregatedData';
  timestamp: Scalars['DateTime'];
  value: Scalars['Float'];
  valueCount: Scalars['Float'];
  valueMax: Scalars['Float'];
  valueMin: Scalars['Float'];
};

export enum AggregationIntervals {
  D_01 = 'D_01',
  D_02 = 'D_02',
  D_03 = 'D_03',
  D_04 = 'D_04',
  D_05 = 'D_05',
  D_06 = 'D_06',
  D_07 = 'D_07',
  H_01 = 'H_01',
  H_02 = 'H_02',
  H_03 = 'H_03',
  H_04 = 'H_04',
  H_06 = 'H_06',
  H_08 = 'H_08',
  H_12 = 'H_12',
  Ms_500 = 'MS_500',
  M_01 = 'M_01',
  M_02 = 'M_02',
  M_03 = 'M_03',
  M_04 = 'M_04',
  M_05 = 'M_05',
  M_06 = 'M_06',
  M_10 = 'M_10',
  M_12 = 'M_12',
  M_15 = 'M_15',
  M_20 = 'M_20',
  M_30 = 'M_30',
  None = 'NONE',
  S_01 = 'S_01',
  S_02 = 'S_02',
  S_03 = 'S_03',
  S_04 = 'S_04',
  S_05 = 'S_05',
  S_06 = 'S_06',
  S_10 = 'S_10',
  S_12 = 'S_12',
  S_15 = 'S_15',
  S_20 = 'S_20',
  S_30 = 'S_30'
}

export type AggregationLogic = {
  __typename?: 'AggregationLogic';
  aggregationLogicType: AggregationLogicType;
  aggregationType?: Maybe<AggregationType>;
  cypherCode: Scalars['String'];
  id: Scalars['UUID'];
  isValid: Scalars['Boolean'];
  logic?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  objectClassType: Scalars['String'];
  objectName: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
  timeWindow?: Maybe<TimeWindow>;
  variables: Array<Variable>;
};

export enum AggregationLogicType {
  Custom = 'CUSTOM',
  RoomAirTwAvgPpm = 'ROOM_AIR_TW_AVG_PPM',
  RoomHumidityDeviation = 'ROOM_HUMIDITY_DEVIATION',
  RoomHumidityTwAvgDeviation = 'ROOM_HUMIDITY_TW_AVG_DEVIATION',
  RoomHumidityTwAvgHumidity = 'ROOM_HUMIDITY_TW_AVG_HUMIDITY',
  RoomTempDeviation = 'ROOM_TEMP_DEVIATION',
  RoomTempTwAvgDeviation = 'ROOM_TEMP_TW_AVG_DEVIATION',
  RoomTempTwAvgTemp = 'ROOM_TEMP_TW_AVG_TEMP',
  SensorStabilityDeviation = 'SENSOR_STABILITY_DEVIATION',
  SensorStabilityTwAvgDeviation = 'SENSOR_STABILITY_TW_AVG_DEVIATION',
  SystemPerformanceDeviation = 'SYSTEM_PERFORMANCE_DEVIATION',
  SystemPerformanceTwAvgDeviation = 'SYSTEM_PERFORMANCE_TW_AVG_DEVIATION'
}

export enum AggregationType {
  None = 'NONE',
  OperatingTimeBinary = 'OPERATING_TIME_BINARY',
  TimeWeightedAvg = 'TIME_WEIGHTED_AVG'
}

export enum AggregationTypes {
  Avg = 'AVG',
  Sum = 'SUM'
}

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type Attribute = {
  __typename?: 'Attribute';
  dataType: DataTypes;
  description?: Maybe<Scalars['String']>;
  deviceAttributeValues: Array<DeviceAttributeValue>;
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  mappingAttributeDeviceModelAttributeSets: Array<MappingAttributeDeviceModelAttributeSet>;
  name: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['UUID']>;
};


export type AttributeDeviceAttributeValuesArgs = {
  order?: InputMaybe<Array<DeviceAttributeValueSortInput>>;
  where?: InputMaybe<DeviceAttributeValueFilterInput>;
};


export type AttributeMappingAttributeDeviceModelAttributeSetsArgs = {
  order?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetSortInput>>;
  where?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
};

export type AttributeCreateType = {
  dataType: DataTypes;
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  required?: InputMaybe<Scalars['Boolean']>;
};

export type AttributeDefinition = {
  __typename?: 'AttributeDefinition';
  dataType: DataTypes;
  description?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
};

export type AttributeDefinitionFilterInput = {
  and?: InputMaybe<Array<AttributeDefinitionFilterInput>>;
  dataType?: InputMaybe<DataTypesOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AttributeDefinitionFilterInput>>;
  required?: InputMaybe<BooleanOperationFilterInput>;
};

export type AttributeFilterInput = {
  and?: InputMaybe<Array<AttributeFilterInput>>;
  attributeId?: InputMaybe<UuidOperationFilterInput>;
  dataType?: InputMaybe<DataTypesOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  deviceAttributeValues?: InputMaybe<ListFilterInputTypeOfDeviceAttributeValueFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingAttributeDeviceModelAttributeSets?: InputMaybe<ListFilterInputTypeOfMappingAttributeDeviceModelAttributeSetFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AttributeFilterInput>>;
  required?: InputMaybe<BooleanOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type AttributeIdAttributeValueInputType = {
  attributeId?: InputMaybe<Scalars['UUID']>;
  value?: InputMaybe<Scalars['String']>;
};

export type AttributeSetNullInputType = {
  dataType?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  deviceAttributeValues?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingAttributeDeviceModelAttributeSets?: InputMaybe<Scalars['Boolean']>;
  required?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type AttributeSortInput = {
  attributeId?: InputMaybe<SortEnumType>;
  dataType?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  required?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type AttributeUpdateType = {
  dataType?: InputMaybe<DataTypes>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
};

export type AttributeWithValue = {
  __typename?: 'AttributeWithValue';
  attribute?: Maybe<Attribute>;
  value?: Maybe<Scalars['String']>;
};

export type AttributesByBuildings = {
  __typename?: 'AttributesByBuildings';
  buildingId?: Maybe<Scalars['UUID']>;
  deviceAttributes?: Maybe<Array<AttributeDefinition>>;
};

/** A segment of a collection. */
export type AttributesByBuildingsCollectionSegment = {
  __typename?: 'AttributesByBuildingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<AttributesByBuildings>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type AttributesByBuildingsFilterInput = {
  and?: InputMaybe<Array<AttributesByBuildingsFilterInput>>;
  buildingId?: InputMaybe<UuidOperationFilterInput>;
  deviceAttributes?: InputMaybe<ListFilterInputTypeOfAttributeDefinitionFilterInput>;
  or?: InputMaybe<Array<AttributesByBuildingsFilterInput>>;
};

export type AttributesByBuildingsSortInput = {
  buildingId?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type AttributesCollectionSegment = {
  __typename?: 'AttributesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Attribute>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type AuditLog = {
  __typename?: 'AuditLog';
  action?: Maybe<Scalars['String']>;
  changeDate: Scalars['DateTime'];
  entity?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  service?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
  userId: Scalars['UUID'];
};

export type AuditLogCreateType = {
  action?: InputMaybe<Scalars['String']>;
  changeDate: Scalars['DateTime'];
  entity?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<Scalars['String']>;
  userId: Scalars['UUID'];
};

export type AuditLogFilterInput = {
  action?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<AuditLogFilterInput>>;
  auditLogId?: InputMaybe<UuidOperationFilterInput>;
  changeDate?: InputMaybe<DateTimeOperationFilterInput>;
  entity?: InputMaybe<StringOperationFilterInput>;
  entityType?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<AuditLogFilterInput>>;
  service?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  userId?: InputMaybe<UuidOperationFilterInput>;
};

export type AuditLogSortInput = {
  action?: InputMaybe<SortEnumType>;
  auditLogId?: InputMaybe<SortEnumType>;
  changeDate?: InputMaybe<SortEnumType>;
  entity?: InputMaybe<SortEnumType>;
  entityType?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  service?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type AuditLogsCollectionSegment = {
  __typename?: 'AuditLogsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<AuditLog>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

/** I am a generated type! */
export type Building = {
  __typename?: 'Building';
  description?: Maybe<Scalars['String']>;
  dimension?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['UUID']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['UUID']>;
  mappingDeviceBuildings?: Maybe<Array<Maybe<MappingDeviceBuilding>>>;
  name: Scalars['String'];
  site: Site;
  siteId: Scalars['UUID'];
  storeys: Array<Storey>;
  tenantId?: Maybe<Scalars['UUID']>;
  weather?: Maybe<Weather>;
};


/** I am a generated type! */
export type BuildingStoreysArgs = {
  order?: InputMaybe<Array<StoreySortInput>>;
  where?: InputMaybe<StoreyFilterInput>;
};

export type BuildingCreateType = {
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  dimension?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['UUID']>;
  locationId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  siteId: Scalars['UUID'];
};

export type BuildingFilterInput = {
  and?: InputMaybe<Array<BuildingFilterInput>>;
  buildingId?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  dimension?: InputMaybe<StringOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  location?: InputMaybe<LocationFilterInput>;
  locationId?: InputMaybe<UuidOperationFilterInput>;
  mappingDeviceBuildings?: InputMaybe<ListFilterInputTypeOfMappingDeviceBuildingFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BuildingFilterInput>>;
  site?: InputMaybe<SiteFilterInput>;
  siteId?: InputMaybe<UuidOperationFilterInput>;
  storeys?: InputMaybe<ListFilterInputTypeOfStoreyFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  weather?: InputMaybe<WeatherFilterInput>;
};

/** A segment of a collection. */
export type BuildingFilterTableCollectionSegment = {
  __typename?: 'BuildingFilterTableCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PlacementFilterRow>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type BuildingSetNullInputType = {
  description?: InputMaybe<Scalars['Boolean']>;
  dimension?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['Boolean']>;
  imageId?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  site?: InputMaybe<Scalars['Boolean']>;
  storeys?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type BuildingSortInput = {
  buildingId?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  dimension?: InputMaybe<SortEnumType>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  location?: InputMaybe<LocationSortInput>;
  locationId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  site?: InputMaybe<SiteSortInput>;
  siteId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  weather?: InputMaybe<WeatherSortInput>;
};

export type BuildingUpdateType = {
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  dimension?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  imageId?: InputMaybe<Scalars['UUID']>;
  locationId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['UUID']>;
};

/** A segment of a collection. */
export type BuildingsCollectionSegment = {
  __typename?: 'BuildingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Building>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** A segment of a collection. */
export type CapabilitiesCollectionSegment = {
  __typename?: 'CapabilitiesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Capability>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type Capability = {
  __typename?: 'Capability';
  dataType: DataTypes;
  description?: Maybe<Scalars['String']>;
  deviceModelCapabilities: Array<DeviceModelCapability>;
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
};


export type CapabilityDeviceModelCapabilitiesArgs = {
  order?: InputMaybe<Array<DeviceModelCapabilitySortInput>>;
  where?: InputMaybe<DeviceModelCapabilityFilterInput>;
};

export type CapabilityCreateType = {
  dataType: DataTypes;
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
};

export type CapabilityFilterInput = {
  and?: InputMaybe<Array<CapabilityFilterInput>>;
  capabilityId?: InputMaybe<UuidOperationFilterInput>;
  dataType?: InputMaybe<DataTypesOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  deviceModelCapabilities?: InputMaybe<ListFilterInputTypeOfDeviceModelCapabilityFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CapabilityFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type CapabilitySetNullInputType = {
  dataType?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  deviceModelCapabilities?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type CapabilitySortInput = {
  capabilityId?: InputMaybe<SortEnumType>;
  dataType?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type CapabilityUpdateType = {
  dataType?: InputMaybe<DataTypes>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

export type CategorizedInstallationParts = {
  __typename?: 'CategorizedInstallationParts';
  category: CategoryType;
  installationParts: Array<BuildingInformationSchema_InstallationPart>;
};

export type CategorizedInstallationPartsScores = {
  __typename?: 'CategorizedInstallationPartsScores';
  category: CategoryType;
  installationPartScores: Array<InstallationPartScores>;
};

export type CategoryScoresInput = {
  category: CategoryType;
  scoreTypes: Array<ScoreType>;
};

export enum CategoryType {
  Actuators = 'ACTUATORS',
  Components = 'COMPONENTS',
  ControlLoop = 'CONTROL_LOOP',
  Sensors = 'SENSORS'
}

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
};

export enum ConnectionStates {
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
  NeverConnected = 'NEVER_CONNECTED',
  Unknown = 'UNKNOWN'
}

export type ConnectionStatesOperationFilterInput = {
  eq?: InputMaybe<ConnectionStates>;
  in?: InputMaybe<Array<ConnectionStates>>;
  neq?: InputMaybe<ConnectionStates>;
  nin?: InputMaybe<Array<ConnectionStates>>;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

/** Custom Feature Request object */
export type CustomFeatureRequestInput = {
  /** Description of the request */
  description: Scalars['String'];
  /** Reason of the request */
  reason?: InputMaybe<Scalars['String']>;
  /** Roles of users that should receive notifications */
  roles: Array<Scalars['String']>;
  /** User that should send notification */
  sender: UserBaseInput;
  /** Custom feature request title */
  title: Scalars['String'];
};

export type DataPoint = {
  __typename?: 'DataPoint';
  device: Device;
  deviceId?: Maybe<Scalars['UUID']>;
  deviceModelCapability?: Maybe<DeviceModelCapability>;
  deviceModelCapabilityId?: Maybe<Scalars['UUID']>;
  tenantId?: Maybe<Scalars['UUID']>;
  utcTimeMeasured?: Maybe<Scalars['DateTime']>;
  valueString?: Maybe<Scalars['String']>;
};

export type DataPointAggregated = {
  __typename?: 'DataPointAggregated';
  avg_value?: Maybe<Scalars['Float']>;
  count_values?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  device_id?: Maybe<Scalars['Int']>;
  device_model_capability_id?: Maybe<Scalars['Int']>;
  first_value?: Maybe<Scalars['Float']>;
  hour?: Maybe<Scalars['Int']>;
  last_value?: Maybe<Scalars['Float']>;
  max_value?: Maybe<Scalars['Float']>;
  min_value?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Int']>;
  quarter?: Maybe<Scalars['Int']>;
  week?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type DataQueryFiltersInput = {
  aggregationIntervalTypeActive?: InputMaybe<AggregationIntervals>;
  aggregationType?: InputMaybe<AggregationTypes>;
  readFromUtc?: InputMaybe<Scalars['DateTime']>;
  readUntilNow?: InputMaybe<Scalars['Boolean']>;
  readUntilUtc?: InputMaybe<Scalars['DateTime']>;
  timeSpan?: InputMaybe<TimeSpans>;
};

export enum DataTypes {
  Bool = 'BOOL',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Double = 'DOUBLE',
  Float = 'FLOAT',
  Integer = 'INTEGER',
  NotSpecified = 'NOT_SPECIFIED',
  String = 'STRING'
}

export type DataTypesOperationFilterInput = {
  eq?: InputMaybe<DataTypes>;
  in?: InputMaybe<Array<DataTypes>>;
  neq?: InputMaybe<DataTypes>;
  nin?: InputMaybe<Array<DataTypes>>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export enum DeleteE2ETestDataResult {
  Failed = 'FAILED',
  Successful = 'SUCCESSFUL'
}

export type DeleteSensorMeasurementsResponse = {
  __typename?: 'DeleteSensorMeasurementsResponse';
  numAffected: Scalars['Int'];
};

export enum DemoSiteType {
  Cube = 'CUBE',
  Dstrct = 'DSTRCT',
  Edge = 'EDGE',
  KpHeadquarters = 'KP_HEADQUARTERS',
  Ship = 'SHIP'
}

/** I am a generated type! */
export type Device = {
  __typename?: 'Device';
  connectionState: ConnectionStates;
  description?: Maybe<Scalars['String']>;
  deviceAttributeValues: Array<DeviceAttributeValue>;
  deviceIdentifier?: Maybe<Scalars['String']>;
  deviceModel: DeviceModel;
  deviceModelId: Scalars['UUID'];
  firstConnected?: Maybe<Scalars['DateTime']>;
  gatewayDeviceConnections: Array<GatewayDeviceConnection>;
  heartbeatState?: Maybe<HeartbeatStates>;
  id: Scalars['UUID'];
  inverseParentDevice: Array<Device>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  lastConnected?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  parentDevice?: Maybe<Device>;
  parentDeviceId?: Maybe<Scalars['UUID']>;
  serialNo?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
};


/** I am a generated type! */
export type DeviceDeviceAttributeValuesArgs = {
  order?: InputMaybe<Array<DeviceAttributeValueSortInput>>;
  where?: InputMaybe<DeviceAttributeValueFilterInput>;
};


/** I am a generated type! */
export type DeviceGatewayDeviceConnectionsArgs = {
  order?: InputMaybe<Array<GatewayDeviceConnectionSortInput>>;
  where?: InputMaybe<GatewayDeviceConnectionFilterInput>;
};


/** I am a generated type! */
export type DeviceInverseParentDeviceArgs = {
  order?: InputMaybe<Array<DeviceSortInput>>;
  where?: InputMaybe<DeviceFilterInput>;
};

export type DeviceAndCapabilityInfo = {
  __typename?: 'DeviceAndCapabilityInfo';
  device: Device;
  deviceId: Scalars['UUID'];
  deviceModelCapability?: Maybe<DeviceModelCapability>;
  deviceModelCapabilityId: Scalars['UUID'];
  telemetryRecords?: Maybe<Array<Maybe<TelemetryRecord>>>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type DeviceAttributeValue = {
  __typename?: 'DeviceAttributeValue';
  attribute: Attribute;
  attributeId: Scalars['UUID'];
  device: Device;
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['UUID']>;
  value?: Maybe<Scalars['String']>;
};

export type DeviceAttributeValueCreateType = {
  attributeId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  value?: InputMaybe<Scalars['String']>;
};

export type DeviceAttributeValueFilterInput = {
  and?: InputMaybe<Array<DeviceAttributeValueFilterInput>>;
  attribute?: InputMaybe<AttributeFilterInput>;
  attributeId?: InputMaybe<UuidOperationFilterInput>;
  device?: InputMaybe<DeviceFilterInput>;
  deviceAttributeValueId?: InputMaybe<UuidOperationFilterInput>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<DeviceAttributeValueFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type DeviceAttributeValueSetNullInputType = {
  attribute?: InputMaybe<Scalars['Boolean']>;
  device?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceAttributeValueSortInput = {
  attribute?: InputMaybe<AttributeSortInput>;
  attributeId?: InputMaybe<SortEnumType>;
  device?: InputMaybe<DeviceSortInput>;
  deviceAttributeValueId?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type DeviceAttributeValueUpdateType = {
  attributeId?: InputMaybe<Scalars['UUID']>;
  deviceId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  value?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type DeviceAttributeValuesCollectionSegment = {
  __typename?: 'DeviceAttributeValuesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<DeviceAttributeValue>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type DeviceCreate = {
  __typename?: 'DeviceCreate';
  description?: Maybe<Scalars['String']>;
  deviceIdentifier?: Maybe<Scalars['String']>;
  deviceModel: DeviceModel;
  deviceModelId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  parentDeviceId?: Maybe<Scalars['UUID']>;
  serialNo?: Maybe<Scalars['String']>;
};

export type DeviceCreateAttributeValueType = {
  attributeId: Scalars['UUID'];
  value?: InputMaybe<Scalars['String']>;
};

export type DeviceCreateInputType = {
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  deviceIdentifier?: InputMaybe<Scalars['String']>;
  deviceModelId: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  parentDeviceId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=50 */
  serialNo?: InputMaybe<Scalars['String']>;
};

export type DeviceCreateType = {
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  deviceIdentifier?: InputMaybe<Scalars['String']>;
  deviceModelId: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  parentDeviceId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=50 */
  serialNo?: InputMaybe<Scalars['String']>;
};

export type DeviceFilterInput = {
  and?: InputMaybe<Array<DeviceFilterInput>>;
  connectionState?: InputMaybe<ConnectionStatesOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  deviceAttributeValues?: InputMaybe<ListFilterInputTypeOfDeviceAttributeValueFilterInput>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  deviceIdentifier?: InputMaybe<StringOperationFilterInput>;
  deviceModel?: InputMaybe<DeviceModelFilterInput>;
  deviceModelId?: InputMaybe<UuidOperationFilterInput>;
  firstConnected?: InputMaybe<DateTimeOperationFilterInput>;
  gatewayDeviceConnections?: InputMaybe<ListFilterInputTypeOfGatewayDeviceConnectionFilterInput>;
  heartbeatState?: InputMaybe<NullableOfHeartbeatStatesOperationFilterInput>;
  inverseParentDevice?: InputMaybe<ListFilterInputTypeOfDeviceFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastConnected?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DeviceFilterInput>>;
  parentDevice?: InputMaybe<DeviceFilterInput>;
  parentDeviceId?: InputMaybe<UuidOperationFilterInput>;
  serialNo?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type DeviceMappingReturnType = {
  __typename?: 'DeviceMappingReturnType';
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  placementId: Scalars['UUID'];
};

export type DeviceMessage = {
  __typename?: 'DeviceMessage';
  connectionState: ConnectionStates;
  description?: Maybe<Scalars['String']>;
  deviceId: Scalars['UUID'];
  deviceIdentifier?: Maybe<Scalars['String']>;
  deviceModelId?: Maybe<Scalars['UUID']>;
  firstConnected?: Maybe<Scalars['DateTime']>;
  heartbeatState?: Maybe<HeartbeatStates>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastConnected?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  parentDeviceId?: Maybe<Scalars['UUID']>;
  serialNo?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type DeviceModel = {
  __typename?: 'DeviceModel';
  dataMeasurementTimestampFieldSelector?: Maybe<Scalars['String']>;
  dataMeasurementTimestampFormat?: Maybe<Scalars['String']>;
  deviceIdentifierFieldSelector?: Maybe<Scalars['String']>;
  deviceModelAttributeSets: Array<DeviceModelAttributeSet>;
  deviceModelCapabilities: Array<DeviceModelCapability>;
  deviceType: DeviceType;
  deviceTypeId: Scalars['UUID'];
  devices: Array<Device>;
  gatewayReceivedDataTimestampFieldSelector?: Maybe<Scalars['String']>;
  gatewayReceivedDataTimestampFormat?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
};


export type DeviceModelDeviceModelAttributeSetsArgs = {
  order?: InputMaybe<Array<DeviceModelAttributeSetSortInput>>;
  where?: InputMaybe<DeviceModelAttributeSetFilterInput>;
};


export type DeviceModelDeviceModelCapabilitiesArgs = {
  order?: InputMaybe<Array<DeviceModelCapabilitySortInput>>;
  where?: InputMaybe<DeviceModelCapabilityFilterInput>;
};


export type DeviceModelDevicesArgs = {
  order?: InputMaybe<Array<DeviceSortInput>>;
  where?: InputMaybe<DeviceFilterInput>;
};

export type DeviceModelAttributeSet = {
  __typename?: 'DeviceModelAttributeSet';
  deviceModel: DeviceModel;
  deviceModelId: Scalars['UUID'];
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  mappingAttributeDeviceModelAttributeSets: Array<MappingAttributeDeviceModelAttributeSet>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
};


export type DeviceModelAttributeSetMappingAttributeDeviceModelAttributeSetsArgs = {
  order?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetSortInput>>;
  where?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
};

export type DeviceModelAttributeSetCreateType = {
  deviceModelId: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
};

export type DeviceModelAttributeSetFilterInput = {
  and?: InputMaybe<Array<DeviceModelAttributeSetFilterInput>>;
  deviceModel?: InputMaybe<DeviceModelFilterInput>;
  deviceModelAttributeSetId?: InputMaybe<UuidOperationFilterInput>;
  deviceModelId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingAttributeDeviceModelAttributeSets?: InputMaybe<ListFilterInputTypeOfMappingAttributeDeviceModelAttributeSetFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DeviceModelAttributeSetFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type DeviceModelAttributeSetSetNullInputType = {
  deviceModel?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingAttributeDeviceModelAttributeSets?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceModelAttributeSetSortInput = {
  deviceModel?: InputMaybe<DeviceModelSortInput>;
  deviceModelAttributeSetId?: InputMaybe<SortEnumType>;
  deviceModelId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type DeviceModelAttributeSetUpdateType = {
  deviceModelId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type DeviceModelAttributeSetsCollectionSegment = {
  __typename?: 'DeviceModelAttributeSetsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<DeviceModelAttributeSet>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** A segment of a collection. */
export type DeviceModelCapabilitiesCollectionSegment = {
  __typename?: 'DeviceModelCapabilitiesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<DeviceModelCapability>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type DeviceModelCapability = {
  __typename?: 'DeviceModelCapability';
  capability: Capability;
  capabilityId: Scalars['UUID'];
  deviceModel: DeviceModel;
  deviceModelId: Scalars['UUID'];
  fieldSelector?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  technicalMax?: Maybe<Scalars['Float']>;
  technicalMin?: Maybe<Scalars['Float']>;
  tenantId?: Maybe<Scalars['UUID']>;
  unit: Unit;
  unitId: Scalars['UUID'];
};

export type DeviceModelCapabilityCreateType = {
  capabilityId: Scalars['UUID'];
  deviceModelId: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  fieldSelector?: InputMaybe<Scalars['String']>;
  technicalMax?: InputMaybe<Scalars['Float']>;
  technicalMin?: InputMaybe<Scalars['Float']>;
  unitId: Scalars['UUID'];
};

export type DeviceModelCapabilityFilterInput = {
  and?: InputMaybe<Array<DeviceModelCapabilityFilterInput>>;
  capability?: InputMaybe<CapabilityFilterInput>;
  capabilityId?: InputMaybe<UuidOperationFilterInput>;
  deviceModel?: InputMaybe<DeviceModelFilterInput>;
  deviceModelCapabilityId?: InputMaybe<UuidOperationFilterInput>;
  deviceModelId?: InputMaybe<UuidOperationFilterInput>;
  fieldSelector?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<DeviceModelCapabilityFilterInput>>;
  technicalMax?: InputMaybe<FloatOperationFilterInput>;
  technicalMin?: InputMaybe<FloatOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  unit?: InputMaybe<UnitFilterInput>;
  unitId?: InputMaybe<UuidOperationFilterInput>;
};

export type DeviceModelCapabilityIdsInput = {
  deviceModelCapabilityId?: InputMaybe<Scalars['UUID']>;
};

export type DeviceModelCapabilitySetNullInputType = {
  capability?: InputMaybe<Scalars['Boolean']>;
  deviceModel?: InputMaybe<Scalars['Boolean']>;
  fieldSelector?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  technicalMax?: InputMaybe<Scalars['Boolean']>;
  technicalMin?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  unit?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceModelCapabilitySortInput = {
  capability?: InputMaybe<CapabilitySortInput>;
  capabilityId?: InputMaybe<SortEnumType>;
  deviceModel?: InputMaybe<DeviceModelSortInput>;
  deviceModelCapabilityId?: InputMaybe<SortEnumType>;
  deviceModelId?: InputMaybe<SortEnumType>;
  fieldSelector?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  technicalMax?: InputMaybe<SortEnumType>;
  technicalMin?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  unit?: InputMaybe<UnitSortInput>;
  unitId?: InputMaybe<SortEnumType>;
};

export type DeviceModelCapabilityUpdateType = {
  capabilityId?: InputMaybe<Scalars['UUID']>;
  deviceModelId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  fieldSelector?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  technicalMax?: InputMaybe<Scalars['Float']>;
  technicalMin?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['UUID']>;
};

export type DeviceModelCreateType = {
  /** MinLength=0, MaxLength=50 */
  dataMeasurementTimestampFieldSelector?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=25 */
  dataMeasurementTimestampFormat?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  deviceIdentifierFieldSelector?: InputMaybe<Scalars['String']>;
  deviceTypeId: Scalars['UUID'];
  /** MinLength=0, MaxLength=50 */
  gatewayReceivedDataTimestampFieldSelector?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=25 */
  gatewayReceivedDataTimestampFormat?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
};

export type DeviceModelFilterInput = {
  and?: InputMaybe<Array<DeviceModelFilterInput>>;
  dataMeasurementTimestampFieldSelector?: InputMaybe<StringOperationFilterInput>;
  dataMeasurementTimestampFormat?: InputMaybe<StringOperationFilterInput>;
  deviceIdentifierFieldSelector?: InputMaybe<StringOperationFilterInput>;
  deviceModelAttributeSets?: InputMaybe<ListFilterInputTypeOfDeviceModelAttributeSetFilterInput>;
  deviceModelCapabilities?: InputMaybe<ListFilterInputTypeOfDeviceModelCapabilityFilterInput>;
  deviceModelId?: InputMaybe<UuidOperationFilterInput>;
  deviceType?: InputMaybe<DeviceTypeFilterInput>;
  deviceTypeId?: InputMaybe<UuidOperationFilterInput>;
  devices?: InputMaybe<ListFilterInputTypeOfDeviceFilterInput>;
  gatewayReceivedDataTimestampFieldSelector?: InputMaybe<StringOperationFilterInput>;
  gatewayReceivedDataTimestampFormat?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DeviceModelFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type DeviceModelSetNullInputType = {
  dataMeasurementTimestampFieldSelector?: InputMaybe<Scalars['Boolean']>;
  dataMeasurementTimestampFormat?: InputMaybe<Scalars['Boolean']>;
  deviceIdentifierFieldSelector?: InputMaybe<Scalars['Boolean']>;
  deviceModelAttributeSets?: InputMaybe<Scalars['Boolean']>;
  deviceModelCapabilities?: InputMaybe<Scalars['Boolean']>;
  deviceType?: InputMaybe<Scalars['Boolean']>;
  devices?: InputMaybe<Scalars['Boolean']>;
  gatewayReceivedDataTimestampFieldSelector?: InputMaybe<Scalars['Boolean']>;
  gatewayReceivedDataTimestampFormat?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceModelSortInput = {
  dataMeasurementTimestampFieldSelector?: InputMaybe<SortEnumType>;
  dataMeasurementTimestampFormat?: InputMaybe<SortEnumType>;
  deviceIdentifierFieldSelector?: InputMaybe<SortEnumType>;
  deviceModelId?: InputMaybe<SortEnumType>;
  deviceType?: InputMaybe<DeviceTypeSortInput>;
  deviceTypeId?: InputMaybe<SortEnumType>;
  gatewayReceivedDataTimestampFieldSelector?: InputMaybe<SortEnumType>;
  gatewayReceivedDataTimestampFormat?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type DeviceModelUpdateType = {
  /** MinLength=0, MaxLength=50 */
  dataMeasurementTimestampFieldSelector?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=25 */
  dataMeasurementTimestampFormat?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  deviceIdentifierFieldSelector?: InputMaybe<Scalars['String']>;
  deviceTypeId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=50 */
  gatewayReceivedDataTimestampFieldSelector?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=25 */
  gatewayReceivedDataTimestampFormat?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type DeviceModelsCollectionSegment = {
  __typename?: 'DeviceModelsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<DeviceModel>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type DeviceSetNullInputType = {
  description?: InputMaybe<Scalars['Boolean']>;
  deviceAttributeValues?: InputMaybe<Scalars['Boolean']>;
  deviceIdentifier?: InputMaybe<Scalars['Boolean']>;
  deviceModel?: InputMaybe<Scalars['Boolean']>;
  gatewayDeviceConnections?: InputMaybe<Scalars['Boolean']>;
  inverseParentDevice?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  parentDevice?: InputMaybe<Scalars['Boolean']>;
  parentDeviceId?: InputMaybe<Scalars['Boolean']>;
  serialNo?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceSortInput = {
  connectionState?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  deviceIdentifier?: InputMaybe<SortEnumType>;
  deviceModel?: InputMaybe<DeviceModelSortInput>;
  deviceModelId?: InputMaybe<SortEnumType>;
  firstConnected?: InputMaybe<SortEnumType>;
  heartbeatState?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastConnected?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  parentDevice?: InputMaybe<DeviceSortInput>;
  parentDeviceId?: InputMaybe<SortEnumType>;
  serialNo?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type DeviceType = {
  __typename?: 'DeviceType';
  deviceModels: Array<DeviceModel>;
  id: Scalars['UUID'];
  isGateway?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
};


export type DeviceTypeDeviceModelsArgs = {
  order?: InputMaybe<Array<DeviceModelSortInput>>;
  where?: InputMaybe<DeviceModelFilterInput>;
};

export type DeviceTypeCreateType = {
  isGateway?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
};

export type DeviceTypeFilterInput = {
  and?: InputMaybe<Array<DeviceTypeFilterInput>>;
  deviceModels?: InputMaybe<ListFilterInputTypeOfDeviceModelFilterInput>;
  deviceTypeId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isGateway?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DeviceTypeFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type DeviceTypeSetNullInputType = {
  deviceModels?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isGateway?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceTypeSortInput = {
  deviceTypeId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isGateway?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type DeviceTypeUpdateType = {
  id: Scalars['UUID'];
  isGateway?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type DeviceTypesCollectionSegment = {
  __typename?: 'DeviceTypesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<DeviceType>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type DeviceUpdateType = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  serialNo?: InputMaybe<Scalars['String']>;
};

export type DeviceWithAttributeValues = {
  __typename?: 'DeviceWithAttributeValues';
  attributeValues?: Maybe<Array<Maybe<AttributeWithValue>>>;
  connectionState: ConnectionStates;
  description?: Maybe<Scalars['String']>;
  deviceAttributeValues?: Maybe<Array<Maybe<DeviceAttributeValue>>>;
  deviceId: Scalars['UUID'];
  deviceIdentifier?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<DeviceModel>;
  deviceModelId: Scalars['UUID'];
  firstConnected?: Maybe<Scalars['DateTime']>;
  gatewayDeviceConnections?: Maybe<Array<Maybe<GatewayDeviceConnection>>>;
  heartbeatState?: Maybe<HeartbeatStates>;
  inverseParentDevice?: Maybe<Array<Maybe<Device>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastConnected?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  parentDevice?: Maybe<Device>;
  parentDeviceId?: Maybe<Scalars['UUID']>;
  serialNo?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type DevicesByBuilding = {
  __typename?: 'DevicesByBuilding';
  building: Building;
  buildingId?: Maybe<Scalars['UUID']>;
  device: Device;
  deviceAttributeValues?: Maybe<Array<ValueEntity>>;
  deviceDescription?: Maybe<Scalars['String']>;
  deviceDeviceIdentifier?: Maybe<Scalars['String']>;
  deviceGatewayName?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['UUID']>;
  deviceModelName?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  deviceSerialNumber?: Maybe<Scalars['String']>;
  deviceTypeId?: Maybe<Scalars['UUID']>;
  deviceTypeName?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['UUID']>;
  storeyId?: Maybe<Scalars['UUID']>;
  zoneId?: Maybe<Scalars['UUID']>;
};

export type DevicesByBuildingFilterInput = {
  and?: InputMaybe<Array<DevicesByBuildingFilterInput>>;
  buildingId?: InputMaybe<UuidOperationFilterInput>;
  deviceAttributeValues?: InputMaybe<ListFilterInputTypeOfValueEntityFilterInput>;
  deviceDescription?: InputMaybe<StringOperationFilterInput>;
  deviceDeviceIdentifier?: InputMaybe<StringOperationFilterInput>;
  deviceGatewayName?: InputMaybe<StringOperationFilterInput>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  deviceModelName?: InputMaybe<StringOperationFilterInput>;
  deviceName?: InputMaybe<StringOperationFilterInput>;
  deviceSerialNumber?: InputMaybe<StringOperationFilterInput>;
  deviceTypeId?: InputMaybe<UuidOperationFilterInput>;
  deviceTypeName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DevicesByBuildingFilterInput>>;
  siteId?: InputMaybe<UuidOperationFilterInput>;
  storeyId?: InputMaybe<UuidOperationFilterInput>;
  zoneId?: InputMaybe<UuidOperationFilterInput>;
};

export type DevicesByBuildingSortInput = {
  buildingId?: InputMaybe<SortEnumType>;
  deviceDescription?: InputMaybe<SortEnumType>;
  deviceDeviceIdentifier?: InputMaybe<SortEnumType>;
  deviceGatewayName?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  deviceModelName?: InputMaybe<SortEnumType>;
  deviceName?: InputMaybe<SortEnumType>;
  deviceSerialNumber?: InputMaybe<SortEnumType>;
  deviceTypeId?: InputMaybe<SortEnumType>;
  deviceTypeName?: InputMaybe<SortEnumType>;
  siteId?: InputMaybe<SortEnumType>;
  storeyId?: InputMaybe<SortEnumType>;
  zoneId?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type DevicesByBuildingsCollectionSegment = {
  __typename?: 'DevicesByBuildingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<DevicesByBuilding>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** A segment of a collection. */
export type DevicesCollectionSegment = {
  __typename?: 'DevicesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Device>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** User Request object */
export type EmailContactRequestInput = {
  /** Message */
  message: Scalars['String'];
  /** User that should receive notification */
  recipient: UserBaseInput;
  /** User that should send notification */
  sender: UserBaseInput;
  /** Subject of the message */
  subject: Scalars['String'];
};

export type ExternalIdScoreTypesInput = {
  externalId: Scalars['String'];
  scoreTypes: Array<ScoreType>;
};

export type FloatOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type Forecast = {
  __typename?: 'Forecast';
  date: Scalars['DateTime'];
  iceProbability: Scalars['Int'];
  iconCode: Scalars['Int'];
  iconPhrase: Scalars['String'];
  precipitationProbability: Scalars['Int'];
  rainProbability: Scalars['Int'];
  realFeelTemperature: Temperature;
  relativeHumidity: Scalars['Int'];
  snowProbability: Scalars['Int'];
  temperature: Temperature;
};

export type ForecastResult = {
  __typename?: 'ForecastResult';
  forecasts: Array<Forecast>;
};

export type GatewayDeviceConnection = {
  __typename?: 'GatewayDeviceConnection';
  device: Device;
  deviceId: Scalars['UUID'];
  host: Scalars['String'];
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  port: Scalars['Int'];
  primaryAuthenticationKey: Scalars['String'];
  primaryConnectionString?: Maybe<Scalars['String']>;
  secondaryAuthenticationKey: Scalars['String'];
  secondaryConnectionString?: Maybe<Scalars['String']>;
  sharedAccessSignatureExpiry?: Maybe<Scalars['DateTime']>;
  tenantId?: Maybe<Scalars['UUID']>;
  user: Scalars['String'];
};

export type GatewayDeviceConnectionCreateType = {
  deviceId: Scalars['UUID'];
  /** MinLength=0, MaxLength=100 */
  name: Scalars['String'];
  /** MinLength=0, MaxLength=250 */
  primaryAuthenticationKey: Scalars['String'];
  /** MinLength=0, MaxLength=250 */
  primaryConnectionString?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  secondaryAuthenticationKey: Scalars['String'];
  /** MinLength=0, MaxLength=250 */
  secondaryConnectionString?: InputMaybe<Scalars['String']>;
};

export type GatewayDeviceConnectionFilterInput = {
  and?: InputMaybe<Array<GatewayDeviceConnectionFilterInput>>;
  device?: InputMaybe<DeviceFilterInput>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  gatewayDeviceConnectionId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<GatewayDeviceConnectionFilterInput>>;
  primaryAuthenticationKey?: InputMaybe<StringOperationFilterInput>;
  primaryConnectionString?: InputMaybe<StringOperationFilterInput>;
  secondaryAuthenticationKey?: InputMaybe<StringOperationFilterInput>;
  secondaryConnectionString?: InputMaybe<StringOperationFilterInput>;
  sharedAccessSignatureExpiry?: InputMaybe<DateTimeOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type GatewayDeviceConnectionSetNullInputType = {
  device?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  primaryConnectionString?: InputMaybe<Scalars['Boolean']>;
  secondaryConnectionString?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type GatewayDeviceConnectionSortInput = {
  device?: InputMaybe<DeviceSortInput>;
  deviceId?: InputMaybe<SortEnumType>;
  gatewayDeviceConnectionId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  primaryAuthenticationKey?: InputMaybe<SortEnumType>;
  primaryConnectionString?: InputMaybe<SortEnumType>;
  secondaryAuthenticationKey?: InputMaybe<SortEnumType>;
  secondaryConnectionString?: InputMaybe<SortEnumType>;
  sharedAccessSignatureExpiry?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type GatewayDeviceConnectionUpdateType = {
  deviceId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=100 */
  name?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  primaryAuthenticationKey?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  primaryConnectionString?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  secondaryAuthenticationKey?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  secondaryConnectionString?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type GatewayDeviceConnectionsCollectionSegment = {
  __typename?: 'GatewayDeviceConnectionsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<GatewayDeviceConnection>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type GatewayProperties = IGateway_Gateway_Properties & {
  __typename?: 'GatewayProperties';
  firmwareVersion?: Maybe<Scalars['String']>;
};

export type Gateway_Gateway_Gateway = {
  __typename?: 'Gateway_Gateway_Gateway';
  id: Scalars['String'];
  properties?: Maybe<IGateway_Gateway_Properties>;
  status?: Maybe<Scalars['String']>;
};

export type Gateway_Gateway_Properties_GatewayProperties = IGateway_Gateway_Properties & {
  __typename?: 'Gateway_Gateway_Properties_GatewayProperties';
  firmwareVersion?: Maybe<Scalars['String']>;
};

export type GroupedSystemsScore = {
  __typename?: 'GroupedSystemsScore';
  score?: Maybe<Scalars['Float']>;
  systemType: SystemType;
};

export enum HeartbeatStates {
  Offline = 'OFFLINE',
  Online = 'ONLINE',
  Unknown = 'UNKNOWN'
}

export type IGateway_Gateway_Properties = {
  firmwareVersion?: Maybe<Scalars['String']>;
};

/** Idea Request object */
export type IdeaRequestInput = {
  /** Description of the idea */
  description: Scalars['String'];
  /** Roles of users that should receive notifications */
  roles: Array<Scalars['String']>;
  /** User that should send notification */
  sender: UserBaseInput;
  /** Subject of the idea */
  subject: Scalars['String'];
};

/** I am a generated type! */
export type Image = {
  __typename?: 'Image';
  buildings: Array<Building>;
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  mappingDeviceImages: Array<MappingDeviceImage>;
  name: Scalars['String'];
  sites: Array<Site>;
  storeys: Array<Storey>;
  tenantId?: Maybe<Scalars['UUID']>;
  url?: Maybe<Scalars['String']>;
  zones: Array<Zone>;
};


/** I am a generated type! */
export type ImageBuildingsArgs = {
  order?: InputMaybe<Array<BuildingSortInput>>;
  where?: InputMaybe<BuildingFilterInput>;
};


/** I am a generated type! */
export type ImageMappingDeviceImagesArgs = {
  order?: InputMaybe<Array<MappingDeviceImageSortInput>>;
  where?: InputMaybe<MappingDeviceImageFilterInput>;
};


/** I am a generated type! */
export type ImageSitesArgs = {
  order?: InputMaybe<Array<SiteSortInput>>;
  where?: InputMaybe<SiteFilterInput>;
};


/** I am a generated type! */
export type ImageStoreysArgs = {
  order?: InputMaybe<Array<StoreySortInput>>;
  where?: InputMaybe<StoreyFilterInput>;
};


/** I am a generated type! */
export type ImageZonesArgs = {
  order?: InputMaybe<Array<ZoneSortInput>>;
  where?: InputMaybe<ZoneFilterInput>;
};

export type ImageCreateType = {
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  /** MinLength=0, MaxLength=500 */
  url?: InputMaybe<Scalars['String']>;
};

export type ImageFilterInput = {
  and?: InputMaybe<Array<ImageFilterInput>>;
  buildings?: InputMaybe<ListFilterInputTypeOfBuildingFilterInput>;
  imageId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceImages?: InputMaybe<ListFilterInputTypeOfMappingDeviceImageFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ImageFilterInput>>;
  sites?: InputMaybe<ListFilterInputTypeOfSiteFilterInput>;
  storeys?: InputMaybe<ListFilterInputTypeOfStoreyFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  url?: InputMaybe<StringOperationFilterInput>;
  zones?: InputMaybe<ListFilterInputTypeOfZoneFilterInput>;
};

export type ImageSetNullInputType = {
  buildings?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceImages?: InputMaybe<Scalars['Boolean']>;
  sites?: InputMaybe<Scalars['Boolean']>;
  storeys?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  url?: InputMaybe<Scalars['Boolean']>;
  zones?: InputMaybe<Scalars['Boolean']>;
};

export type ImageSortInput = {
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  url?: InputMaybe<SortEnumType>;
};

export type ImageUpdateType = {
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=500 */
  url?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type ImagesCollectionSegment = {
  __typename?: 'ImagesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Image>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type InstallationPart = {
  __typename?: 'InstallationPart';
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type InstallationPartScores = {
  __typename?: 'InstallationPartScores';
  installationPart: InstallationPart;
  scores: Array<Score>;
  value?: Maybe<Scalars['Float']>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type ListFilterInputTypeOfAttributeDefinitionFilterInput = {
  all?: InputMaybe<AttributeDefinitionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AttributeDefinitionFilterInput>;
  some?: InputMaybe<AttributeDefinitionFilterInput>;
};

export type ListFilterInputTypeOfBuildingFilterInput = {
  all?: InputMaybe<BuildingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<BuildingFilterInput>;
  some?: InputMaybe<BuildingFilterInput>;
};

export type ListFilterInputTypeOfDeviceAttributeValueFilterInput = {
  all?: InputMaybe<DeviceAttributeValueFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceAttributeValueFilterInput>;
  some?: InputMaybe<DeviceAttributeValueFilterInput>;
};

export type ListFilterInputTypeOfDeviceFilterInput = {
  all?: InputMaybe<DeviceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceFilterInput>;
  some?: InputMaybe<DeviceFilterInput>;
};

export type ListFilterInputTypeOfDeviceModelAttributeSetFilterInput = {
  all?: InputMaybe<DeviceModelAttributeSetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceModelAttributeSetFilterInput>;
  some?: InputMaybe<DeviceModelAttributeSetFilterInput>;
};

export type ListFilterInputTypeOfDeviceModelCapabilityFilterInput = {
  all?: InputMaybe<DeviceModelCapabilityFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceModelCapabilityFilterInput>;
  some?: InputMaybe<DeviceModelCapabilityFilterInput>;
};

export type ListFilterInputTypeOfDeviceModelFilterInput = {
  all?: InputMaybe<DeviceModelFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceModelFilterInput>;
  some?: InputMaybe<DeviceModelFilterInput>;
};

export type ListFilterInputTypeOfGatewayDeviceConnectionFilterInput = {
  all?: InputMaybe<GatewayDeviceConnectionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<GatewayDeviceConnectionFilterInput>;
  some?: InputMaybe<GatewayDeviceConnectionFilterInput>;
};

export type ListFilterInputTypeOfMappingAttributeDeviceModelAttributeSetFilterInput = {
  all?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
  some?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
};

export type ListFilterInputTypeOfMappingDeviceBuildingFilterInput = {
  all?: InputMaybe<MappingDeviceBuildingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingDeviceBuildingFilterInput>;
  some?: InputMaybe<MappingDeviceBuildingFilterInput>;
};

export type ListFilterInputTypeOfMappingDeviceImageFilterInput = {
  all?: InputMaybe<MappingDeviceImageFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingDeviceImageFilterInput>;
  some?: InputMaybe<MappingDeviceImageFilterInput>;
};

export type ListFilterInputTypeOfMappingDeviceZoneFilterInput = {
  all?: InputMaybe<MappingDeviceZoneFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingDeviceZoneFilterInput>;
  some?: InputMaybe<MappingDeviceZoneFilterInput>;
};

export type ListFilterInputTypeOfSiteFilterInput = {
  all?: InputMaybe<SiteFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SiteFilterInput>;
  some?: InputMaybe<SiteFilterInput>;
};

export type ListFilterInputTypeOfStoreyFilterInput = {
  all?: InputMaybe<StoreyFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StoreyFilterInput>;
  some?: InputMaybe<StoreyFilterInput>;
};

export type ListFilterInputTypeOfValueEntityFilterInput = {
  all?: InputMaybe<ValueEntityFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ValueEntityFilterInput>;
  some?: InputMaybe<ValueEntityFilterInput>;
};

export type ListFilterInputTypeOfZoneFilterInput = {
  all?: InputMaybe<ZoneFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ZoneFilterInput>;
  some?: InputMaybe<ZoneFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

/** I am a generated type! */
export type Location = {
  __typename?: 'Location';
  buildings: Array<Building>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  no?: Maybe<Scalars['String']>;
  sites: Array<Site>;
  street?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
  zip?: Maybe<Scalars['String']>;
};


/** I am a generated type! */
export type LocationBuildingsArgs = {
  order?: InputMaybe<Array<BuildingSortInput>>;
  where?: InputMaybe<BuildingFilterInput>;
};


/** I am a generated type! */
export type LocationSitesArgs = {
  order?: InputMaybe<Array<SiteSortInput>>;
  where?: InputMaybe<SiteFilterInput>;
};

export type LocationCreateType = {
  /** MinLength=0, MaxLength=250 */
  city?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  country?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  county?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  no?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  street?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  zip?: InputMaybe<Scalars['String']>;
};

export type LocationFilterInput = {
  and?: InputMaybe<Array<LocationFilterInput>>;
  buildings?: InputMaybe<ListFilterInputTypeOfBuildingFilterInput>;
  city?: InputMaybe<StringOperationFilterInput>;
  country?: InputMaybe<StringOperationFilterInput>;
  county?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lat?: InputMaybe<FloatOperationFilterInput>;
  locationId?: InputMaybe<UuidOperationFilterInput>;
  lon?: InputMaybe<FloatOperationFilterInput>;
  no?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LocationFilterInput>>;
  sites?: InputMaybe<ListFilterInputTypeOfSiteFilterInput>;
  street?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  zip?: InputMaybe<StringOperationFilterInput>;
};

export type LocationSetNullInputType = {
  buildings?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['Boolean']>;
  county?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  no?: InputMaybe<Scalars['Boolean']>;
  sites?: InputMaybe<Scalars['Boolean']>;
  street?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  zip?: InputMaybe<Scalars['Boolean']>;
};

export type LocationSortInput = {
  city?: InputMaybe<SortEnumType>;
  country?: InputMaybe<SortEnumType>;
  county?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lat?: InputMaybe<SortEnumType>;
  locationId?: InputMaybe<SortEnumType>;
  lon?: InputMaybe<SortEnumType>;
  no?: InputMaybe<SortEnumType>;
  street?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  zip?: InputMaybe<SortEnumType>;
};

export type LocationUpdateType = {
  /** MinLength=0, MaxLength=250 */
  city?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  country?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  county?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=50 */
  no?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  street?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  zip?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type LocationsCollectionSegment = {
  __typename?: 'LocationsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Location>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type MappingAttributeDeviceModelAttributeSet = {
  __typename?: 'MappingAttributeDeviceModelAttributeSet';
  attribute: Attribute;
  attributeId: Scalars['UUID'];
  deviceModelAttributeSet: DeviceModelAttributeSet;
  deviceModelAttributeSetId: Scalars['UUID'];
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type MappingAttributeDeviceModelAttributeSetCreateType = {
  attributeId: Scalars['UUID'];
  deviceModelAttributeSetId: Scalars['UUID'];
};

export type MappingAttributeDeviceModelAttributeSetFilterInput = {
  and?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetFilterInput>>;
  attribute?: InputMaybe<AttributeFilterInput>;
  attributeId?: InputMaybe<UuidOperationFilterInput>;
  deviceModelAttributeSet?: InputMaybe<DeviceModelAttributeSetFilterInput>;
  deviceModelAttributeSetId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingAttributeDeviceModelAttributeSetId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type MappingAttributeDeviceModelAttributeSetSetNullInputType = {
  attribute?: InputMaybe<Scalars['Boolean']>;
  deviceModelAttributeSet?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type MappingAttributeDeviceModelAttributeSetSortInput = {
  attribute?: InputMaybe<AttributeSortInput>;
  attributeId?: InputMaybe<SortEnumType>;
  deviceModelAttributeSet?: InputMaybe<DeviceModelAttributeSetSortInput>;
  deviceModelAttributeSetId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingAttributeDeviceModelAttributeSetId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type MappingAttributeDeviceModelAttributeSetUpdateType = {
  attributeId?: InputMaybe<Scalars['UUID']>;
  deviceModelAttributeSetId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
};

/** A segment of a collection. */
export type MappingAttributeDeviceModelAttributeSetsCollectionSegment = {
  __typename?: 'MappingAttributeDeviceModelAttributeSetsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<MappingAttributeDeviceModelAttributeSet>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** I am a generated type! */
export type MappingDeviceBuilding = {
  __typename?: 'MappingDeviceBuilding';
  building: Building;
  buildingId: Scalars['UUID'];
  device: Device;
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type MappingDeviceBuildingCreateType = {
  buildingId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
};

export type MappingDeviceBuildingFilterInput = {
  and?: InputMaybe<Array<MappingDeviceBuildingFilterInput>>;
  building?: InputMaybe<BuildingFilterInput>;
  buildingId?: InputMaybe<UuidOperationFilterInput>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceBuildingId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingDeviceBuildingFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type MappingDeviceBuildingSetNullInputType = {
  building?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type MappingDeviceBuildingSortInput = {
  building?: InputMaybe<BuildingSortInput>;
  buildingId?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingDeviceBuildingId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type MappingDeviceBuildingUpdateType = {
  buildingId?: InputMaybe<Scalars['UUID']>;
  deviceId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
};

/** A segment of a collection. */
export type MappingDeviceBuildingsCollectionSegment = {
  __typename?: 'MappingDeviceBuildingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<MappingDeviceBuilding>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** I am a generated type! */
export type MappingDeviceImage = {
  __typename?: 'MappingDeviceImage';
  device: Device;
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  image: Image;
  imageId: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type MappingDeviceImageCreateType = {
  deviceId: Scalars['UUID'];
  imageId: Scalars['UUID'];
};

export type MappingDeviceImageFilterInput = {
  and?: InputMaybe<Array<MappingDeviceImageFilterInput>>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceImageId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingDeviceImageFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type MappingDeviceImageSetNullInputType = {
  image?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type MappingDeviceImageSortInput = {
  deviceId?: InputMaybe<SortEnumType>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingDeviceImageId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type MappingDeviceImageUpdateType = {
  deviceId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  imageId?: InputMaybe<Scalars['UUID']>;
};

/** A segment of a collection. */
export type MappingDeviceImagesCollectionSegment = {
  __typename?: 'MappingDeviceImagesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<MappingDeviceImage>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** I am a generated type! */
export type MappingDeviceZone = {
  __typename?: 'MappingDeviceZone';
  device: Device;
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['UUID']>;
  zone: Zone;
  zoneId: Scalars['UUID'];
};

export type MappingDeviceZoneCreateType = {
  deviceId: Scalars['UUID'];
  zoneId: Scalars['UUID'];
};

export type MappingDeviceZoneFilterInput = {
  and?: InputMaybe<Array<MappingDeviceZoneFilterInput>>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceZoneId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingDeviceZoneFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  zone?: InputMaybe<ZoneFilterInput>;
  zoneId?: InputMaybe<UuidOperationFilterInput>;
};

export type MappingDeviceZoneSetNullInputType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  zone?: InputMaybe<Scalars['Boolean']>;
};

export type MappingDeviceZoneSortInput = {
  deviceId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingDeviceZoneId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  zone?: InputMaybe<ZoneSortInput>;
  zoneId?: InputMaybe<SortEnumType>;
};

export type MappingDeviceZoneUpdateType = {
  deviceId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  zoneId?: InputMaybe<Scalars['UUID']>;
};

/** A segment of a collection. */
export type MappingDeviceZonesCollectionSegment = {
  __typename?: 'MappingDeviceZonesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<MappingDeviceZone>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  bulkProvisionIoTHubDevices: Scalars['String'];
  cleanupGatewayDevicesFromIoTHub: Scalars['String'];
  createAttribute?: Maybe<Attribute>;
  createAttributes: Array<Maybe<Attribute>>;
  createAuditLog?: Maybe<AuditLog>;
  createAuditLogs: Array<Maybe<AuditLog>>;
  createBuilding?: Maybe<Building>;
  createBuildings: Array<Maybe<Building>>;
  createCapabilities: Array<Maybe<Capability>>;
  createCapability?: Maybe<Capability>;
  createDeviceModel?: Maybe<DeviceModel>;
  createDeviceModelAttributeSet?: Maybe<DeviceModelAttributeSet>;
  createDeviceModelAttributeSets: Array<Maybe<DeviceModelAttributeSet>>;
  createDeviceModelCapabilities: Array<Maybe<DeviceModelCapability>>;
  createDeviceModelCapability?: Maybe<DeviceModelCapability>;
  createDeviceModels: Array<Maybe<DeviceModel>>;
  createDeviceType?: Maybe<DeviceType>;
  createDeviceTypes: Array<Maybe<DeviceType>>;
  createDeviceWithPlacement: DeviceCreate;
  createGatewayDeviceConnection?: Maybe<GatewayDeviceConnection>;
  createGatewayDeviceConnections: Array<Maybe<GatewayDeviceConnection>>;
  createImage?: Maybe<Image>;
  createImages: Array<Maybe<Image>>;
  createLocation?: Maybe<Location>;
  createLocations: Array<Maybe<Location>>;
  createMappingAttributeDeviceModelAttributeSet?: Maybe<MappingAttributeDeviceModelAttributeSet>;
  createMappingAttributeDeviceModelAttributeSets: Array<Maybe<MappingAttributeDeviceModelAttributeSet>>;
  createMappingDeviceBuilding?: Maybe<MappingDeviceBuilding>;
  createMappingDeviceBuildings: Array<Maybe<MappingDeviceBuilding>>;
  createMappingDeviceImage?: Maybe<MappingDeviceImage>;
  createMappingDeviceImages: Array<Maybe<MappingDeviceImage>>;
  createMappingDeviceZone?: Maybe<MappingDeviceZone>;
  createMappingDeviceZones: Array<Maybe<MappingDeviceZone>>;
  createRule?: Maybe<Rule>;
  createRuleWithChecks: RuleWithChecks;
  createRules: Array<Maybe<Rule>>;
  createRulesWithChecks: Array<RuleWithChecks>;
  createSite?: Maybe<Site>;
  createSites: Array<Maybe<Site>>;
  createStorey?: Maybe<Storey>;
  createStoreys: Array<Maybe<Storey>>;
  createUnit?: Maybe<Unit>;
  createUnits: Array<Maybe<Unit>>;
  createZone?: Maybe<Zone>;
  createZones: Array<Maybe<Zone>>;
  deleteAttribute?: Maybe<Attribute>;
  deleteAttributes: Array<Maybe<Attribute>>;
  deleteBuilding?: Maybe<Building>;
  deleteBuildings: Array<Maybe<Building>>;
  deleteCapabilities: Array<Maybe<Capability>>;
  deleteCapability?: Maybe<Capability>;
  deleteDevice?: Maybe<Device>;
  /** Delete list of sensor measurements by device and device capability */
  deleteDeviceCapabilitySensorMeasurements?: Maybe<DeleteSensorMeasurementsResponse>;
  deleteDeviceModel?: Maybe<DeviceModel>;
  deleteDeviceModelAttributeSet?: Maybe<DeviceModelAttributeSet>;
  deleteDeviceModelAttributeSets: Array<Maybe<DeviceModelAttributeSet>>;
  deleteDeviceModelCapabilities: Array<Maybe<DeviceModelCapability>>;
  deleteDeviceModelCapability?: Maybe<DeviceModelCapability>;
  deleteDeviceModels: Array<Maybe<DeviceModel>>;
  deleteDeviceType?: Maybe<DeviceType>;
  deleteDeviceTypes: Array<Maybe<DeviceType>>;
  deleteDevices: Array<Maybe<Device>>;
  deleteDevicesByGatewayId: DeleteE2ETestDataResult;
  deleteGatewayDeviceConnection?: Maybe<GatewayDeviceConnection>;
  deleteGatewayDeviceConnections: Array<Maybe<GatewayDeviceConnection>>;
  deleteImage?: Maybe<Image>;
  deleteImages: Array<Maybe<Image>>;
  deleteLocation?: Maybe<Location>;
  deleteLocations: Array<Maybe<Location>>;
  deleteMappingAttributeDeviceModelAttributeSet?: Maybe<MappingAttributeDeviceModelAttributeSet>;
  deleteMappingAttributeDeviceModelAttributeSets: Array<Maybe<MappingAttributeDeviceModelAttributeSet>>;
  deleteMappingDeviceBuilding?: Maybe<MappingDeviceBuilding>;
  deleteMappingDeviceBuildings: Array<Maybe<MappingDeviceBuilding>>;
  deleteMappingDeviceImage?: Maybe<MappingDeviceImage>;
  deleteMappingDeviceImages: Array<Maybe<MappingDeviceImage>>;
  deleteMappingDeviceZone?: Maybe<MappingDeviceZone>;
  deleteMappingDeviceZones: Array<Maybe<MappingDeviceZone>>;
  deleteRule?: Maybe<Rule>;
  deleteRules: Array<Maybe<Rule>>;
  deleteSite?: Maybe<Site>;
  deleteSites: Array<Maybe<Site>>;
  deleteStorey?: Maybe<Storey>;
  deleteStoreys: Array<Maybe<Storey>>;
  deleteUnit?: Maybe<Unit>;
  deleteUnits: Array<Maybe<Unit>>;
  deleteZone?: Maybe<Zone>;
  deleteZones: Array<Maybe<Zone>>;
  exportIoTDevicesToBlobStorage: Scalars['String'];
  rebuildDeviceMappingsInStorageTable: Scalars['String'];
  redoCreateGatewayDeviceNotification?: Maybe<Scalars['String']>;
  regenerateGatewayDeviceConnectionKey?: Maybe<GatewayDeviceConnection>;
  regenerateGatewayDeviceConnectionsKeys: Array<Maybe<GatewayDeviceConnection>>;
  regenerateGatewayDeviceSharedAccessSignature: SharedAccessSignatureData;
  regenerateTenantGatewayDeviceConnectionsKeys: Array<GatewayDeviceConnection>;
  removeDeletedDeviceMappingsInStorageTable: Scalars['String'];
  /** Send new email contact request to selected user */
  sendContactRequestByEmail: Scalars['Boolean'];
  /** Send new custom request to users in selected roles */
  sendCustomFeatureRequestByRoles: Scalars['Boolean'];
  /** Send new idea request to users in selected roles */
  sendIdeaRequestByRoles: Scalars['Boolean'];
  setDeviceAttributeValue?: Maybe<DeviceAttributeValue>;
  setDeviceAttributeValues: Array<Maybe<DeviceAttributeValue>>;
  testSubscriptionDeviceMessage?: Maybe<DeviceMessage>;
  unsetDeviceAttributeValue?: Maybe<DeviceAttributeValue>;
  unsetDeviceAttributeValues: Array<Maybe<DeviceAttributeValue>>;
  updateAttribute: Attribute;
  updateAttributes: Array<Attribute>;
  updateBuilding: Building;
  updateBuildings: Array<Building>;
  updateCapabilities: Array<Capability>;
  updateCapability: Capability;
  updateDevice: Device;
  updateDeviceModel: DeviceModel;
  updateDeviceModelAttributeSet: DeviceModelAttributeSet;
  updateDeviceModelAttributeSets: Array<DeviceModelAttributeSet>;
  updateDeviceModelCapabilities: Array<DeviceModelCapability>;
  updateDeviceModelCapability: DeviceModelCapability;
  updateDeviceModels: Array<DeviceModel>;
  updateDeviceType: DeviceType;
  updateDeviceTypes: Array<DeviceType>;
  updateDevices: Array<Device>;
  updateGatewayDeviceConnection: GatewayDeviceConnection;
  updateGatewayDeviceConnections: Array<GatewayDeviceConnection>;
  updateImage: Image;
  updateImages: Array<Image>;
  updateLocation: Location;
  updateLocations: Array<Location>;
  updateMappingAttributeDeviceModelAttributeSet: MappingAttributeDeviceModelAttributeSet;
  updateMappingAttributeDeviceModelAttributeSets: Array<MappingAttributeDeviceModelAttributeSet>;
  updateMappingDeviceBuilding: MappingDeviceBuilding;
  updateMappingDeviceBuildingToBuilding: MappingDeviceBuilding;
  updateMappingDeviceBuildingToZone: MappingDeviceZone;
  updateMappingDeviceBuildings: Array<MappingDeviceBuilding>;
  updateMappingDeviceImage: MappingDeviceImage;
  updateMappingDeviceImages: Array<MappingDeviceImage>;
  updateMappingDeviceZone: MappingDeviceZone;
  updateMappingDeviceZoneToBuilding: MappingDeviceBuilding;
  updateMappingDeviceZoneToZone: MappingDeviceZone;
  updateMappingDeviceZones: Array<MappingDeviceZone>;
  updatePlacementOfDevice: DeviceMappingReturnType;
  updateRule: Rule;
  updateRuleWithChecks: RuleWithChecks;
  updateRules: Array<Rule>;
  updateRulesWithChecks: Array<RuleWithChecks>;
  updateSite: Site;
  updateSites: Array<Site>;
  updateStorey: Storey;
  updateStoreys: Array<Storey>;
  updateStressTestMappingTablesAsync: Scalars['String'];
  updateUnit: Unit;
  updateUnits: Array<Unit>;
  updateZone: Zone;
  updateZones: Array<Zone>;
};


export type MutationBulkProvisionIoTHubDevicesArgs = {
  numDevices: Scalars['Int'];
  numTenants: Scalars['Int'];
};


export type MutationCleanupGatewayDevicesFromIoTHubArgs = {
  tenantIds?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationCreateAttributeArgs = {
  attribute: AttributeCreateType;
};


export type MutationCreateAttributesArgs = {
  attributes: Array<AttributeCreateType>;
};


export type MutationCreateAuditLogArgs = {
  auditLog: AuditLogCreateType;
};


export type MutationCreateAuditLogsArgs = {
  auditLogs: Array<AuditLogCreateType>;
};


export type MutationCreateBuildingArgs = {
  building: BuildingCreateType;
};


export type MutationCreateBuildingsArgs = {
  buildings: Array<BuildingCreateType>;
};


export type MutationCreateCapabilitiesArgs = {
  capabilities: Array<CapabilityCreateType>;
};


export type MutationCreateCapabilityArgs = {
  capability: CapabilityCreateType;
};


export type MutationCreateDeviceModelArgs = {
  deviceModel: DeviceModelCreateType;
};


export type MutationCreateDeviceModelAttributeSetArgs = {
  deviceModelAttributeSet: DeviceModelAttributeSetCreateType;
};


export type MutationCreateDeviceModelAttributeSetsArgs = {
  deviceModelAttributeSets: Array<DeviceModelAttributeSetCreateType>;
};


export type MutationCreateDeviceModelCapabilitiesArgs = {
  deviceModelCapabilities: Array<DeviceModelCapabilityCreateType>;
};


export type MutationCreateDeviceModelCapabilityArgs = {
  deviceModelCapability: DeviceModelCapabilityCreateType;
};


export type MutationCreateDeviceModelsArgs = {
  deviceModels: Array<DeviceModelCreateType>;
};


export type MutationCreateDeviceTypeArgs = {
  deviceType: DeviceTypeCreateType;
};


export type MutationCreateDeviceTypesArgs = {
  deviceTypes: Array<DeviceTypeCreateType>;
};


export type MutationCreateDeviceWithPlacementArgs = {
  attributeValues: Array<DeviceCreateAttributeValueType>;
  device: DeviceCreateInputType;
  placementId: Scalars['UUID'];
  placementType: PlacementKind;
};


export type MutationCreateGatewayDeviceConnectionArgs = {
  gatewayDeviceConnection: GatewayDeviceConnectionCreateType;
};


export type MutationCreateGatewayDeviceConnectionsArgs = {
  gatewayDeviceConnections: Array<GatewayDeviceConnectionCreateType>;
};


export type MutationCreateImageArgs = {
  image: ImageCreateType;
};


export type MutationCreateImagesArgs = {
  images: Array<ImageCreateType>;
};


export type MutationCreateLocationArgs = {
  location: LocationCreateType;
};


export type MutationCreateLocationsArgs = {
  locations: Array<LocationCreateType>;
};


export type MutationCreateMappingAttributeDeviceModelAttributeSetArgs = {
  mappingAttributeDeviceModelAttributeSet: MappingAttributeDeviceModelAttributeSetCreateType;
};


export type MutationCreateMappingAttributeDeviceModelAttributeSetsArgs = {
  mappingAttributeDeviceModelAttributeSets: Array<MappingAttributeDeviceModelAttributeSetCreateType>;
};


export type MutationCreateMappingDeviceBuildingArgs = {
  mappingDeviceBuilding: MappingDeviceBuildingCreateType;
};


export type MutationCreateMappingDeviceBuildingsArgs = {
  mappingDeviceBuildings: Array<MappingDeviceBuildingCreateType>;
};


export type MutationCreateMappingDeviceImageArgs = {
  mappingDeviceImage: MappingDeviceImageCreateType;
};


export type MutationCreateMappingDeviceImagesArgs = {
  mappingDeviceImages: Array<MappingDeviceImageCreateType>;
};


export type MutationCreateMappingDeviceZoneArgs = {
  mappingDeviceZone: MappingDeviceZoneCreateType;
};


export type MutationCreateMappingDeviceZonesArgs = {
  mappingDeviceZones: Array<MappingDeviceZoneCreateType>;
};


export type MutationCreateRuleArgs = {
  rule: RuleCreateType;
};


export type MutationCreateRuleWithChecksArgs = {
  rule: RuleCreateType;
};


export type MutationCreateRulesArgs = {
  rules: Array<RuleCreateType>;
};


export type MutationCreateRulesWithChecksArgs = {
  rules: Array<RuleCreateType>;
};


export type MutationCreateSiteArgs = {
  site: SiteCreateType;
};


export type MutationCreateSitesArgs = {
  sites: Array<SiteCreateType>;
};


export type MutationCreateStoreyArgs = {
  storey: StoreyCreateType;
};


export type MutationCreateStoreysArgs = {
  storeys: Array<StoreyCreateType>;
};


export type MutationCreateUnitArgs = {
  unit: UnitCreateType;
};


export type MutationCreateUnitsArgs = {
  units: Array<UnitCreateType>;
};


export type MutationCreateZoneArgs = {
  zone: ZoneCreateType;
};


export type MutationCreateZonesArgs = {
  zones: Array<ZoneCreateType>;
};


export type MutationDeleteAttributeArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteAttributesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteBuildingArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteBuildingsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteCapabilitiesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteCapabilityArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteDeviceArgs = {
  deleteChildDevices?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
};


export type MutationDeleteDeviceCapabilitySensorMeasurementsArgs = {
  deviceId: Scalars['UUID'];
  deviceModelCapabilityIds?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteDeviceModelArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteDeviceModelAttributeSetArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteDeviceModelAttributeSetsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteDeviceModelCapabilitiesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteDeviceModelCapabilityArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteDeviceModelsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteDeviceTypeArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteDeviceTypesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteDevicesArgs = {
  deleteChildDevices?: InputMaybe<Scalars['Boolean']>;
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteDevicesByGatewayIdArgs = {
  gatewayId: Scalars['UUID'];
};


export type MutationDeleteGatewayDeviceConnectionArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteGatewayDeviceConnectionsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteImageArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteImagesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteLocationArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteLocationsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteMappingAttributeDeviceModelAttributeSetArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteMappingAttributeDeviceModelAttributeSetsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteMappingDeviceBuildingArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteMappingDeviceBuildingsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteMappingDeviceImageArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteMappingDeviceImagesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteMappingDeviceZoneArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteMappingDeviceZonesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteRuleArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteRulesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteSiteArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteSitesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteStoreyArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteStoreysArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteUnitArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteUnitsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteZoneArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteZonesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationExportIoTDevicesToBlobStorageArgs = {
  blobName?: InputMaybe<Scalars['String']>;
};


export type MutationRebuildDeviceMappingsInStorageTableArgs = {
  tenantIds?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationRedoCreateGatewayDeviceNotificationArgs = {
  deviceId: Scalars['UUID'];
};


export type MutationRegenerateGatewayDeviceConnectionKeyArgs = {
  deviceId: Scalars['UUID'];
  regeneratePrimaryKey?: Scalars['Boolean'];
  regenerateSecondaryKey?: Scalars['Boolean'];
};


export type MutationRegenerateGatewayDeviceConnectionsKeysArgs = {
  deviceIds: Array<Scalars['UUID']>;
  regeneratePrimaryKey?: Scalars['Boolean'];
  regenerateSecondaryKey?: Scalars['Boolean'];
};


export type MutationRegenerateGatewayDeviceSharedAccessSignatureArgs = {
  deviceId: Scalars['UUID'];
  expirySeconds: Scalars['Int'];
};


export type MutationRegenerateTenantGatewayDeviceConnectionsKeysArgs = {
  regeneratePrimaryKey?: Scalars['Boolean'];
  regenerateSecondaryKey?: Scalars['Boolean'];
};


export type MutationRemoveDeletedDeviceMappingsInStorageTableArgs = {
  tenantIds?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationSendContactRequestByEmailArgs = {
  request: EmailContactRequestInput;
};


export type MutationSendCustomFeatureRequestByRolesArgs = {
  request: CustomFeatureRequestInput;
};


export type MutationSendIdeaRequestByRolesArgs = {
  request: IdeaRequestInput;
};


export type MutationSetDeviceAttributeValueArgs = {
  attributeId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
  value: Scalars['String'];
};


export type MutationSetDeviceAttributeValuesArgs = {
  deviceId: Scalars['UUID'];
  input: Array<AttributeIdAttributeValueInputType>;
};


export type MutationTestSubscriptionDeviceMessageArgs = {
  deviceId: Scalars['UUID'];
};


export type MutationUnsetDeviceAttributeValueArgs = {
  attributeId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
};


export type MutationUnsetDeviceAttributeValuesArgs = {
  attributeIds: Array<Scalars['UUID']>;
  deviceId: Scalars['UUID'];
};


export type MutationUpdateAttributeArgs = {
  attribute: AttributeUpdateType;
};


export type MutationUpdateAttributesArgs = {
  attributes: Array<AttributeUpdateType>;
};


export type MutationUpdateBuildingArgs = {
  building: BuildingUpdateType;
};


export type MutationUpdateBuildingsArgs = {
  buildings: Array<BuildingUpdateType>;
};


export type MutationUpdateCapabilitiesArgs = {
  capabilities: Array<CapabilityUpdateType>;
};


export type MutationUpdateCapabilityArgs = {
  capability: CapabilityUpdateType;
};


export type MutationUpdateDeviceArgs = {
  device: DeviceUpdateType;
};


export type MutationUpdateDeviceModelArgs = {
  deviceModel: DeviceModelUpdateType;
};


export type MutationUpdateDeviceModelAttributeSetArgs = {
  deviceModelAttributeSet: DeviceModelAttributeSetUpdateType;
};


export type MutationUpdateDeviceModelAttributeSetsArgs = {
  deviceModelAttributeSets: Array<DeviceModelAttributeSetUpdateType>;
};


export type MutationUpdateDeviceModelCapabilitiesArgs = {
  deviceModelCapabilities: Array<DeviceModelCapabilityUpdateType>;
};


export type MutationUpdateDeviceModelCapabilityArgs = {
  deviceModelCapability: DeviceModelCapabilityUpdateType;
};


export type MutationUpdateDeviceModelsArgs = {
  deviceModels: Array<DeviceModelUpdateType>;
};


export type MutationUpdateDeviceTypeArgs = {
  deviceType: DeviceTypeUpdateType;
};


export type MutationUpdateDeviceTypesArgs = {
  deviceTypes: Array<DeviceTypeUpdateType>;
};


export type MutationUpdateDevicesArgs = {
  devices: Array<DeviceUpdateType>;
};


export type MutationUpdateGatewayDeviceConnectionArgs = {
  gatewayDeviceConnection: GatewayDeviceConnectionUpdateType;
};


export type MutationUpdateGatewayDeviceConnectionsArgs = {
  gatewayDeviceConnections: Array<GatewayDeviceConnectionUpdateType>;
};


export type MutationUpdateImageArgs = {
  image: ImageUpdateType;
};


export type MutationUpdateImagesArgs = {
  images: Array<ImageUpdateType>;
};


export type MutationUpdateLocationArgs = {
  location: LocationUpdateType;
};


export type MutationUpdateLocationsArgs = {
  locations: Array<LocationUpdateType>;
};


export type MutationUpdateMappingAttributeDeviceModelAttributeSetArgs = {
  mappingAttributeDeviceModelAttributeSet: MappingAttributeDeviceModelAttributeSetUpdateType;
};


export type MutationUpdateMappingAttributeDeviceModelAttributeSetsArgs = {
  mappingAttributeDeviceModelAttributeSets: Array<MappingAttributeDeviceModelAttributeSetUpdateType>;
};


export type MutationUpdateMappingDeviceBuildingArgs = {
  mappingDeviceBuilding: MappingDeviceBuildingUpdateType;
};


export type MutationUpdateMappingDeviceBuildingToBuildingArgs = {
  mappingDeviceBuildingToBuildingUpdate: MappingDeviceBuildingUpdateType;
};


export type MutationUpdateMappingDeviceBuildingToZoneArgs = {
  mappingDeviceBuildingToZoneUpdate: MappingDeviceZoneCreateType;
};


export type MutationUpdateMappingDeviceBuildingsArgs = {
  mappingDeviceBuildings: Array<MappingDeviceBuildingUpdateType>;
};


export type MutationUpdateMappingDeviceImageArgs = {
  mappingDeviceImage: MappingDeviceImageUpdateType;
};


export type MutationUpdateMappingDeviceImagesArgs = {
  mappingDeviceImages: Array<MappingDeviceImageUpdateType>;
};


export type MutationUpdateMappingDeviceZoneArgs = {
  mappingDeviceZone: MappingDeviceZoneUpdateType;
};


export type MutationUpdateMappingDeviceZoneToBuildingArgs = {
  mappingDeviceZoneToBuildingUpdate: MappingDeviceBuildingCreateType;
};


export type MutationUpdateMappingDeviceZoneToZoneArgs = {
  mappingDeviceZoneToZoneUpdate: MappingDeviceZoneUpdateType;
};


export type MutationUpdateMappingDeviceZonesArgs = {
  mappingDeviceZones: Array<MappingDeviceZoneUpdateType>;
};


export type MutationUpdatePlacementOfDeviceArgs = {
  deviceId: Scalars['UUID'];
  placementId: Scalars['UUID'];
  placementType: PlacementKind;
};


export type MutationUpdateRuleArgs = {
  rule: RuleUpdateType;
};


export type MutationUpdateRuleWithChecksArgs = {
  rule: RuleUpdateType;
};


export type MutationUpdateRulesArgs = {
  rules: Array<RuleUpdateType>;
};


export type MutationUpdateRulesWithChecksArgs = {
  rules: Array<RuleUpdateType>;
};


export type MutationUpdateSiteArgs = {
  site: SiteUpdateType;
};


export type MutationUpdateSitesArgs = {
  sites: Array<SiteUpdateType>;
};


export type MutationUpdateStoreyArgs = {
  storey: StoreyUpdateType;
};


export type MutationUpdateStoreysArgs = {
  storeys: Array<StoreyUpdateType>;
};


export type MutationUpdateStressTestMappingTablesAsyncArgs = {
  delete?: Scalars['Boolean'];
  numCapabilities: Scalars['Int'];
  numDevices: Scalars['Int'];
  numTenants: Scalars['Int'];
};


export type MutationUpdateUnitArgs = {
  unit: UnitUpdateType;
};


export type MutationUpdateUnitsArgs = {
  units: Array<UnitUpdateType>;
};


export type MutationUpdateZoneArgs = {
  zone: ZoneUpdateType;
};


export type MutationUpdateZonesArgs = {
  zones: Array<ZoneUpdateType>;
};

export type NodeInput = {
  id: Scalars['String'];
  type: Scalars['String'];
};

export type NullableOfHeartbeatStatesOperationFilterInput = {
  eq?: InputMaybe<HeartbeatStates>;
  in?: InputMaybe<Array<InputMaybe<HeartbeatStates>>>;
  neq?: InputMaybe<HeartbeatStates>;
  nin?: InputMaybe<Array<InputMaybe<HeartbeatStates>>>;
};

export type Output = {
  __typename?: 'Output';
  cypherCode: Scalars['String'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  objectClassType: Scalars['String'];
  objectName: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
  timeSeriesId: Scalars['String'];
};

export type PlacementFilterRow = {
  __typename?: 'PlacementFilterRow';
  buildingId?: Maybe<Scalars['UUID']>;
  deviceId?: Maybe<Scalars['UUID']>;
  locationId?: Maybe<Scalars['UUID']>;
  mappingPlacementId?: Maybe<Scalars['UUID']>;
  siteId?: Maybe<Scalars['UUID']>;
  storeyId?: Maybe<Scalars['UUID']>;
  zoneId?: Maybe<Scalars['UUID']>;
};

export type PlacementFilterRowFilterInput = {
  and?: InputMaybe<Array<PlacementFilterRowFilterInput>>;
  buildingId?: InputMaybe<UuidOperationFilterInput>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  locationId?: InputMaybe<UuidOperationFilterInput>;
  mappingPlacementId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<PlacementFilterRowFilterInput>>;
  siteId?: InputMaybe<UuidOperationFilterInput>;
  storeyId?: InputMaybe<UuidOperationFilterInput>;
  zoneId?: InputMaybe<UuidOperationFilterInput>;
};

export type PlacementFilterRowSortInput = {
  buildingId?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  locationId?: InputMaybe<SortEnumType>;
  mappingPlacementId?: InputMaybe<SortEnumType>;
  siteId?: InputMaybe<SortEnumType>;
  storeyId?: InputMaybe<SortEnumType>;
  zoneId?: InputMaybe<SortEnumType>;
};

export enum PlacementKind {
  Building = 'BUILDING',
  Zone = 'ZONE'
}

export type PlacementOfDevice = {
  __typename?: 'PlacementOfDevice';
  buildingCity?: Maybe<Scalars['String']>;
  buildingId?: Maybe<Scalars['UUID']>;
  buildingName?: Maybe<Scalars['String']>;
  buildingNumber?: Maybe<Scalars['String']>;
  buildingStreet?: Maybe<Scalars['String']>;
  buildingZip?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['UUID']>;
  locationCity?: Maybe<Scalars['String']>;
  locationCountry?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['UUID']>;
  mappingPlacementId?: Maybe<Scalars['UUID']>;
  mappingPlacementType?: Maybe<Scalars['String']>;
  siteCity?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['UUID']>;
  siteName?: Maybe<Scalars['String']>;
  siteNumber?: Maybe<Scalars['String']>;
  siteStreet?: Maybe<Scalars['String']>;
  siteZip?: Maybe<Scalars['String']>;
  storeyId?: Maybe<Scalars['UUID']>;
  storeyName?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['UUID']>;
  zoneName?: Maybe<Scalars['String']>;
};

export type Point = {
  __typename?: 'Point';
  id: Scalars['String'];
  name: Scalars['String'];
  timeseriesId: Scalars['String'];
  type: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  aggregates: Array<Aggregate>;
  attribute?: Maybe<Attribute>;
  attributes?: Maybe<AttributesCollectionSegment>;
  /** Queries all attributes assigned to devices in a building */
  attributesByBuildings?: Maybe<AttributesByBuildingsCollectionSegment>;
  auditLog?: Maybe<AuditLog>;
  /** Audit Logs */
  auditLogs?: Maybe<AuditLogsCollectionSegment>;
  building?: Maybe<Building>;
  buildingFilterTable?: Maybe<BuildingFilterTableCollectionSegment>;
  buildings?: Maybe<BuildingsCollectionSegment>;
  capabilities?: Maybe<CapabilitiesCollectionSegment>;
  capability?: Maybe<Capability>;
  createDemoSite: Scalars['Boolean'];
  /** Get DataService Info */
  dataServiceInfo?: Maybe<ServiceInfo>;
  device?: Maybe<Device>;
  deviceAttributeValue?: Maybe<DeviceAttributeValue>;
  deviceAttributeValues?: Maybe<DeviceAttributeValuesCollectionSegment>;
  deviceGroupedSensorDataByViews?: Maybe<Array<Maybe<TimeAndAggregationParameter>>>;
  deviceModel?: Maybe<DeviceModel>;
  deviceModelAttributeSet?: Maybe<DeviceModelAttributeSet>;
  deviceModelAttributeSets?: Maybe<DeviceModelAttributeSetsCollectionSegment>;
  deviceModelCapabilities?: Maybe<DeviceModelCapabilitiesCollectionSegment>;
  deviceModelCapability?: Maybe<DeviceModelCapability>;
  deviceModels?: Maybe<DeviceModelsCollectionSegment>;
  deviceType?: Maybe<DeviceType>;
  deviceTypes?: Maybe<DeviceTypesCollectionSegment>;
  deviceWithAttributes?: Maybe<DeviceWithAttributeValues>;
  devices?: Maybe<DevicesCollectionSegment>;
  /** Queries all devices in a building */
  devicesByBuildings?: Maybe<DevicesByBuildingsCollectionSegment>;
  fakeErrors: Array<TestError>;
  gatewayDeviceConnection?: Maybe<GatewayDeviceConnection>;
  gatewayDeviceConnections?: Maybe<GatewayDeviceConnectionsCollectionSegment>;
  gatewayDevices?: Maybe<Array<Maybe<Device>>>;
  image?: Maybe<Image>;
  images?: Maybe<ImagesCollectionSegment>;
  latestSensorData?: Maybe<Array<Maybe<DataPoint>>>;
  latestTimeseriesData?: Maybe<Array<Maybe<TimeseriesDataPoint>>>;
  location?: Maybe<Location>;
  locations?: Maybe<LocationsCollectionSegment>;
  mappingAttributeDeviceModelAttributeSet?: Maybe<MappingAttributeDeviceModelAttributeSet>;
  mappingAttributeDeviceModelAttributeSets?: Maybe<MappingAttributeDeviceModelAttributeSetsCollectionSegment>;
  mappingDeviceBuilding?: Maybe<MappingDeviceBuilding>;
  mappingDeviceBuildings?: Maybe<MappingDeviceBuildingsCollectionSegment>;
  mappingDeviceImage?: Maybe<MappingDeviceImage>;
  mappingDeviceImages?: Maybe<MappingDeviceImagesCollectionSegment>;
  mappingDeviceZone?: Maybe<MappingDeviceZone>;
  mappingDeviceZones?: Maybe<MappingDeviceZonesCollectionSegment>;
  multipleDeviceSensorDataByViews?: Maybe<Array<Maybe<TimeAndAggregationParameter>>>;
  /** Get Info of multi sensor measurements */
  multipleDeviceSensorDataInfo?: Maybe<SensorMeasurementsInfo>;
  multipleTimeseriesData?: Maybe<TimeseriesTimeAndAggregationParameter>;
  placementOfDevice?: Maybe<PlacementOfDevice>;
  resolveSpaceSensorTimeseries: Array<SpaceSensorTimeseries>;
  resolveTimeseriesId: Scalars['UUID'];
  resolveTimeseriesIdsByScoreTypes: Array<TimeseriesIdScoreType>;
  rule?: Maybe<Rule>;
  rules?: Maybe<RulesCollectionSegment>;
  /** Queries for timeseries data by score and space ID */
  scoreTimeseriesBySpace: TimeseriesDataBySpaceResult;
  /** Get list of sensor measurements */
  sensorMeasurements?: Maybe<SensorMeasurementsCollectionSegment>;
  /** Get Info of sensor measurement */
  sensorMeasurementsInfo?: Maybe<SensorMeasurementsInfo>;
  /** Queries for timeseries data by sensor type and space ID */
  sensorTimeseriesBySpaceAndType: TimeseriesDataBySpaceAndSensorResult;
  site?: Maybe<Site>;
  sites?: Maybe<SitesCollectionSegment>;
  spaceSystems: Array<System>;
  spaceTree: Array<SpaceNode>;
  spacesSensorsByType: Array<SpaceSensorsWithParents>;
  spacesStatistics: Array<SpacesStatistics>;
  storey?: Maybe<Storey>;
  storeys?: Maybe<StoreysCollectionSegment>;
  subsystemInstallationParts: Array<CategorizedInstallationParts>;
  subsystemInstallationPartsScores: Array<CategorizedInstallationPartsScores>;
  subsystemsScoreBySpace: Array<SubsystemsScore>;
  /** Queries for systems score by space ID */
  systemsScoreBySpace: Array<GroupedSystemsScore>;
  /** Get current server time */
  time: Scalars['DateTime'];
  unit?: Maybe<Unit>;
  units?: Maybe<UnitsCollectionSegment>;
  weatherInfoByCoordinates: ForecastResult;
  zone?: Maybe<Zone>;
  zoneAndBuildingFilterTable?: Maybe<ZoneAndBuildingFilterTableCollectionSegment>;
  zoneFilterTable?: Maybe<ZoneFilterTableCollectionSegment>;
  zones?: Maybe<ZonesCollectionSegment>;
};


export type QueryAggregatesArgs = {
  externalId: Scalars['String'];
};


export type QueryAttributeArgs = {
  id: Scalars['UUID'];
};


export type QueryAttributesArgs = {
  order?: InputMaybe<Array<AttributeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AttributeFilterInput>;
};


export type QueryAttributesByBuildingsArgs = {
  order?: InputMaybe<Array<AttributesByBuildingsSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AttributesByBuildingsFilterInput>;
};


export type QueryAuditLogArgs = {
  id: Scalars['UUID'];
};


export type QueryAuditLogsArgs = {
  order?: InputMaybe<Array<AuditLogSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AuditLogFilterInput>;
};


export type QueryBuildingArgs = {
  id: Scalars['UUID'];
};


export type QueryBuildingFilterTableArgs = {
  order?: InputMaybe<Array<PlacementFilterRowSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlacementFilterRowFilterInput>;
};


export type QueryBuildingsArgs = {
  order?: InputMaybe<Array<BuildingSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BuildingFilterInput>;
};


export type QueryCapabilitiesArgs = {
  order?: InputMaybe<Array<CapabilitySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CapabilityFilterInput>;
};


export type QueryCapabilityArgs = {
  id: Scalars['UUID'];
};


export type QueryCreateDemoSiteArgs = {
  demoSiteType: DemoSiteType;
};


export type QueryDeviceArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceAttributeValueArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceAttributeValuesArgs = {
  order?: InputMaybe<Array<DeviceAttributeValueSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceAttributeValueFilterInput>;
};


export type QueryDeviceGroupedSensorDataByViewsArgs = {
  where?: InputMaybe<QueryGroupFiltersMultipleInput>;
};


export type QueryDeviceModelArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceModelAttributeSetArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceModelAttributeSetsArgs = {
  order?: InputMaybe<Array<DeviceModelAttributeSetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelAttributeSetFilterInput>;
};


export type QueryDeviceModelCapabilitiesArgs = {
  order?: InputMaybe<Array<DeviceModelCapabilitySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelCapabilityFilterInput>;
};


export type QueryDeviceModelCapabilityArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceModelsArgs = {
  order?: InputMaybe<Array<DeviceModelSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelFilterInput>;
};


export type QueryDeviceTypeArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceTypesArgs = {
  order?: InputMaybe<Array<DeviceTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceTypeFilterInput>;
};


export type QueryDeviceWithAttributesArgs = {
  id: Scalars['UUID'];
};


export type QueryDevicesArgs = {
  order?: InputMaybe<Array<DeviceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceFilterInput>;
};


export type QueryDevicesByBuildingsArgs = {
  order?: InputMaybe<Array<DevicesByBuildingSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DevicesByBuildingFilterInput>;
};


export type QueryGatewayDeviceConnectionArgs = {
  id: Scalars['UUID'];
};


export type QueryGatewayDeviceConnectionsArgs = {
  order?: InputMaybe<Array<GatewayDeviceConnectionSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GatewayDeviceConnectionFilterInput>;
};


export type QueryImageArgs = {
  id: Scalars['UUID'];
};


export type QueryImagesArgs = {
  order?: InputMaybe<Array<ImageSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImageFilterInput>;
};


export type QueryLatestSensorDataArgs = {
  deviceId: Scalars['UUID'];
  deviceModelCapabilityIds?: InputMaybe<Array<Scalars['UUID']>>;
  limitPerCapability?: InputMaybe<Scalars['Int']>;
};


export type QueryLatestTimeseriesDataArgs = {
  limitPerTimeseries?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TimeseriesQueryFiltersInput>;
};


export type QueryLocationArgs = {
  id: Scalars['UUID'];
};


export type QueryLocationsArgs = {
  order?: InputMaybe<Array<LocationSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LocationFilterInput>;
};


export type QueryMappingAttributeDeviceModelAttributeSetArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingAttributeDeviceModelAttributeSetsArgs = {
  order?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
};


export type QueryMappingDeviceBuildingArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingDeviceBuildingsArgs = {
  order?: InputMaybe<Array<MappingDeviceBuildingSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceBuildingFilterInput>;
};


export type QueryMappingDeviceImageArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingDeviceImagesArgs = {
  order?: InputMaybe<Array<MappingDeviceImageSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceImageFilterInput>;
};


export type QueryMappingDeviceZoneArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingDeviceZonesArgs = {
  order?: InputMaybe<Array<MappingDeviceZoneSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceZoneFilterInput>;
};


export type QueryMultipleDeviceSensorDataByViewsArgs = {
  where?: InputMaybe<QueryFiltersMultipleInput>;
};


export type QueryMultipleDeviceSensorDataInfoArgs = {
  queryFilters?: InputMaybe<Array<InputMaybe<QueryFiltersMultipleDeviceAndCapabilityInput>>>;
};


export type QueryMultipleTimeseriesDataArgs = {
  where?: InputMaybe<QueryFiltersMultipleTimeseriesInput>;
};


export type QueryPlacementOfDeviceArgs = {
  deviceId: Scalars['UUID'];
};


export type QueryResolveSpaceSensorTimeseriesArgs = {
  where: QuerySpaceSensorInput;
};


export type QueryResolveTimeseriesIdArgs = {
  externalId: Scalars['String'];
  selector: SelectorInput;
};


export type QueryResolveTimeseriesIdsByScoreTypesArgs = {
  where: QueryResolveTimeseriesInput;
};


export type QueryRuleArgs = {
  id: Scalars['UUID'];
};


export type QueryRulesArgs = {
  order?: InputMaybe<Array<RuleSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RuleFilterInput>;
};


export type QueryScoreTimeseriesBySpaceArgs = {
  where: ScoreTimeseriesBySpaceInput;
};


export type QuerySensorMeasurementsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryFiltersInput>;
};


export type QuerySensorMeasurementsInfoArgs = {
  where?: InputMaybe<SensorInfoQueryFilterInput>;
};


export type QuerySensorTimeseriesBySpaceAndTypeArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortInput>;
  where: SensorTimeseriesBySpaceInput;
};


export type QuerySiteArgs = {
  id: Scalars['UUID'];
};


export type QuerySitesArgs = {
  order?: InputMaybe<Array<SiteSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SiteFilterInput>;
};


export type QuerySpaceSystemsArgs = {
  where: QuerySpaceSystemCategoriesInput;
};


export type QuerySpaceTreeArgs = {
  where?: InputMaybe<QuerySpaceTreeInput>;
};


export type QuerySpacesSensorsByTypeArgs = {
  where: QuerySpacesByTypeInput;
};


export type QuerySpacesStatisticsArgs = {
  where: SpacesStatisticsInput;
};


export type QueryStoreyArgs = {
  id: Scalars['UUID'];
};


export type QueryStoreysArgs = {
  order?: InputMaybe<Array<StoreySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreyFilterInput>;
};


export type QuerySubsystemInstallationPartsArgs = {
  where: QuerySubsystemInput;
};


export type QuerySubsystemInstallationPartsScoresArgs = {
  where: SubsystemInstallationPartsScoresInput;
};


export type QuerySubsystemsScoreBySpaceArgs = {
  where: SubsystemsScoresBySpaceInput;
};


export type QuerySystemsScoreBySpaceArgs = {
  where: SystemsScoresBySpaceInput;
};


export type QueryUnitArgs = {
  id: Scalars['UUID'];
};


export type QueryUnitsArgs = {
  order?: InputMaybe<Array<UnitSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UnitFilterInput>;
};


export type QueryWeatherInfoByCoordinatesArgs = {
  where: QueryWeatherInput;
};


export type QueryZoneArgs = {
  id: Scalars['UUID'];
};


export type QueryZoneAndBuildingFilterTableArgs = {
  order?: InputMaybe<Array<PlacementFilterRowSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlacementFilterRowFilterInput>;
};


export type QueryZoneFilterTableArgs = {
  order?: InputMaybe<Array<PlacementFilterRowSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlacementFilterRowFilterInput>;
};


export type QueryZonesArgs = {
  order?: InputMaybe<Array<ZoneSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ZoneFilterInput>;
};

export type QueryFiltersInput = {
  deviceId: Scalars['UUID'];
  deviceModelCapabilityIds: Array<Scalars['UUID']>;
  limit?: InputMaybe<Scalars['Int']>;
  readFromUtc?: InputMaybe<Scalars['DateTime']>;
  readUntilUtc?: InputMaybe<Scalars['DateTime']>;
};

export type QueryFiltersMultipleDeviceAndCapabilityInput = {
  deviceId: Scalars['UUID'];
  deviceModelCapabilityIds?: InputMaybe<Array<InputMaybe<DeviceModelCapabilityIdsInput>>>;
};

export type QueryFiltersMultipleInput = {
  aggregationIntervalTypeActive?: InputMaybe<AggregationIntervals>;
  aggregationType?: InputMaybe<AggregationTypes>;
  queryFiltersMultipleDeviceAndCapability?: InputMaybe<Array<InputMaybe<QueryFiltersMultipleDeviceAndCapabilityInput>>>;
  readFromUtc?: InputMaybe<Scalars['DateTime']>;
  readUntilNow?: InputMaybe<Scalars['Boolean']>;
  readUntilUtc?: InputMaybe<Scalars['DateTime']>;
  timeSpan?: InputMaybe<TimeSpans>;
};

export type QueryFiltersMultipleTimeseriesInput = {
  aggregationIntervalTypeActive?: InputMaybe<AggregationIntervals>;
  aggregationType?: InputMaybe<AggregationTypes>;
  readFromUtc?: InputMaybe<Scalars['DateTime']>;
  readUntilNow?: InputMaybe<Scalars['Boolean']>;
  readUntilUtc?: InputMaybe<Scalars['DateTime']>;
  timeSpan?: InputMaybe<TimeSpans>;
  timeseriesIds?: InputMaybe<Array<Scalars['UUID']>>;
};

export type QueryGroupFiltersMultipleInput = {
  aggregationIntervalTypeActive?: InputMaybe<AggregationIntervals>;
  aggregationType?: InputMaybe<AggregationTypes>;
  deviceModelCapabilityId: Scalars['UUID'];
  limit: Scalars['Int'];
  readFromUtc?: InputMaybe<Scalars['DateTime']>;
  readUntilNow?: InputMaybe<Scalars['Boolean']>;
  readUntilUtc?: InputMaybe<Scalars['DateTime']>;
  timeSpan?: InputMaybe<TimeSpans>;
};

export type QueryResolveTimeseriesInput = {
  externalIdScoreTypes: Array<ExternalIdScoreTypesInput>;
};

export type QuerySpaceSensorInput = {
  resolutionType: ResolutionType;
  sensorType: SensorType;
  space: SpaceInput;
};

export type QuerySpaceSystemCategoriesInput = {
  space: SpaceInput;
  systemTypes: Array<SystemType>;
};

export type QuerySpaceTreeInput = {
  maxTreeDepth?: InputMaybe<Scalars['Int']>;
  space?: InputMaybe<SpaceInput>;
};

export type QuerySpacesByTypeInput = {
  sensorTypes: Array<SensorType>;
  space: SpaceInput;
  spaceType: SpaceType;
};

export type QuerySubsystemInput = {
  categories: Array<CategoryType>;
  subsystem: NodeInput;
};

export type QueryWeatherInput = {
  language: Scalars['String'];
  latitude: Scalars['String'];
  longitude: Scalars['String'];
};

export enum ResolutionType {
  TowardsLeaf = 'TOWARDS_LEAF',
  TowardsRoot = 'TOWARDS_ROOT',
  TowardsRootAndLeaf = 'TOWARDS_ROOT_AND_LEAF'
}

export type Rule = {
  __typename?: 'Rule';
  createdAt?: Maybe<Scalars['DateTime']>;
  deployedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  json?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type RuleCreateType = {
  /** Rule Definition as JSON */
  json?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=512 */
  name?: InputMaybe<Scalars['String']>;
};

export type RuleFilterInput = {
  and?: InputMaybe<Array<RuleFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  deployedAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  json?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RuleFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type RuleSetNullInputType = {
  json?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
};

export type RuleSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  deployedAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  json?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type RuleUpdateType = {
  id: Scalars['UUID'];
  /** Rule Definition as JSON */
  json?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

export type RuleWithChecks = {
  __typename?: 'RuleWithChecks';
  createdAt?: Maybe<Scalars['DateTime']>;
  deployedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  json?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
};

/** A segment of a collection. */
export type RulesCollectionSegment = {
  __typename?: 'RulesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<Rule>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type Score = {
  __typename?: 'Score';
  scoreType: ScoreType;
  value?: Maybe<Scalars['Float']>;
};

export type ScoreData = {
  __typename?: 'ScoreData';
  average?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  scoreType: ScoreType;
};

export type ScoreTimeseriesBySpaceInput = {
  aggregationScoreType: ScoreType;
  dataFilters: DataQueryFiltersInput;
  spaceId: Scalars['String'];
  spaceType: SpaceType;
};

export enum ScoreType {
  Accuracy = 'ACCURACY',
  Co2 = 'CO2',
  Humidity = 'HUMIDITY',
  HumidityDeviation = 'HUMIDITY_DEVIATION',
  OnlineStatus = 'ONLINE_STATUS',
  OperatingHours = 'OPERATING_HOURS',
  Stability = 'STABILITY',
  StartStopCount = 'START_STOP_COUNT',
  Temperature = 'TEMPERATURE',
  TemperatureDeviation = 'TEMPERATURE_DEVIATION'
}

export type SelectorInput = {
  scoreType: ScoreType;
};

export type SensorInfoQueryFilterInput = {
  deviceId: Scalars['UUID'];
  deviceModelCapabilityId: Scalars['UUID'];
};

export type SensorMeasurement = {
  __typename?: 'SensorMeasurement';
  device: Device;
  deviceId: Scalars['UUID'];
  deviceModelCapability?: Maybe<DeviceModelCapability>;
  deviceModelCapabilityId: Scalars['UUID'];
  ingestedAt?: Maybe<Scalars['DateTime']>;
  insertedAt?: Maybe<Scalars['DateTime']>;
  measuredAt?: Maybe<Scalars['DateTime']>;
  measuredTimeZone?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
  utcTimeMeasured: Scalars['DateTime'];
  valueBool?: Maybe<Scalars['Boolean']>;
  valueDate?: Maybe<Scalars['DateTime']>;
  valueDateTime?: Maybe<Scalars['DateTime']>;
  valueDecimal?: Maybe<Scalars['Decimal']>;
  valueDouble?: Maybe<Scalars['Float']>;
  valueFloat?: Maybe<Scalars['Float']>;
  valueInteger?: Maybe<Scalars['Int']>;
  valueString?: Maybe<Scalars['String']>;
};

/** A segment of a collection. */
export type SensorMeasurementsCollectionSegment = {
  __typename?: 'SensorMeasurementsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<SensorMeasurement>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type SensorMeasurementsInfo = {
  __typename?: 'SensorMeasurementsInfo';
  count: Scalars['Int'];
  maxUtcTimeMeasured: Scalars['DateTime'];
  minUtcTimeMeasured: Scalars['DateTime'];
};

export type SensorTimeseriesBySpaceInput = {
  dataFilters: DataQueryFiltersInput;
  resolutionType: ResolutionType;
  sensorType: SensorType;
  spaceId: Scalars['String'];
  spaceType: SpaceType;
};

export enum SensorType {
  Co2Sensor = 'CO2_SENSOR',
  IndoorAirCo2Sensor = 'INDOOR_AIR_CO2_SENSOR',
  IndoorAirHumiditySensor = 'INDOOR_AIR_HUMIDITY_SENSOR',
  IndoorAirTemperatureSensor = 'INDOOR_AIR_TEMPERATURE_SENSOR',
  OutsideAirTemperatureSensor = 'OUTSIDE_AIR_TEMPERATURE_SENSOR'
}

export type ServiceInfo = {
  __typename?: 'ServiceInfo';
  dateTimeNow: Scalars['DateTime'];
  daylightSavingTimeName?: Maybe<Scalars['String']>;
  env?: Maybe<Scalars['String']>;
  utcNow: Scalars['DateTime'];
};

export type SharedAccessSignatureData = {
  __typename?: 'SharedAccessSignatureData';
  sharedAccessSignature: Scalars['String'];
  sharedAccessSignatureExpiry: Scalars['DateTime'];
};

/** I am a generated type! */
export type Site = {
  __typename?: 'Site';
  buildings: Array<Building>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['UUID']>;
  inverseParentSite: Array<Site>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  parentSite?: Maybe<Site>;
  parentSiteId?: Maybe<Scalars['UUID']>;
  tenantId?: Maybe<Scalars['UUID']>;
};


/** I am a generated type! */
export type SiteBuildingsArgs = {
  order?: InputMaybe<Array<BuildingSortInput>>;
  where?: InputMaybe<BuildingFilterInput>;
};


/** I am a generated type! */
export type SiteInverseParentSiteArgs = {
  order?: InputMaybe<Array<SiteSortInput>>;
  where?: InputMaybe<SiteFilterInput>;
};

export type SiteCreateType = {
  description?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['UUID']>;
  locationId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  parentSiteId?: InputMaybe<Scalars['UUID']>;
};

export type SiteFilterInput = {
  and?: InputMaybe<Array<SiteFilterInput>>;
  buildings?: InputMaybe<ListFilterInputTypeOfBuildingFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<UuidOperationFilterInput>;
  inverseParentSite?: InputMaybe<ListFilterInputTypeOfSiteFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  location?: InputMaybe<LocationFilterInput>;
  locationId?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SiteFilterInput>>;
  parentSite?: InputMaybe<SiteFilterInput>;
  parentSiteId?: InputMaybe<UuidOperationFilterInput>;
  siteId?: InputMaybe<UuidOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type SiteSetNullInputType = {
  buildings?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['Boolean']>;
  imageId?: InputMaybe<Scalars['Boolean']>;
  inverseParentSite?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  parentSite?: InputMaybe<Scalars['Boolean']>;
  parentSiteId?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type SiteSortInput = {
  description?: InputMaybe<SortEnumType>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  location?: InputMaybe<LocationSortInput>;
  locationId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  parentSite?: InputMaybe<SiteSortInput>;
  parentSiteId?: InputMaybe<SortEnumType>;
  siteId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type SiteUpdateType = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  imageId?: InputMaybe<Scalars['UUID']>;
  locationId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  parentSiteId?: InputMaybe<Scalars['UUID']>;
};

/** A segment of a collection. */
export type SitesCollectionSegment = {
  __typename?: 'SitesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Site>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export enum SortDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortInput = {
  direction?: InputMaybe<SortDirection>;
};

export type Space = {
  __typename?: 'Space';
  id: Scalars['String'];
  location?: Maybe<SpaceLocation>;
  name: Scalars['String'];
  type: SpaceType;
};

export type SpaceInput = {
  id: Scalars['String'];
  type: SpaceType;
};

export type SpaceLocation = {
  __typename?: 'SpaceLocation';
  coordinates?: Maybe<Coordinates>;
};

export type SpaceNode = {
  __typename?: 'SpaceNode';
  id: Scalars['String'];
  location?: Maybe<SpaceLocation>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  type: SpaceType;
};

export type SpaceSensorTimeseries = {
  __typename?: 'SpaceSensorTimeseries';
  sensorType: SensorType;
  space: Space;
  /** @deprecated Use the `space` field instead */
  spaceId: Scalars['String'];
  /** @deprecated Use the `space` field instead */
  spaceName: Scalars['String'];
  /** @deprecated Use the `space` field instead */
  spaceType: SpaceType;
  timeseriesId: Scalars['UUID'];
};

export type SpaceSensorsWithParents = {
  __typename?: 'SpaceSensorsWithParents';
  parents: Array<Space>;
  sensors: Array<Point>;
  space: Space;
};

export type SpaceTimeseriesInfo = {
  __typename?: 'SpaceTimeseriesInfo';
  data: Array<AggregatedData>;
  space: Space;
  /** @deprecated Use the `space` field instead */
  spaceId: Scalars['String'];
  /** @deprecated Use the `space` field instead */
  spaceName: Scalars['String'];
  /** @deprecated Use the `space` field instead */
  spaceType: SpaceType;
  timeseriesId: Scalars['UUID'];
};

export enum SpaceType {
  Building = 'BUILDING',
  Level = 'LEVEL',
  Room = 'ROOM',
  Site = 'SITE',
  Space = 'SPACE',
  SubBuilding = 'SUB_BUILDING'
}

export type SpacesStatistics = {
  __typename?: 'SpacesStatistics';
  scoreData: Array<ScoreData>;
  space: Space;
  spaces: Array<Space>;
};

export type SpacesStatisticsInput = {
  dataFilters: DataQueryFiltersInput;
  scoreTypes: Array<ScoreType>;
  space: SpaceInput;
  spaceType: SpaceType;
};

/** I am a generated type! */
export type Storey = {
  __typename?: 'Storey';
  building: Building;
  buildingId: Scalars['UUID'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['UUID']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
  zones: Array<Zone>;
};


/** I am a generated type! */
export type StoreyZonesArgs = {
  order?: InputMaybe<Array<ZoneSortInput>>;
  where?: InputMaybe<ZoneFilterInput>;
};

export type StoreyCreateType = {
  buildingId: Scalars['UUID'];
  description?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
};

export type StoreyFilterInput = {
  and?: InputMaybe<Array<StoreyFilterInput>>;
  building?: InputMaybe<BuildingFilterInput>;
  buildingId?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<StoreyFilterInput>>;
  storeyId?: InputMaybe<UuidOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  zones?: InputMaybe<ListFilterInputTypeOfZoneFilterInput>;
};

export type StoreySetNullInputType = {
  building?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['Boolean']>;
  imageId?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  zones?: InputMaybe<Scalars['Boolean']>;
};

export type StoreySortInput = {
  building?: InputMaybe<BuildingSortInput>;
  buildingId?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  storeyId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type StoreyUpdateType = {
  buildingId?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  imageId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type StoreysCollectionSegment = {
  __typename?: 'StoreysCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Storey>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type SubSystem = {
  __typename?: 'SubSystem';
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  commissioningStatusChanged?: Maybe<Gateway_Gateway_Gateway>;
  /** Subscription providing updates when a device's connection state is changed. */
  deviceStateChanged: DeviceMessage;
};


export type SubscriptionCommissioningStatusChangedArgs = {
  gatewayIds?: InputMaybe<Array<Scalars['String']>>;
};


export type SubscriptionDeviceStateChangedArgs = {
  deviceId: Scalars['UUID'];
};

export type Subsystem = {
  __typename?: 'Subsystem';
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type SubsystemInstallationPartsScoresInput = {
  categoryScores: Array<CategoryScoresInput>;
  dataFilters: DataQueryFiltersInput;
  subsystem: NodeInput;
};

export type SubsystemsScore = {
  __typename?: 'SubsystemsScore';
  score?: Maybe<Scalars['Float']>;
  spaces: Array<Space>;
  subsystem: Subsystem;
  systemType: SystemType;
};

export type SubsystemsScoresBySpaceInput = {
  dataFilters: DataQueryFiltersInput;
  space: SpaceInput;
  systemTypes: Array<SystemType>;
};

export type System = {
  __typename?: 'System';
  id: Scalars['String'];
  name: Scalars['String'];
  spaces: Array<Space>;
  subSystems: Array<SubSystem>;
  type: SystemType;
};

export enum SystemType {
  AirConditioning = 'AIR_CONDITIONING',
  ColdDistribution = 'COLD_DISTRIBUTION',
  ColdGeneration = 'COLD_GENERATION',
  DomesticHotWaterInstallation = 'DOMESTIC_HOT_WATER_INSTALLATION',
  FireProtectionSystem = 'FIRE_PROTECTION_SYSTEM',
  HeatColdDistribution = 'HEAT_COLD_DISTRIBUTION',
  HeatColdGeneration = 'HEAT_COLD_GENERATION',
  HeatDistribution = 'HEAT_DISTRIBUTION',
  HeatGeneration = 'HEAT_GENERATION',
  IndividualInstallations = 'INDIVIDUAL_INSTALLATIONS',
  RoomControl = 'ROOM_CONTROL',
  SwitchCabinet = 'SWITCH_CABINET'
}

export type SystemsScoresBySpaceInput = {
  dataFilters: DataQueryFiltersInput;
  space: SpaceInput;
};

export type TelemetryRecord = {
  __typename?: 'TelemetryRecord';
  utcTimeMeasured: Scalars['DateTime'];
  valueCount: Scalars['Float'];
  valueDouble: Scalars['Float'];
  valueMax: Scalars['Float'];
  valueMin: Scalars['Float'];
  valueString?: Maybe<Scalars['String']>;
};

export type Temperature = {
  __typename?: 'Temperature';
  unit: Scalars['String'];
  value: Scalars['Float'];
};

export type TestError = {
  __typename?: 'TestError';
  errorCode: Scalars['String'];
};

export type TimeAndAggregationParameter = {
  __typename?: 'TimeAndAggregationParameter';
  aggregationIntervalActive: AggregationIntervals;
  aggregationIntervalDefault: AggregationIntervals;
  aggregationIntervalsList?: Maybe<Array<AggregationIntervals>>;
  aggregationType?: Maybe<AggregationTypes>;
  deviceAndCapabilityInfos?: Maybe<Array<Maybe<DeviceAndCapabilityInfo>>>;
};

export enum TimeSpans {
  Last_1Hour = 'LAST_1_HOUR',
  Last_2Hour = 'LAST_2_HOUR',
  Last_4Hour = 'LAST_4_HOUR',
  Last_7Day = 'LAST_7_DAY',
  Last_12Hour = 'LAST_12_HOUR',
  Last_15Min = 'LAST_15_MIN',
  Last_24Hour = 'LAST_24_HOUR',
  Last_30Day = 'LAST_30_DAY',
  Last_30Min = 'LAST_30_MIN',
  Last_90Day = 'LAST_90_DAY'
}

export type TimeWindow = {
  __typename?: 'TimeWindow';
  cypherCode: Scalars['String'];
  id: Scalars['UUID'];
  minutes: Scalars['Int'];
  name: Scalars['String'];
  objectClassType: Scalars['String'];
  objectName: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
  useGapFill: Scalars['Boolean'];
  windowType: TimeWindowType;
};

export enum TimeWindowType {
  Sliding = 'SLIDING',
  Tumbeling = 'TUMBELING'
}

export type TimeseriesDataBySpaceAndSensorResult = {
  __typename?: 'TimeseriesDataBySpaceAndSensorResult';
  aggregationIntervalActive?: Maybe<AggregationIntervals>;
  spaceTimeseriesInfos: Array<SpaceTimeseriesInfo>;
};

export type TimeseriesDataBySpaceResult = {
  __typename?: 'TimeseriesDataBySpaceResult';
  aggregationIntervalActive: AggregationIntervals;
  timeseriesInfos: Array<TimeseriesInfo>;
};

export type TimeseriesDataPoint = {
  __typename?: 'TimeseriesDataPoint';
  mostAccurateTime: Scalars['DateTime'];
  tenantId: Scalars['UUID'];
  timeseriesId: Scalars['UUID'];
  value: Scalars['Float'];
};

export type TimeseriesIdScoreType = {
  __typename?: 'TimeseriesIdScoreType';
  externalId: Scalars['String'];
  scoreType: ScoreType;
  timeseriesId: Scalars['UUID'];
};

export type TimeseriesInfo = {
  __typename?: 'TimeseriesInfo';
  data: Array<AggregatedData>;
  timeseriesId: Scalars['UUID'];
};

export type TimeseriesQueryFiltersInput = {
  limit?: InputMaybe<Scalars['Int']>;
  readFromUtc?: InputMaybe<Scalars['DateTime']>;
  readUntilUtc?: InputMaybe<Scalars['DateTime']>;
  timeseriesIds: Array<Scalars['UUID']>;
};

export type TimeseriesTelemetryRecord = {
  __typename?: 'TimeseriesTelemetryRecord';
  timestamp: Scalars['DateTime'];
  value: Scalars['Float'];
  valueCount: Scalars['Float'];
  valueMax: Scalars['Float'];
  valueMin: Scalars['Float'];
};

export type TimeseriesTimeAndAggregationParameter = {
  __typename?: 'TimeseriesTimeAndAggregationParameter';
  aggregationIntervalActive: AggregationIntervals;
  aggregationIntervalDefault: AggregationIntervals;
  aggregationIntervalsList?: Maybe<Array<AggregationIntervals>>;
  aggregationType?: Maybe<AggregationTypes>;
  timeseriesInfos?: Maybe<Array<Maybe<DataSchema_TimeseriesInfo>>>;
};

export type Unit = {
  __typename?: 'Unit';
  deviceModelCapabilities: Array<DeviceModelCapability>;
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
  unitSymbol?: Maybe<Scalars['String']>;
  uris?: Maybe<Array<Scalars['String']>>;
};


export type UnitDeviceModelCapabilitiesArgs = {
  order?: InputMaybe<Array<DeviceModelCapabilitySortInput>>;
  where?: InputMaybe<DeviceModelCapabilityFilterInput>;
};

export type UnitCreateType = {
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  /** MinLength=0, MaxLength=250 */
  unitSymbol?: InputMaybe<Scalars['String']>;
  uris?: InputMaybe<Array<Scalars['String']>>;
};

export type UnitFilterInput = {
  and?: InputMaybe<Array<UnitFilterInput>>;
  deviceModelCapabilities?: InputMaybe<ListFilterInputTypeOfDeviceModelCapabilityFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UnitFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  unitId?: InputMaybe<UuidOperationFilterInput>;
  unitSymbol?: InputMaybe<StringOperationFilterInput>;
  uris?: InputMaybe<ListStringOperationFilterInput>;
};

export type UnitSetNullInputType = {
  deviceModelCapabilities?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  unitSymbol?: InputMaybe<Scalars['Boolean']>;
  uris?: InputMaybe<Scalars['Boolean']>;
};

export type UnitSortInput = {
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  unitId?: InputMaybe<SortEnumType>;
  unitSymbol?: InputMaybe<SortEnumType>;
};

export type UnitUpdateType = {
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  unitSymbol?: InputMaybe<Scalars['String']>;
  uris?: InputMaybe<Array<Scalars['String']>>;
};

/** A segment of a collection. */
export type UnitsCollectionSegment = {
  __typename?: 'UnitsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Unit>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type UserBaseInput = {
  /** Email address of the person */
  email: Scalars['String'];
  /** Full name of the person */
  fullName: Scalars['String'];
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']>;
  gt?: InputMaybe<Scalars['UUID']>;
  gte?: InputMaybe<Scalars['UUID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  lt?: InputMaybe<Scalars['UUID']>;
  lte?: InputMaybe<Scalars['UUID']>;
  neq?: InputMaybe<Scalars['UUID']>;
  ngt?: InputMaybe<Scalars['UUID']>;
  ngte?: InputMaybe<Scalars['UUID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  nlt?: InputMaybe<Scalars['UUID']>;
  nlte?: InputMaybe<Scalars['UUID']>;
};

export type ValueEntity = {
  __typename?: 'ValueEntity';
  value?: Maybe<Scalars['String']>;
};

export type ValueEntityFilterInput = {
  and?: InputMaybe<Array<ValueEntityFilterInput>>;
  or?: InputMaybe<Array<ValueEntityFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type Variable = {
  __typename?: 'Variable';
  cypherCode: Scalars['String'];
  endiMatch: Scalars['String'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  objectClassType: Scalars['String'];
  objectName: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
  variableType: VariableType;
  windowType: TimeWindowType;
};

export enum VariableType {
  Cache = 'CACHE',
  Stream = 'STREAM',
  StreamGroupWindow = 'STREAM_GROUP_WINDOW',
  StreamWindow = 'STREAM_WINDOW'
}

/** Weather information for building location */
export type Weather = {
  __typename?: 'Weather';
  building?: Maybe<Building>;
  buildingId: Scalars['UUID'];
  iceProbability: Scalars['Int'];
  iconCode: Scalars['Int'];
  lastUpdated: Scalars['DateTime'];
  phrase: Scalars['String'];
  rainProbability: Scalars['Int'];
  snowProbability: Scalars['Int'];
  temperatureUnit: Scalars['String'];
  temperatureValue: Scalars['Float'];
  weatherId: Scalars['UUID'];
};

export type WeatherFilterInput = {
  and?: InputMaybe<Array<WeatherFilterInput>>;
  building?: InputMaybe<BuildingFilterInput>;
  buildingId?: InputMaybe<UuidOperationFilterInput>;
  iceProbability?: InputMaybe<IntOperationFilterInput>;
  iconCode?: InputMaybe<IntOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<WeatherFilterInput>>;
  phrase?: InputMaybe<StringOperationFilterInput>;
  rainProbability?: InputMaybe<IntOperationFilterInput>;
  snowProbability?: InputMaybe<IntOperationFilterInput>;
  temperatureUnit?: InputMaybe<StringOperationFilterInput>;
  temperatureValue?: InputMaybe<FloatOperationFilterInput>;
  weatherId?: InputMaybe<UuidOperationFilterInput>;
};

export type WeatherSortInput = {
  building?: InputMaybe<BuildingSortInput>;
  buildingId?: InputMaybe<SortEnumType>;
  iceProbability?: InputMaybe<SortEnumType>;
  iconCode?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  phrase?: InputMaybe<SortEnumType>;
  rainProbability?: InputMaybe<SortEnumType>;
  snowProbability?: InputMaybe<SortEnumType>;
  temperatureUnit?: InputMaybe<SortEnumType>;
  temperatureValue?: InputMaybe<SortEnumType>;
  weatherId?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type Zone = {
  __typename?: 'Zone';
  description?: Maybe<Scalars['String']>;
  dimension?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['UUID']>;
  inverseParentZone: Array<Zone>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  mappingDeviceZones: Array<MappingDeviceZone>;
  name: Scalars['String'];
  parentZone?: Maybe<Zone>;
  parentZoneId?: Maybe<Scalars['UUID']>;
  storey: Storey;
  storeyId: Scalars['UUID'];
  tenantId?: Maybe<Scalars['UUID']>;
};


/** I am a generated type! */
export type ZoneInverseParentZoneArgs = {
  order?: InputMaybe<Array<ZoneSortInput>>;
  where?: InputMaybe<ZoneFilterInput>;
};


/** I am a generated type! */
export type ZoneMappingDeviceZonesArgs = {
  order?: InputMaybe<Array<MappingDeviceZoneSortInput>>;
  where?: InputMaybe<MappingDeviceZoneFilterInput>;
};

/** A segment of a collection. */
export type ZoneAndBuildingFilterTableCollectionSegment = {
  __typename?: 'ZoneAndBuildingFilterTableCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PlacementFilterRow>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type ZoneCreateType = {
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  dimension?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  parentZoneId?: InputMaybe<Scalars['UUID']>;
  storeyId: Scalars['UUID'];
};

export type ZoneFilterInput = {
  and?: InputMaybe<Array<ZoneFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  dimension?: InputMaybe<StringOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<UuidOperationFilterInput>;
  inverseParentZone?: InputMaybe<ListFilterInputTypeOfZoneFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceZones?: InputMaybe<ListFilterInputTypeOfMappingDeviceZoneFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ZoneFilterInput>>;
  parentZone?: InputMaybe<ZoneFilterInput>;
  parentZoneId?: InputMaybe<UuidOperationFilterInput>;
  storey?: InputMaybe<StoreyFilterInput>;
  storeyId?: InputMaybe<UuidOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  zoneId?: InputMaybe<UuidOperationFilterInput>;
};

/** A segment of a collection. */
export type ZoneFilterTableCollectionSegment = {
  __typename?: 'ZoneFilterTableCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PlacementFilterRow>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type ZoneSetNullInputType = {
  description?: InputMaybe<Scalars['Boolean']>;
  dimension?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['Boolean']>;
  imageId?: InputMaybe<Scalars['Boolean']>;
  inverseParentZone?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceZones?: InputMaybe<Scalars['Boolean']>;
  parentZone?: InputMaybe<Scalars['Boolean']>;
  parentZoneId?: InputMaybe<Scalars['Boolean']>;
  storey?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type ZoneSortInput = {
  description?: InputMaybe<SortEnumType>;
  dimension?: InputMaybe<SortEnumType>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  parentZone?: InputMaybe<ZoneSortInput>;
  parentZoneId?: InputMaybe<SortEnumType>;
  storey?: InputMaybe<StoreySortInput>;
  storeyId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  zoneId?: InputMaybe<SortEnumType>;
};

export type ZoneUpdateType = {
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  dimension?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  imageId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  parentZoneId?: InputMaybe<Scalars['UUID']>;
  storeyId?: InputMaybe<Scalars['UUID']>;
};

/** A segment of a collection. */
export type ZonesCollectionSegment = {
  __typename?: 'ZonesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Zone>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type BuildingInformationSchema_InstallationPart = {
  __typename?: 'buildingInformationSchema_InstallationPart';
  id: Scalars['String'];
  name: Scalars['String'];
  timeseriesId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type DataSchema_TimeseriesInfo = {
  __typename?: 'dataSchema_TimeseriesInfo';
  telemetryRecords?: Maybe<Array<Maybe<TimeseriesTelemetryRecord>>>;
  tenantId?: Maybe<Scalars['UUID']>;
  timeseriesId: Scalars['UUID'];
};

export type FieldDeviceListGatewayDetailsQueryVariables = Exact<{
  gatewayId: Scalars['UUID'];
}>;


export type FieldDeviceListGatewayDetailsQuery = { __typename?: 'Query', device?: { __typename?: 'Device', id: string, name: string, description?: string | null, deviceIdentifier?: string | null, inverseParentDevice: Array<{ __typename?: 'Device', id: string, name: string, description?: string | null, serialNo?: string | null, deviceIdentifier?: string | null, parentDeviceId?: string | null, deviceModelId: string, deviceModel: { __typename?: 'DeviceModel', id: string, name: string, deviceTypeId: string, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, fieldSelector?: string | null, technicalMax?: number | null, technicalMin?: number | null, capability: { __typename?: 'Capability', id: string, name: string, description?: string | null, dataType: DataTypes }, unit: { __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null } }>, deviceType: { __typename?: 'DeviceType', id: string, name: string, isGateway?: boolean | null } } }>, deviceModel: { __typename?: 'DeviceModel', id: string, name: string, deviceType: { __typename?: 'DeviceType', id: string, name: string, isGateway?: boolean | null }, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, fieldSelector?: string | null, technicalMax?: number | null, technicalMin?: number | null, capability: { __typename?: 'Capability', id: string, name: string, description?: string | null, dataType: DataTypes }, unit: { __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null } }> } } | null, placementOfDevice?: { __typename?: 'PlacementOfDevice', zoneName?: string | null, buildingName?: string | null, locationCity?: string | null, locationCountry?: string | null, siteName?: string | null, storeyName?: string | null } | null };

export type GatewayListQueryVariables = Exact<{ [key: string]: never; }>;


export type GatewayListQuery = { __typename?: 'Query', devices?: { __typename?: 'DevicesCollectionSegment', items?: Array<{ __typename?: 'Device', id: string, name: string, description?: string | null, connectionState: ConnectionStates, inverseParentDevice: Array<{ __typename?: 'Device', id: string, name: string, deviceModel: { __typename?: 'DeviceModel', deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string }> } }>, deviceModel: { __typename?: 'DeviceModel', id: string, name: string, deviceType: { __typename?: 'DeviceType', id: string, name: string, isGateway?: boolean | null }, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string }> } }> | null } | null };

export type AttributeCreateMutationVariables = Exact<{
  attributeInput: AttributeCreateType;
}>;


export type AttributeCreateMutation = { __typename?: 'Mutation', createAttribute?: { __typename?: 'Attribute', id: string } | null };

export type AttributeDeleteMutationVariables = Exact<{
  attributeId: Scalars['UUID'];
}>;


export type AttributeDeleteMutation = { __typename?: 'Mutation', deleteAttribute?: { __typename?: 'Attribute', id: string } | null };

export type AttributeDetailsQueryVariables = Exact<{
  attributeId: Scalars['UUID'];
}>;


export type AttributeDetailsQuery = { __typename?: 'Query', attribute?: { __typename?: 'Attribute', id: string, name: string, description?: string | null, dataType: DataTypes, required?: boolean | null, isGlobal?: boolean | null } | null };

export type AttributeEditDetailsQueryVariables = Exact<{
  attributeId: Scalars['UUID'];
}>;


export type AttributeEditDetailsQuery = { __typename?: 'Query', attribute?: { __typename?: 'Attribute', id: string, name: string, description?: string | null, dataType: DataTypes, required?: boolean | null, isGlobal?: boolean | null } | null };

export type AttributeUpdateMutationVariables = Exact<{
  attributeInput: AttributeUpdateType;
}>;


export type AttributeUpdateMutation = { __typename?: 'Mutation', updateAttribute: { __typename?: 'Attribute', id: string } };

export type AttributeListQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  where?: InputMaybe<AttributeFilterInput>;
}>;


export type AttributeListQuery = { __typename?: 'Query', attributes?: { __typename?: 'AttributesCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'Attribute', id: string, name: string, isGlobal?: boolean | null, mappingAttributeDeviceModelAttributeSets: Array<{ __typename?: 'MappingAttributeDeviceModelAttributeSet', id: string }> }> | null } | null };

export type BuildingCreateParentSiteQueryVariables = Exact<{
  siteId: Scalars['UUID'];
}>;


export type BuildingCreateParentSiteQuery = { __typename?: 'Query', site?: { __typename?: 'Site', id: string, name: string, location?: { __typename?: 'Location', city?: string | null, country?: string | null, no?: string | null, street?: string | null, zip?: string | null } | null } | null };

export type BuildingCreateMutationVariables = Exact<{
  building: BuildingCreateType;
}>;


export type BuildingCreateMutation = { __typename?: 'Mutation', createBuilding?: { __typename?: 'Building', id: string } | null };

export type BuildingCreateLocationCreateMutationVariables = Exact<{
  locationInput: LocationCreateType;
}>;


export type BuildingCreateLocationCreateMutation = { __typename?: 'Mutation', createLocation?: { __typename?: 'Location', id: string } | null };

export type BuildingDeleteMutationVariables = Exact<{
  buildingId: Scalars['UUID'];
}>;


export type BuildingDeleteMutation = { __typename?: 'Mutation', deleteBuilding?: { __typename?: 'Building', id: string } | null };

export type BuildingDetailsQueryVariables = Exact<{
  buildingId: Scalars['UUID'];
  where?: InputMaybe<StoreyFilterInput>;
}>;


export type BuildingDetailsQuery = { __typename?: 'Query', building?: { __typename?: 'Building', id: string, name: string, dimension?: string | null, description?: string | null, image?: { __typename?: 'Image', name: string, url?: string | null } | null, location?: { __typename?: 'Location', id: string, street?: string | null, no?: string | null, zip?: string | null, city?: string | null, county?: string | null, country?: string | null } | null, site: { __typename?: 'Site', id: string, name: string } } | null, storeys?: { __typename?: 'StoreysCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'Storey', id: string, buildingId: string }> | null } | null };

export type BuildingDetailsDevicesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DevicesByBuildingFilterInput>;
}>;


export type BuildingDetailsDevicesQuery = { __typename?: 'Query', devicesByBuildings?: { __typename?: 'DevicesByBuildingsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'DevicesByBuilding', device: { __typename?: 'Device', id: string, name: string, deviceModel: { __typename?: 'DeviceModel', name: string } } }> | null } | null };

export type BuildingDetailsStoreysQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreyFilterInput>;
}>;


export type BuildingDetailsStoreysQuery = { __typename?: 'Query', storeys?: { __typename?: 'StoreysCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'Storey', id: string, name: string, buildingId: string }> | null } | null };

export type BuildingEditDetailsQueryVariables = Exact<{
  buildingId: Scalars['UUID'];
}>;


export type BuildingEditDetailsQuery = { __typename?: 'Query', building?: { __typename?: 'Building', id: string, name: string, description?: string | null, dimension?: string | null, site: { __typename?: 'Site', id: string, name: string } } | null };

export type BuildingUpdateMutationVariables = Exact<{
  building: BuildingUpdateType;
}>;


export type BuildingUpdateMutation = { __typename?: 'Mutation', updateBuilding: { __typename?: 'Building', id: string } };

export type BuildingLocationDetailsQueryVariables = Exact<{
  buildingId: Scalars['UUID'];
}>;


export type BuildingLocationDetailsQuery = { __typename?: 'Query', building?: { __typename?: 'Building', id: string, name: string, locationId?: string | null, location?: { __typename?: 'Location', id: string, street?: string | null, no?: string | null, zip?: string | null, city?: string | null, county?: string | null, country?: string | null } | null, site: { __typename?: 'Site', id: string, name: string } } | null };

export type BuildingLocationBuildingUpdateMutationVariables = Exact<{
  building: BuildingUpdateType;
}>;


export type BuildingLocationBuildingUpdateMutation = { __typename?: 'Mutation', updateBuilding: { __typename?: 'Building', id: string } };

export type BuildingLocationCreateMutationVariables = Exact<{
  location: LocationCreateType;
}>;


export type BuildingLocationCreateMutation = { __typename?: 'Mutation', createLocation?: { __typename?: 'Location', id: string } | null };

export type BuildingLocationUpdateMutationVariables = Exact<{
  location: LocationUpdateType;
}>;


export type BuildingLocationUpdateMutation = { __typename?: 'Mutation', updateLocation: { __typename?: 'Location', id: string } };

export type CapabilityCreateMutationVariables = Exact<{
  capabilityInput: CapabilityCreateType;
}>;


export type CapabilityCreateMutation = { __typename?: 'Mutation', createCapability?: { __typename?: 'Capability', id: string } | null };

export type CapabilityDeleteMutationVariables = Exact<{
  capabilityId: Scalars['UUID'];
}>;


export type CapabilityDeleteMutation = { __typename?: 'Mutation', deleteCapability?: { __typename?: 'Capability', id: string } | null };

export type CapabilityDetailsQueryVariables = Exact<{
  capabilityId: Scalars['UUID'];
}>;


export type CapabilityDetailsQuery = { __typename?: 'Query', capability?: { __typename?: 'Capability', id: string, name: string, description?: string | null, dataType: DataTypes, isGlobal?: boolean | null, isLocked?: boolean | null } | null };

export type CapabilityEditDetailsQueryVariables = Exact<{
  capabilityId: Scalars['UUID'];
}>;


export type CapabilityEditDetailsQuery = { __typename?: 'Query', capability?: { __typename?: 'Capability', id: string, name: string, description?: string | null, dataType: DataTypes, isGlobal?: boolean | null } | null };

export type CapabilityUpdateMutationVariables = Exact<{
  capabilityInput: CapabilityUpdateType;
}>;


export type CapabilityUpdateMutation = { __typename?: 'Mutation', updateCapability: { __typename?: 'Capability', id: string } };

export type CapabilityListQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  where?: InputMaybe<CapabilityFilterInput>;
}>;


export type CapabilityListQuery = { __typename?: 'Query', capabilities?: { __typename?: 'CapabilitiesCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'Capability', id: string, name: string, description?: string | null, isGlobal?: boolean | null, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string }> }> | null } | null };

export type DeviceModelAttributeSetCreateDetailsQueryVariables = Exact<{
  deviceModelId: Scalars['UUID'];
}>;


export type DeviceModelAttributeSetCreateDetailsQuery = { __typename?: 'Query', deviceModel?: { __typename?: 'DeviceModel', id: string, name: string, isGlobal?: boolean | null } | null };

export type DeviceModelAttributeSetCreateMutationVariables = Exact<{
  deviceModelAttributeSetInput: DeviceModelAttributeSetCreateType;
}>;


export type DeviceModelAttributeSetCreateMutation = { __typename?: 'Mutation', createDeviceModelAttributeSet?: { __typename?: 'DeviceModelAttributeSet', id: string } | null };

export type DeviceModelAttributeSetDeleteMutationVariables = Exact<{
  deviceModelAttributeSetId: Scalars['UUID'];
}>;


export type DeviceModelAttributeSetDeleteMutation = { __typename?: 'Mutation', deleteDeviceModelAttributeSet?: { __typename?: 'DeviceModelAttributeSet', id: string } | null };

export type DeviceModelAttributeSetDetailsQueryVariables = Exact<{
  deviceModelAttributeSetId: Scalars['UUID'];
}>;


export type DeviceModelAttributeSetDetailsQuery = { __typename?: 'Query', deviceModelAttributeSet?: { __typename?: 'DeviceModelAttributeSet', id: string, name: string, deviceModelId: string, deviceModel: { __typename?: 'DeviceModel', name: string, isGlobal?: boolean | null } } | null };

export type DeviceModelAttributeSetDetailsAttributesQueryVariables = Exact<{
  deviceModelAttributeSetId: Scalars['UUID'];
}>;


export type DeviceModelAttributeSetDetailsAttributesQuery = { __typename?: 'Query', deviceModelAttributeSet?: { __typename?: 'DeviceModelAttributeSet', id: string, name: string, deviceModelId: string, deviceModel: { __typename?: 'DeviceModel', id: string, name: string, isGlobal?: boolean | null, deviceModelAttributeSets: Array<{ __typename?: 'DeviceModelAttributeSet', id: string, mappingAttributeDeviceModelAttributeSets: Array<{ __typename?: 'MappingAttributeDeviceModelAttributeSet', attributeId: string }> }> }, mappingAttributeDeviceModelAttributeSets: Array<{ __typename?: 'MappingAttributeDeviceModelAttributeSet', id: string, attributeId: string, attribute: { __typename?: 'Attribute', id: string, name: string, dataType: DataTypes, required?: boolean | null } }> } | null, attributes?: { __typename?: 'AttributesCollectionSegment', items?: Array<{ __typename?: 'Attribute', id: string, name: string, dataType: DataTypes, required?: boolean | null }> | null } | null };

export type DeviceModelAttributeSetDetailsAttributeCreateMutationVariables = Exact<{
  mappingInput: MappingAttributeDeviceModelAttributeSetCreateType;
}>;


export type DeviceModelAttributeSetDetailsAttributeCreateMutation = { __typename?: 'Mutation', createMappingAttributeDeviceModelAttributeSet?: { __typename?: 'MappingAttributeDeviceModelAttributeSet', id: string } | null };

export type DeviceModelAttributeSetDetailsAttributeUpdateMutationVariables = Exact<{
  mappingInput: MappingAttributeDeviceModelAttributeSetUpdateType;
}>;


export type DeviceModelAttributeSetDetailsAttributeUpdateMutation = { __typename?: 'Mutation', updateMappingAttributeDeviceModelAttributeSet: { __typename?: 'MappingAttributeDeviceModelAttributeSet', id: string } };

export type DeviceModelAttributeSetDetailsAttributeDeleteMutationVariables = Exact<{
  mappingId: Scalars['UUID'];
}>;


export type DeviceModelAttributeSetDetailsAttributeDeleteMutation = { __typename?: 'Mutation', deleteMappingAttributeDeviceModelAttributeSet?: { __typename?: 'MappingAttributeDeviceModelAttributeSet', id: string } | null };

export type DeviceModelAttributeSetEditDetailsQueryVariables = Exact<{
  deviceModelAttributeSetId: Scalars['UUID'];
}>;


export type DeviceModelAttributeSetEditDetailsQuery = { __typename?: 'Query', deviceModelAttributeSet?: { __typename?: 'DeviceModelAttributeSet', id: string, deviceModelId: string, name: string, deviceModel: { __typename?: 'DeviceModel', name: string, isGlobal?: boolean | null } } | null };

export type DeviceModelAttributeSetUpdateMutationVariables = Exact<{
  deviceModelAttributeSetInput: DeviceModelAttributeSetUpdateType;
}>;


export type DeviceModelAttributeSetUpdateMutation = { __typename?: 'Mutation', updateDeviceModelAttributeSet: { __typename?: 'DeviceModelAttributeSet', id: string } };

export type DeviceModelCapabilityCreateDetailsQueryVariables = Exact<{
  deviceModelId: Scalars['UUID'];
}>;


export type DeviceModelCapabilityCreateDetailsQuery = { __typename?: 'Query', deviceModel?: { __typename?: 'DeviceModel', id: string, name: string, isGlobal?: boolean | null, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', fieldSelector?: string | null }> } | null, capabilities?: { __typename?: 'CapabilitiesCollectionSegment', items?: Array<{ __typename?: 'Capability', id: string, name: string, dataType: DataTypes }> | null } | null, units?: { __typename?: 'UnitsCollectionSegment', items?: Array<{ __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null, uris?: Array<string> | null }> | null } | null };

export type DeviceModelCapabilityCreateMutationVariables = Exact<{
  deviceModelCapabilityInput: DeviceModelCapabilityCreateType;
}>;


export type DeviceModelCapabilityCreateMutation = { __typename?: 'Mutation', createDeviceModelCapability?: { __typename?: 'DeviceModelCapability', id: string } | null };

export type DeviceModelCapabilityDeleteMutationVariables = Exact<{
  deviceModelCapabilityId: Scalars['UUID'];
}>;


export type DeviceModelCapabilityDeleteMutation = { __typename?: 'Mutation', deleteDeviceModelCapability?: { __typename?: 'DeviceModelCapability', id: string } | null };

export type DeviceModelCapabilityDetailsQueryVariables = Exact<{
  deviceModelCapabilityId: Scalars['UUID'];
}>;


export type DeviceModelCapabilityDetailsQuery = { __typename?: 'Query', deviceModelCapability?: { __typename?: 'DeviceModelCapability', id: string, isLocked?: boolean | null, deviceModelId: string, fieldSelector?: string | null, technicalMax?: number | null, technicalMin?: number | null, deviceModel: { __typename?: 'DeviceModel', name: string, isGlobal?: boolean | null }, capability: { __typename?: 'Capability', name: string, dataType: DataTypes }, unit: { __typename?: 'Unit', name: string, unitSymbol?: string | null, uris?: Array<string> | null } } | null };

export type DeviceModelCapabilityEditDetailsQueryVariables = Exact<{
  deviceModelCapabilityId: Scalars['UUID'];
}>;


export type DeviceModelCapabilityEditDetailsQuery = { __typename?: 'Query', deviceModelCapability?: { __typename?: 'DeviceModelCapability', id: string, deviceModelId: string, fieldSelector?: string | null, technicalMax?: number | null, technicalMin?: number | null, deviceModel: { __typename?: 'DeviceModel', id: string, name: string, isGlobal?: boolean | null, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', fieldSelector?: string | null }> }, capability: { __typename?: 'Capability', id: string, name: string }, unit: { __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null } } | null, capabilities?: { __typename?: 'CapabilitiesCollectionSegment', items?: Array<{ __typename?: 'Capability', id: string, name: string, dataType: DataTypes }> | null } | null, units?: { __typename?: 'UnitsCollectionSegment', items?: Array<{ __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null, uris?: Array<string> | null }> | null } | null };

export type DeviceModelCapabilityUpdateMutationVariables = Exact<{
  deviceModelCapabilityInput: DeviceModelCapabilityUpdateType;
}>;


export type DeviceModelCapabilityUpdateMutation = { __typename?: 'Mutation', updateDeviceModelCapability: { __typename?: 'DeviceModelCapability', id: string } };

export type DeviceTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type DeviceTypesQuery = { __typename?: 'Query', deviceTypes?: { __typename?: 'DeviceTypesCollectionSegment', items?: Array<{ __typename?: 'DeviceType', id: string, name: string, isGateway?: boolean | null }> | null } | null };

export type DeviceModelCreateMutationVariables = Exact<{
  deviceModel: DeviceModelCreateType;
}>;


export type DeviceModelCreateMutation = { __typename?: 'Mutation', createDeviceModel?: { __typename?: 'DeviceModel', id: string } | null };

export type DeviceModelDeleteMutationVariables = Exact<{
  deviceModelId: Scalars['UUID'];
}>;


export type DeviceModelDeleteMutation = { __typename?: 'Mutation', deleteDeviceModel?: { __typename?: 'DeviceModel', id: string } | null };

export type DeviceModelDetailsQueryVariables = Exact<{
  deviceModelId: Scalars['UUID'];
}>;


export type DeviceModelDetailsQuery = { __typename?: 'Query', deviceModel?: { __typename?: 'DeviceModel', id: string, name: string, deviceIdentifierFieldSelector?: string | null, isGlobal?: boolean | null, isLocked?: boolean | null, deviceModelAttributeSets: Array<{ __typename?: 'DeviceModelAttributeSet', id: string, name: string, mappingAttributeDeviceModelAttributeSets: Array<{ __typename?: 'MappingAttributeDeviceModelAttributeSet', id: string, attributeId: string, attribute: { __typename?: 'Attribute', id: string, name: string, required?: boolean | null } }> }>, deviceType: { __typename?: 'DeviceType', id: string, name: string, isGateway?: boolean | null } } | null };

export type DeviceModelDetailsAttributeSetsQueryVariables = Exact<{
  deviceModelId: Scalars['UUID'];
}>;


export type DeviceModelDetailsAttributeSetsQuery = { __typename?: 'Query', deviceModel?: { __typename?: 'DeviceModel', id: string, isGlobal?: boolean | null, deviceModelAttributeSets: Array<{ __typename?: 'DeviceModelAttributeSet', id: string, name: string, mappingAttributeDeviceModelAttributeSets: Array<{ __typename?: 'MappingAttributeDeviceModelAttributeSet', id: string, attributeId: string }> }> } | null };

export type DeviceModelDetailsCapabilitiesQueryVariables = Exact<{
  deviceModelId: Scalars['UUID'];
}>;


export type DeviceModelDetailsCapabilitiesQuery = { __typename?: 'Query', deviceModel?: { __typename?: 'DeviceModel', id: string, name: string, isGlobal?: boolean | null, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, technicalMax?: number | null, technicalMin?: number | null, capability: { __typename?: 'Capability', id: string, name: string }, unit: { __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null } }> } | null };

export type DeviceModelEditDetailsQueryVariables = Exact<{
  deviceModelId: Scalars['UUID'];
}>;


export type DeviceModelEditDetailsQuery = { __typename?: 'Query', deviceModel?: { __typename?: 'DeviceModel', id: string, name: string, deviceIdentifierFieldSelector?: string | null, isGlobal?: boolean | null, isLocked?: boolean | null, deviceType: { __typename?: 'DeviceType', id: string, name: string, isGateway?: boolean | null } } | null };

export type DeviceModelUpdateMutationVariables = Exact<{
  deviceModelInput: DeviceModelUpdateType;
}>;


export type DeviceModelUpdateMutation = { __typename?: 'Mutation', updateDeviceModel: { __typename?: 'DeviceModel', id: string } };

export type DeviceModelsListQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  where?: InputMaybe<DeviceModelFilterInput>;
}>;


export type DeviceModelsListQuery = { __typename?: 'Query', deviceModels?: { __typename?: 'DeviceModelsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'DeviceModel', id: string, name: string, isGlobal?: boolean | null, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', capability: { __typename?: 'Capability', id: string, name: string } }>, devices: Array<{ __typename?: 'Device', id: string }> }> | null } | null };

export type DeviceAttributesQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
}>;


export type DeviceAttributesQuery = { __typename?: 'Query', device?: { __typename?: 'Device', name: string, deviceModel: { __typename?: 'DeviceModel', deviceModelAttributeSets: Array<{ __typename?: 'DeviceModelAttributeSet', id: string, name: string, mappingAttributeDeviceModelAttributeSets: Array<{ __typename?: 'MappingAttributeDeviceModelAttributeSet', attribute: { __typename?: 'Attribute', name: string, id: string, required?: boolean | null, dataType: DataTypes } }> }> }, deviceAttributeValues: Array<{ __typename?: 'DeviceAttributeValue', value?: string | null, id: string }> } | null };

export type SetDeviceAttributeValueMutationVariables = Exact<{
  deviceId: Scalars['UUID'];
  attributeId: Scalars['UUID'];
  value: Scalars['String'];
}>;


export type SetDeviceAttributeValueMutation = { __typename?: 'Mutation', setDeviceAttributeValue?: { __typename?: 'DeviceAttributeValue', deviceId: string, attributeId: string, value?: string | null } | null };

export type UnsetDeviceAttributeValueMutationVariables = Exact<{
  deviceId: Scalars['UUID'];
  attributeId: Scalars['UUID'];
}>;


export type UnsetDeviceAttributeValueMutation = { __typename?: 'Mutation', unsetDeviceAttributeValue?: { __typename?: 'DeviceAttributeValue', deviceId: string, attributeId: string, value?: string | null } | null };

export type DeviceIdentifierExistsQueryVariables = Exact<{
  deviceIdentifier: Scalars['String'];
}>;


export type DeviceIdentifierExistsQuery = { __typename?: 'Query', devices?: { __typename?: 'DevicesCollectionSegment', items?: Array<{ __typename?: 'Device', id: string, deviceIdentifier?: string | null }> | null } | null };

export type FieldDeviceCreateDeviceModelsAndSitesQueryVariables = Exact<{ [key: string]: never; }>;


export type FieldDeviceCreateDeviceModelsAndSitesQuery = { __typename?: 'Query', deviceModels?: { __typename?: 'DeviceModelsCollectionSegment', items?: Array<{ __typename?: 'DeviceModel', id: string, name: string, deviceType: { __typename?: 'DeviceType', isGateway?: boolean | null }, deviceModelAttributeSets: Array<{ __typename?: 'DeviceModelAttributeSet', id: string, name: string, mappingAttributeDeviceModelAttributeSets: Array<{ __typename?: 'MappingAttributeDeviceModelAttributeSet', attribute: { __typename?: 'Attribute', name: string, id: string, required?: boolean | null, dataType: DataTypes } }> }> }> | null } | null, sites?: { __typename?: 'SitesCollectionSegment', items?: Array<{ __typename?: 'Site', id: string, name: string, buildings: Array<{ __typename?: 'Building', id: string, name: string, siteId: string, storeys: Array<{ __typename?: 'Storey', id: string, name: string, buildingId: string, zones: Array<{ __typename?: 'Zone', id: string, name: string, storeyId: string }> }> }> }> | null } | null };

export type FieldDeviceCreateGatewayDevicesQueryVariables = Exact<{ [key: string]: never; }>;


export type FieldDeviceCreateGatewayDevicesQuery = { __typename?: 'Query', devices?: { __typename?: 'DevicesCollectionSegment', items?: Array<{ __typename?: 'Device', id: string, name: string, deviceModel: { __typename?: 'DeviceModel', deviceType: { __typename?: 'DeviceType', isGateway?: boolean | null } } }> | null } | null };

export type FieldDeviceCreateWithPlacementMutationVariables = Exact<{
  device: DeviceCreateInputType;
  placementId: Scalars['UUID'];
  placementType: PlacementKind;
  attributeValues: Array<DeviceCreateAttributeValueType> | DeviceCreateAttributeValueType;
}>;


export type FieldDeviceCreateWithPlacementMutation = { __typename?: 'Mutation', createDeviceWithPlacement: { __typename?: 'DeviceCreate', id?: string | null } };

export type GatewayCreateDeviceModelsAndSitesQueryVariables = Exact<{ [key: string]: never; }>;


export type GatewayCreateDeviceModelsAndSitesQuery = { __typename?: 'Query', deviceModels?: { __typename?: 'DeviceModelsCollectionSegment', items?: Array<{ __typename?: 'DeviceModel', id: string, name: string, deviceType: { __typename?: 'DeviceType', isGateway?: boolean | null }, deviceModelAttributeSets: Array<{ __typename?: 'DeviceModelAttributeSet', id: string, name: string, mappingAttributeDeviceModelAttributeSets: Array<{ __typename?: 'MappingAttributeDeviceModelAttributeSet', attribute: { __typename?: 'Attribute', name: string, id: string, required?: boolean | null, dataType: DataTypes } }> }> }> | null } | null, sites?: { __typename?: 'SitesCollectionSegment', items?: Array<{ __typename?: 'Site', id: string, name: string, buildings: Array<{ __typename?: 'Building', id: string, name: string, siteId: string, storeys: Array<{ __typename?: 'Storey', id: string, name: string, buildingId: string, zones: Array<{ __typename?: 'Zone', id: string, name: string, storeyId: string }> }> }> }> | null } | null };

export type GatewayCreateWithPlacementMutationVariables = Exact<{
  device: DeviceCreateInputType;
  placementId: Scalars['UUID'];
  placementType: PlacementKind;
  attributeValues: Array<DeviceCreateAttributeValueType> | DeviceCreateAttributeValueType;
}>;


export type GatewayCreateWithPlacementMutation = { __typename?: 'Mutation', createDeviceWithPlacement: { __typename?: 'DeviceCreate', id?: string | null } };

export type DeviceDeleteMutationVariables = Exact<{
  deviceId: Scalars['UUID'];
  deleteChildDevices?: InputMaybe<Scalars['Boolean']>;
}>;


export type DeviceDeleteMutation = { __typename?: 'Mutation', deleteDevice?: { __typename?: 'Device', id: string } | null };

export type DeploymentsGatewayDetailsQueryVariables = Exact<{
  gatewayId: Scalars['UUID'];
}>;


export type DeploymentsGatewayDetailsQuery = { __typename?: 'Query', device?: { __typename?: 'Device', id: string, name: string } | null, placementOfDevice?: { __typename?: 'PlacementOfDevice', buildingName?: string | null, siteName?: string | null } | null };

export type CapabilityDataQueryVariables = Exact<{
  readFromUtc?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
}>;


export type CapabilityDataQuery = { __typename?: 'Query', sensorMeasurements?: { __typename?: 'SensorMeasurementsCollectionSegment', items?: Array<{ __typename?: 'SensorMeasurement', deviceId: string, deviceModelCapabilityId: string, utcTimeMeasured: any, valueString?: string | null }> | null } | null };

export type DeviceDetailsQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
}>;


export type DeviceDetailsQuery = { __typename?: 'Query', device?: { __typename?: 'Device', id: string, name: string, isLocked?: boolean | null, deviceModelId: string, description?: string | null, serialNo?: string | null, deviceIdentifier?: string | null, parentDeviceId?: string | null, deviceModel: { __typename?: 'DeviceModel', id: string, name: string, deviceTypeId: string, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, technicalMax?: number | null, technicalMin?: number | null, unit: { __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null }, capability: { __typename?: 'Capability', id: string, name: string } }>, deviceType: { __typename?: 'DeviceType', id: string, name: string, isGateway?: boolean | null }, deviceModelAttributeSets: Array<{ __typename?: 'DeviceModelAttributeSet', id: string, name: string, mappingAttributeDeviceModelAttributeSets: Array<{ __typename?: 'MappingAttributeDeviceModelAttributeSet', attribute: { __typename?: 'Attribute', id: string, name: string, required?: boolean | null, dataType: DataTypes } }> }> }, deviceAttributeValues: Array<{ __typename?: 'DeviceAttributeValue', value?: string | null, id: string }>, parentDevice?: { __typename?: 'Device', id: string, name: string } | null } | null, placementOfDevice?: { __typename?: 'PlacementOfDevice', zoneId?: string | null, zoneName?: string | null, buildingId?: string | null, buildingName?: string | null, locationCity?: string | null, locationCountry?: string | null, siteId?: string | null, siteName?: string | null, storeyId?: string | null, storeyName?: string | null } | null };

export type DeviceDetailsGatewayConfigQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
}>;


export type DeviceDetailsGatewayConfigQuery = { __typename?: 'Query', device?: { __typename?: 'Device', id: string, gatewayDeviceConnections: Array<{ __typename?: 'GatewayDeviceConnection', name: string, user: string, host: string, port: number, sharedAccessSignatureExpiry?: any | null }> } | null };

export type RegenerateSharedAccessSignatureMutationVariables = Exact<{
  deviceId: Scalars['UUID'];
  expirySeconds: Scalars['Int'];
}>;


export type RegenerateSharedAccessSignatureMutation = { __typename?: 'Mutation', regenerateGatewayDeviceSharedAccessSignature: { __typename?: 'SharedAccessSignatureData', sharedAccessSignature: string, sharedAccessSignatureExpiry: any } };

export type GatewayStatusQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
}>;


export type GatewayStatusQuery = { __typename?: 'Query', device?: { __typename?: 'Device', id: string, connectionState: ConnectionStates, heartbeatState?: HeartbeatStates | null, firstConnected?: any | null, lastConnected?: any | null } | null };

export type GatewayNameQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
}>;


export type GatewayNameQuery = { __typename?: 'Query', device?: { __typename?: 'Device', id: string, name: string } | null };

export type DeviceEditDetailsQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
}>;


export type DeviceEditDetailsQuery = { __typename?: 'Query', device?: { __typename?: 'Device', id: string, name: string, description?: string | null, deviceModelId: string, parentDeviceId?: string | null, serialNo?: string | null, deviceIdentifier?: string | null, parentDevice?: { __typename?: 'Device', id: string, name: string } | null, deviceModel: { __typename?: 'DeviceModel', id: string, name: string, deviceType: { __typename?: 'DeviceType', isGateway?: boolean | null } } } | null };

export type DeviceEditGatewayDevicesQueryVariables = Exact<{ [key: string]: never; }>;


export type DeviceEditGatewayDevicesQuery = { __typename?: 'Query', devices?: { __typename?: 'DevicesCollectionSegment', items?: Array<{ __typename?: 'Device', id: string, name: string, deviceModel: { __typename?: 'DeviceModel', deviceType: { __typename?: 'DeviceType', isGateway?: boolean | null } } }> | null } | null };

export type DeviceUpdateMutationVariables = Exact<{
  device: DeviceUpdateType;
}>;


export type DeviceUpdateMutation = { __typename?: 'Mutation', updateDevice: { __typename?: 'Device', id: string } };

export type DevicesListQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  where?: InputMaybe<DeviceFilterInput>;
}>;


export type DevicesListQuery = { __typename?: 'Query', devices?: { __typename?: 'DevicesCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'Device', name: string, id: string, isLocked?: boolean | null, deviceModelId: string, deviceModel: { __typename?: 'DeviceModel', id: string, name: string, deviceType: { __typename?: 'DeviceType', id: string, name: string } } }> | null } | null };

export type DeviceLocationQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
}>;


export type DeviceLocationQuery = { __typename?: 'Query', device?: { __typename?: 'Device', id: string, name: string } | null, sites?: { __typename?: 'SitesCollectionSegment', items?: Array<{ __typename?: 'Site', id: string, name: string, buildings: Array<{ __typename?: 'Building', id: string, name: string, siteId: string, storeys: Array<{ __typename?: 'Storey', id: string, name: string, buildingId: string, zones: Array<{ __typename?: 'Zone', id: string, name: string, storeyId: string }> }> }> }> | null } | null, placementOfDevice?: { __typename?: 'PlacementOfDevice', siteId?: string | null, siteName?: string | null, buildingId?: string | null, buildingName?: string | null, storeyId?: string | null, storeyName?: string | null, zoneId?: string | null, zoneName?: string | null } | null };

export type DeviceLocationUpdateMutationVariables = Exact<{
  deviceId: Scalars['UUID'];
  locationId: Scalars['UUID'];
  locationType: PlacementKind;
}>;


export type DeviceLocationUpdateMutation = { __typename?: 'Mutation', updatePlacementOfDevice: { __typename?: 'DeviceMappingReturnType', id: string } };

export type MappingsCreateGatewayDetailsQueryVariables = Exact<{
  gatewayId: Scalars['UUID'];
}>;


export type MappingsCreateGatewayDetailsQuery = { __typename?: 'Query', device?: { __typename?: 'Device', id: string, name: string } | null, placementOfDevice?: { __typename?: 'PlacementOfDevice', mappingPlacementType?: string | null, buildingName?: string | null, buildingId?: string | null, siteName?: string | null, zoneId?: string | null } | null };

export type MappingsCreateDeviceModelsQueryVariables = Exact<{
  modelIds?: InputMaybe<Array<Scalars['UUID']> | Scalars['UUID']>;
}>;


export type MappingsCreateDeviceModelsQuery = { __typename?: 'Query', deviceModels?: { __typename?: 'DeviceModelsCollectionSegment', items?: Array<{ __typename?: 'DeviceModel', id: string, name: string, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, fieldSelector?: string | null, technicalMax?: number | null, technicalMin?: number | null, capability: { __typename?: 'Capability', id: string, name: string, description?: string | null, dataType: DataTypes }, unit: { __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null, uris?: Array<string> | null } }> }> | null } | null };

export type MappingsCreateDeviceByNameQueryVariables = Exact<{
  deviceNames?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type MappingsCreateDeviceByNameQuery = { __typename?: 'Query', devices?: { __typename?: 'DevicesCollectionSegment', items?: Array<{ __typename?: 'Device', id: string, name: string }> | null } | null };

export type MappingsEditGatewayDetailsQueryVariables = Exact<{
  gatewayId: Scalars['UUID'];
}>;


export type MappingsEditGatewayDetailsQuery = { __typename?: 'Query', device?: { __typename?: 'Device', id: string, name: string } | null, placementOfDevice?: { __typename?: 'PlacementOfDevice', mappingPlacementType?: string | null, buildingName?: string | null, buildingId?: string | null, siteName?: string | null, zoneId?: string | null } | null };

export type MappingsEditAllDevicesQueryVariables = Exact<{
  gatewayId?: InputMaybe<Scalars['UUID']>;
}>;


export type MappingsEditAllDevicesQuery = { __typename?: 'Query', devices?: { __typename?: 'DevicesCollectionSegment', items?: Array<{ __typename?: 'Device', id: string, name: string, deviceModelId: string, deviceModel: { __typename?: 'DeviceModel', id: string, name: string, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, technicalMax?: number | null, technicalMin?: number | null, capability: { __typename?: 'Capability', id: string, name: string, description?: string | null, dataType: DataTypes }, unit: { __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null } }> } }> | null } | null };

export type MappingsEditDeviceCreateWithPlacementMutationVariables = Exact<{
  device: DeviceCreateInputType;
  placementId: Scalars['UUID'];
  placementType: PlacementKind;
  attributeValues: Array<DeviceCreateAttributeValueType> | DeviceCreateAttributeValueType;
}>;


export type MappingsEditDeviceCreateWithPlacementMutation = { __typename?: 'Mutation', createDeviceWithPlacement: { __typename?: 'DeviceCreate', id?: string | null, name?: string | null, deviceModelId?: string | null, deviceModel: { __typename?: 'DeviceModel', id: string, name: string, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, technicalMax?: number | null, technicalMin?: number | null, capability: { __typename?: 'Capability', id: string, name: string, description?: string | null, dataType: DataTypes }, unit: { __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null } }> } } };

export type DeviceDetailsForDataQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
}>;


export type DeviceDetailsForDataQuery = { __typename?: 'Query', placementOfDevice?: { __typename?: 'PlacementOfDevice', zoneId?: string | null, zoneName?: string | null, buildingId?: string | null, buildingName?: string | null, locationCity?: string | null, locationCountry?: string | null, siteId?: string | null, siteName?: string | null, storeyId?: string | null, storeyName?: string | null } | null, device?: { __typename?: 'Device', id: string, name: string, deviceModelId: string, serialNo?: string | null, deviceModel: { __typename?: 'DeviceModel', id: string, name: string, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, technicalMax?: number | null, technicalMin?: number | null, unit: { __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null }, capability: { __typename?: 'Capability', id: string, name: string } }> }, parentDevice?: { __typename?: 'Device', id: string, name: string } | null, inverseParentDevice: Array<{ __typename?: 'Device', name: string, id: string }> } | null };

export type HistoricalDataQueryVariables = Exact<{
  readFromUtc?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  deviceId: Scalars['UUID'];
  deviceModelCapabilityIds: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type HistoricalDataQuery = { __typename?: 'Query', sensorMeasurements?: { __typename?: 'SensorMeasurementsCollectionSegment', items?: Array<{ __typename?: 'SensorMeasurement', deviceId: string, deviceModelCapabilityId: string, utcTimeMeasured: any, valueString?: string | null }> | null } | null };

export type SiteCreateMutationVariables = Exact<{
  site: SiteCreateType;
}>;


export type SiteCreateMutation = { __typename?: 'Mutation', createSite?: { __typename?: 'Site', id: string } | null };

export type SiteCreateBuildingMutationVariables = Exact<{
  building: BuildingCreateType;
}>;


export type SiteCreateBuildingMutation = { __typename?: 'Mutation', createBuilding?: { __typename?: 'Building', id: string } | null };

export type SiteCreateLocationCreateMutationVariables = Exact<{
  locationInput: LocationCreateType;
}>;


export type SiteCreateLocationCreateMutation = { __typename?: 'Mutation', createLocation?: { __typename?: 'Location', id: string } | null };

export type SiteDeleteMutationVariables = Exact<{
  siteId: Scalars['UUID'];
}>;


export type SiteDeleteMutation = { __typename?: 'Mutation', deleteSite?: { __typename?: 'Site', id: string } | null };

export type SiteDetailsQueryVariables = Exact<{
  siteId: Scalars['UUID'];
}>;


export type SiteDetailsQuery = { __typename?: 'Query', site?: { __typename?: 'Site', id: string, name: string, description?: string | null, locationId?: string | null, image?: { __typename?: 'Image', name: string, url?: string | null } | null, location?: { __typename?: 'Location', id: string, street?: string | null, no?: string | null, zip?: string | null, city?: string | null, county?: string | null, country?: string | null } | null } | null };

export type SiteDetailsBuildingsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BuildingFilterInput>;
}>;


export type SiteDetailsBuildingsQuery = { __typename?: 'Query', buildings?: { __typename?: 'BuildingsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'Building', id: string, name: string, dimension?: string | null, description?: string | null, locationId?: string | null, siteId: string, storeys: Array<{ __typename?: 'Storey', id: string, name: string }>, location?: { __typename?: 'Location', id: string, street?: string | null, no?: string | null, zip?: string | null, city?: string | null, county?: string | null, country?: string | null } | null, site: { __typename?: 'Site', id: string, name: string } }> | null } | null };

export type SiteEditDetailsQueryVariables = Exact<{
  siteId: Scalars['UUID'];
}>;


export type SiteEditDetailsQuery = { __typename?: 'Query', site?: { __typename?: 'Site', id: string, name: string, description?: string | null } | null };

export type SiteEditUpdateMutationVariables = Exact<{
  site: SiteUpdateType;
}>;


export type SiteEditUpdateMutation = { __typename?: 'Mutation', updateSite: { __typename?: 'Site', id: string } };

export type SiteListQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  where?: InputMaybe<SiteFilterInput>;
}>;


export type SiteListQuery = { __typename?: 'Query', sites?: { __typename?: 'SitesCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'Site', id: string, name: string, locationId?: string | null, location?: { __typename?: 'Location', id: string, street?: string | null, no?: string | null, zip?: string | null, city?: string | null, county?: string | null, country?: string | null } | null }> | null } | null };

export type SiteLocationDetailsQueryVariables = Exact<{
  siteId: Scalars['UUID'];
}>;


export type SiteLocationDetailsQuery = { __typename?: 'Query', site?: { __typename?: 'Site', id: string, name: string, locationId?: string | null, location?: { __typename?: 'Location', id: string, street?: string | null, no?: string | null, zip?: string | null, city?: string | null, county?: string | null, country?: string | null } | null } | null };

export type SiteLocationSiteUpdateMutationVariables = Exact<{
  site: SiteUpdateType;
}>;


export type SiteLocationSiteUpdateMutation = { __typename?: 'Mutation', updateSite: { __typename?: 'Site', id: string } };

export type SiteLocationCreateMutationVariables = Exact<{
  location: LocationCreateType;
}>;


export type SiteLocationCreateMutation = { __typename?: 'Mutation', createLocation?: { __typename?: 'Location', id: string } | null };

export type SiteLocationUpdateMutationVariables = Exact<{
  location: LocationUpdateType;
}>;


export type SiteLocationUpdateMutation = { __typename?: 'Mutation', updateLocation: { __typename?: 'Location', id: string } };

export type DeviceDataQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
  deviceModelCapabilityIds: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type DeviceDataQuery = { __typename?: 'Query', latestSensorData?: Array<{ __typename?: 'DataPoint', deviceId?: string | null, deviceModelCapabilityId?: string | null, utcTimeMeasured?: any | null, valueString?: string | null } | null> | null };

export type StatusGatewayDetailsQueryVariables = Exact<{
  gatewayId: Scalars['UUID'];
}>;


export type StatusGatewayDetailsQuery = { __typename?: 'Query', device?: { __typename?: 'Device', id: string, name: string, inverseParentDevice: Array<{ __typename?: 'Device', id: string, name: string, description?: string | null, deviceModel: { __typename?: 'DeviceModel', id: string, name: string, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, capability: { __typename?: 'Capability', id: string, name: string }, unit: { __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null } }> } }> } | null };

export type StoreyCreateParentBuildingQueryVariables = Exact<{
  buildingId: Scalars['UUID'];
}>;


export type StoreyCreateParentBuildingQuery = { __typename?: 'Query', building?: { __typename?: 'Building', id: string, name: string, site: { __typename?: 'Site', id: string, name: string } } | null };

export type StoreyCreateMutationVariables = Exact<{
  storey: StoreyCreateType;
}>;


export type StoreyCreateMutation = { __typename?: 'Mutation', createStorey?: { __typename?: 'Storey', id: string, name: string, buildingId: string } | null };

export type StoreyDeleteMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type StoreyDeleteMutation = { __typename?: 'Mutation', deleteStorey?: { __typename?: 'Storey', id: string } | null };

export type StoreyDetailsQueryVariables = Exact<{
  storeyId: Scalars['UUID'];
}>;


export type StoreyDetailsQuery = { __typename?: 'Query', storey?: { __typename?: 'Storey', id: string, name: string, description?: string | null, image?: { __typename?: 'Image', name: string, url?: string | null } | null, building: { __typename?: 'Building', id: string, name: string, site: { __typename?: 'Site', id: string, name: string } } } | null };

export type StoreyDetailsZonesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ZoneFilterInput>;
}>;


export type StoreyDetailsZonesQuery = { __typename?: 'Query', zones?: { __typename?: 'ZonesCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'Zone', id: string, storeyId: string, name: string, parentZoneId?: string | null, dimension?: string | null }> | null } | null };

export type StoreyEditQueryVariables = Exact<{
  storeyId: Scalars['UUID'];
}>;


export type StoreyEditQuery = { __typename?: 'Query', storey?: { __typename?: 'Storey', id: string, name: string, description?: string | null, image?: { __typename?: 'Image', name: string, url?: string | null } | null, building: { __typename?: 'Building', id: string, name: string, site: { __typename?: 'Site', id: string, name: string } } } | null };

export type StoreyUpdateMutationVariables = Exact<{
  storeyInput: StoreyUpdateType;
}>;


export type StoreyUpdateMutation = { __typename?: 'Mutation', updateStorey: { __typename?: 'Storey', id: string, name: string, buildingId: string } };

export type UnitCreateMutationVariables = Exact<{
  unitInput: UnitCreateType;
}>;


export type UnitCreateMutation = { __typename?: 'Mutation', createUnit?: { __typename?: 'Unit', id: string } | null };

export type UnitDeleteMutationVariables = Exact<{
  unitId: Scalars['UUID'];
}>;


export type UnitDeleteMutation = { __typename?: 'Mutation', deleteUnit?: { __typename?: 'Unit', id: string } | null };

export type UnitDetailsQueryVariables = Exact<{
  unitId: Scalars['UUID'];
}>;


export type UnitDetailsQuery = { __typename?: 'Query', unit?: { __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null, isGlobal?: boolean | null, uris?: Array<string> | null } | null };

export type UnitEditDetailsQueryVariables = Exact<{
  unitId: Scalars['UUID'];
}>;


export type UnitEditDetailsQuery = { __typename?: 'Query', unit?: { __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null, isGlobal?: boolean | null, uris?: Array<string> | null } | null };

export type UnitUpdateMutationVariables = Exact<{
  unitInput: UnitUpdateType;
}>;


export type UnitUpdateMutation = { __typename?: 'Mutation', updateUnit: { __typename?: 'Unit', id: string } };

export type UnitListQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  where?: InputMaybe<UnitFilterInput>;
}>;


export type UnitListQuery = { __typename?: 'Query', units?: { __typename?: 'UnitsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null, isGlobal?: boolean | null, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string }> }> | null } | null };

export type ZoneCreateParentStoreyQueryVariables = Exact<{
  storeyId: Scalars['UUID'];
}>;


export type ZoneCreateParentStoreyQuery = { __typename?: 'Query', storey?: { __typename?: 'Storey', id: string, name: string, building: { __typename?: 'Building', id: string, name: string, site: { __typename?: 'Site', id: string, name: string } } } | null };

export type ZoneCreateMutationVariables = Exact<{
  zone: ZoneCreateType;
}>;


export type ZoneCreateMutation = { __typename?: 'Mutation', createZone?: { __typename?: 'Zone', id: string, name: string, storeyId: string } | null };

export type ZoneDeleteMutationVariables = Exact<{
  zoneId: Scalars['UUID'];
}>;


export type ZoneDeleteMutation = { __typename?: 'Mutation', deleteZone?: { __typename?: 'Zone', id: string } | null };

export type ZoneDetailsQueryVariables = Exact<{
  zoneId: Scalars['UUID'];
}>;


export type ZoneDetailsQuery = { __typename?: 'Query', zone?: { __typename?: 'Zone', id: string, name: string, description?: string | null, image?: { __typename?: 'Image', url?: string | null, name: string } | null, storey: { __typename?: 'Storey', id: string, name: string, building: { __typename?: 'Building', id: string, name: string, site: { __typename?: 'Site', id: string, name: string } } } } | null };

export type ZoneDetailsDevicesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DevicesByBuildingFilterInput>;
}>;


export type ZoneDetailsDevicesQuery = { __typename?: 'Query', devicesByBuildings?: { __typename?: 'DevicesByBuildingsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'DevicesByBuilding', device: { __typename?: 'Device', id: string, name: string, deviceModel: { __typename?: 'DeviceModel', name: string } } }> | null } | null };

export type ZoneEditQueryVariables = Exact<{
  zoneId: Scalars['UUID'];
}>;


export type ZoneEditQuery = { __typename?: 'Query', zone?: { __typename?: 'Zone', id: string, name: string, description?: string | null, image?: { __typename?: 'Image', url?: string | null, name: string } | null, storey: { __typename?: 'Storey', id: string, name: string, building: { __typename?: 'Building', id: string, name: string, site: { __typename?: 'Site', id: string, name: string } } } } | null };

export type ZoneUpdateMutationVariables = Exact<{
  zoneInput: ZoneUpdateType;
}>;


export type ZoneUpdateMutation = { __typename?: 'Mutation', updateZone: { __typename?: 'Zone', id: string, name: string, storeyId: string } };


export const FieldDeviceListGatewayDetailsDocument = gql`
    query FieldDeviceListGatewayDetails($gatewayId: UUID!) {
  device(id: $gatewayId) {
    id
    name
    description
    deviceIdentifier
    inverseParentDevice {
      id
      name
      description
      serialNo
      deviceIdentifier
      parentDeviceId
      deviceModelId
      deviceModel {
        id
        name
        deviceModelCapabilities {
          id
          fieldSelector
          technicalMax
          technicalMin
          capability {
            id
            name
            description
            dataType
          }
          unit {
            id
            name
            unitSymbol
          }
        }
        deviceTypeId
        deviceType {
          id
          name
          isGateway
        }
      }
    }
    deviceModel {
      id
      name
      deviceType {
        id
        name
        isGateway
      }
      deviceModelCapabilities {
        id
        fieldSelector
        technicalMax
        technicalMin
        capability {
          id
          name
          description
          dataType
        }
        unit {
          id
          name
          unitSymbol
        }
      }
    }
  }
  placementOfDevice(deviceId: $gatewayId) {
    zoneName
    buildingName
    locationCity
    locationCountry
    siteName
    storeyName
  }
}
    `;

/**
 * __useFieldDeviceListGatewayDetailsQuery__
 *
 * To run a query within a React component, call `useFieldDeviceListGatewayDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldDeviceListGatewayDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldDeviceListGatewayDetailsQuery({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *   },
 * });
 */
export function useFieldDeviceListGatewayDetailsQuery(baseOptions: Apollo.QueryHookOptions<FieldDeviceListGatewayDetailsQuery, FieldDeviceListGatewayDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FieldDeviceListGatewayDetailsQuery, FieldDeviceListGatewayDetailsQueryVariables>(FieldDeviceListGatewayDetailsDocument, options);
      }
export function useFieldDeviceListGatewayDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FieldDeviceListGatewayDetailsQuery, FieldDeviceListGatewayDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FieldDeviceListGatewayDetailsQuery, FieldDeviceListGatewayDetailsQueryVariables>(FieldDeviceListGatewayDetailsDocument, options);
        }
export type FieldDeviceListGatewayDetailsQueryHookResult = ReturnType<typeof useFieldDeviceListGatewayDetailsQuery>;
export type FieldDeviceListGatewayDetailsLazyQueryHookResult = ReturnType<typeof useFieldDeviceListGatewayDetailsLazyQuery>;
export type FieldDeviceListGatewayDetailsQueryResult = Apollo.QueryResult<FieldDeviceListGatewayDetailsQuery, FieldDeviceListGatewayDetailsQueryVariables>;
export const GatewayListDocument = gql`
    query GatewayList {
  devices(
    where: {and: [{deviceModel: {deviceType: {isGateway: {eq: true}}}}]}
    order: {name: ASC}
  ) {
    items {
      id
      name
      description
      connectionState
      inverseParentDevice {
        id
        name
        deviceModel {
          deviceModelCapabilities {
            id
          }
        }
      }
      deviceModel {
        id
        name
        deviceType {
          id
          name
          isGateway
        }
        deviceModelCapabilities {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useGatewayListQuery__
 *
 * To run a query within a React component, call `useGatewayListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGatewayListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGatewayListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGatewayListQuery(baseOptions?: Apollo.QueryHookOptions<GatewayListQuery, GatewayListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GatewayListQuery, GatewayListQueryVariables>(GatewayListDocument, options);
      }
export function useGatewayListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GatewayListQuery, GatewayListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GatewayListQuery, GatewayListQueryVariables>(GatewayListDocument, options);
        }
export type GatewayListQueryHookResult = ReturnType<typeof useGatewayListQuery>;
export type GatewayListLazyQueryHookResult = ReturnType<typeof useGatewayListLazyQuery>;
export type GatewayListQueryResult = Apollo.QueryResult<GatewayListQuery, GatewayListQueryVariables>;
export const AttributeCreateDocument = gql`
    mutation AttributeCreate($attributeInput: AttributeCreateType!) {
  createAttribute(attribute: $attributeInput) {
    id
  }
}
    `;
export type AttributeCreateMutationFn = Apollo.MutationFunction<AttributeCreateMutation, AttributeCreateMutationVariables>;

/**
 * __useAttributeCreateMutation__
 *
 * To run a mutation, you first call `useAttributeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttributeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attributeCreateMutation, { data, loading, error }] = useAttributeCreateMutation({
 *   variables: {
 *      attributeInput: // value for 'attributeInput'
 *   },
 * });
 */
export function useAttributeCreateMutation(baseOptions?: Apollo.MutationHookOptions<AttributeCreateMutation, AttributeCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttributeCreateMutation, AttributeCreateMutationVariables>(AttributeCreateDocument, options);
      }
export type AttributeCreateMutationHookResult = ReturnType<typeof useAttributeCreateMutation>;
export type AttributeCreateMutationResult = Apollo.MutationResult<AttributeCreateMutation>;
export type AttributeCreateMutationOptions = Apollo.BaseMutationOptions<AttributeCreateMutation, AttributeCreateMutationVariables>;
export const AttributeDeleteDocument = gql`
    mutation AttributeDelete($attributeId: UUID!) {
  deleteAttribute(id: $attributeId) {
    id
  }
}
    `;
export type AttributeDeleteMutationFn = Apollo.MutationFunction<AttributeDeleteMutation, AttributeDeleteMutationVariables>;

/**
 * __useAttributeDeleteMutation__
 *
 * To run a mutation, you first call `useAttributeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttributeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attributeDeleteMutation, { data, loading, error }] = useAttributeDeleteMutation({
 *   variables: {
 *      attributeId: // value for 'attributeId'
 *   },
 * });
 */
export function useAttributeDeleteMutation(baseOptions?: Apollo.MutationHookOptions<AttributeDeleteMutation, AttributeDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttributeDeleteMutation, AttributeDeleteMutationVariables>(AttributeDeleteDocument, options);
      }
export type AttributeDeleteMutationHookResult = ReturnType<typeof useAttributeDeleteMutation>;
export type AttributeDeleteMutationResult = Apollo.MutationResult<AttributeDeleteMutation>;
export type AttributeDeleteMutationOptions = Apollo.BaseMutationOptions<AttributeDeleteMutation, AttributeDeleteMutationVariables>;
export const AttributeDetailsDocument = gql`
    query AttributeDetails($attributeId: UUID!) {
  attribute(id: $attributeId) {
    id
    name
    description
    dataType
    required
    isGlobal
  }
}
    `;

/**
 * __useAttributeDetailsQuery__
 *
 * To run a query within a React component, call `useAttributeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttributeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttributeDetailsQuery({
 *   variables: {
 *      attributeId: // value for 'attributeId'
 *   },
 * });
 */
export function useAttributeDetailsQuery(baseOptions: Apollo.QueryHookOptions<AttributeDetailsQuery, AttributeDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AttributeDetailsQuery, AttributeDetailsQueryVariables>(AttributeDetailsDocument, options);
      }
export function useAttributeDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AttributeDetailsQuery, AttributeDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AttributeDetailsQuery, AttributeDetailsQueryVariables>(AttributeDetailsDocument, options);
        }
export type AttributeDetailsQueryHookResult = ReturnType<typeof useAttributeDetailsQuery>;
export type AttributeDetailsLazyQueryHookResult = ReturnType<typeof useAttributeDetailsLazyQuery>;
export type AttributeDetailsQueryResult = Apollo.QueryResult<AttributeDetailsQuery, AttributeDetailsQueryVariables>;
export const AttributeEditDetailsDocument = gql`
    query AttributeEditDetails($attributeId: UUID!) {
  attribute(id: $attributeId) {
    id
    name
    description
    dataType
    required
    isGlobal
  }
}
    `;

/**
 * __useAttributeEditDetailsQuery__
 *
 * To run a query within a React component, call `useAttributeEditDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttributeEditDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttributeEditDetailsQuery({
 *   variables: {
 *      attributeId: // value for 'attributeId'
 *   },
 * });
 */
export function useAttributeEditDetailsQuery(baseOptions: Apollo.QueryHookOptions<AttributeEditDetailsQuery, AttributeEditDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AttributeEditDetailsQuery, AttributeEditDetailsQueryVariables>(AttributeEditDetailsDocument, options);
      }
export function useAttributeEditDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AttributeEditDetailsQuery, AttributeEditDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AttributeEditDetailsQuery, AttributeEditDetailsQueryVariables>(AttributeEditDetailsDocument, options);
        }
export type AttributeEditDetailsQueryHookResult = ReturnType<typeof useAttributeEditDetailsQuery>;
export type AttributeEditDetailsLazyQueryHookResult = ReturnType<typeof useAttributeEditDetailsLazyQuery>;
export type AttributeEditDetailsQueryResult = Apollo.QueryResult<AttributeEditDetailsQuery, AttributeEditDetailsQueryVariables>;
export const AttributeUpdateDocument = gql`
    mutation AttributeUpdate($attributeInput: AttributeUpdateType!) {
  updateAttribute(attribute: $attributeInput) {
    id
  }
}
    `;
export type AttributeUpdateMutationFn = Apollo.MutationFunction<AttributeUpdateMutation, AttributeUpdateMutationVariables>;

/**
 * __useAttributeUpdateMutation__
 *
 * To run a mutation, you first call `useAttributeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttributeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attributeUpdateMutation, { data, loading, error }] = useAttributeUpdateMutation({
 *   variables: {
 *      attributeInput: // value for 'attributeInput'
 *   },
 * });
 */
export function useAttributeUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AttributeUpdateMutation, AttributeUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttributeUpdateMutation, AttributeUpdateMutationVariables>(AttributeUpdateDocument, options);
      }
export type AttributeUpdateMutationHookResult = ReturnType<typeof useAttributeUpdateMutation>;
export type AttributeUpdateMutationResult = Apollo.MutationResult<AttributeUpdateMutation>;
export type AttributeUpdateMutationOptions = Apollo.BaseMutationOptions<AttributeUpdateMutation, AttributeUpdateMutationVariables>;
export const AttributeListDocument = gql`
    query AttributeList($skip: Int!, $take: Int!, $where: AttributeFilterInput) {
  attributes(skip: $skip, take: $take, where: $where, order: {name: ASC}) {
    items {
      id
      name
      isGlobal
      mappingAttributeDeviceModelAttributeSets {
        id
      }
    }
    totalCount
  }
}
    `;

/**
 * __useAttributeListQuery__
 *
 * To run a query within a React component, call `useAttributeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttributeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttributeListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAttributeListQuery(baseOptions: Apollo.QueryHookOptions<AttributeListQuery, AttributeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AttributeListQuery, AttributeListQueryVariables>(AttributeListDocument, options);
      }
export function useAttributeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AttributeListQuery, AttributeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AttributeListQuery, AttributeListQueryVariables>(AttributeListDocument, options);
        }
export type AttributeListQueryHookResult = ReturnType<typeof useAttributeListQuery>;
export type AttributeListLazyQueryHookResult = ReturnType<typeof useAttributeListLazyQuery>;
export type AttributeListQueryResult = Apollo.QueryResult<AttributeListQuery, AttributeListQueryVariables>;
export const BuildingCreateParentSiteDocument = gql`
    query BuildingCreateParentSite($siteId: UUID!) {
  site(id: $siteId) {
    id
    name
    location {
      city
      country
      no
      street
      zip
    }
  }
}
    `;

/**
 * __useBuildingCreateParentSiteQuery__
 *
 * To run a query within a React component, call `useBuildingCreateParentSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingCreateParentSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingCreateParentSiteQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useBuildingCreateParentSiteQuery(baseOptions: Apollo.QueryHookOptions<BuildingCreateParentSiteQuery, BuildingCreateParentSiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingCreateParentSiteQuery, BuildingCreateParentSiteQueryVariables>(BuildingCreateParentSiteDocument, options);
      }
export function useBuildingCreateParentSiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingCreateParentSiteQuery, BuildingCreateParentSiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingCreateParentSiteQuery, BuildingCreateParentSiteQueryVariables>(BuildingCreateParentSiteDocument, options);
        }
export type BuildingCreateParentSiteQueryHookResult = ReturnType<typeof useBuildingCreateParentSiteQuery>;
export type BuildingCreateParentSiteLazyQueryHookResult = ReturnType<typeof useBuildingCreateParentSiteLazyQuery>;
export type BuildingCreateParentSiteQueryResult = Apollo.QueryResult<BuildingCreateParentSiteQuery, BuildingCreateParentSiteQueryVariables>;
export const BuildingCreateDocument = gql`
    mutation BuildingCreate($building: BuildingCreateType!) {
  createBuilding(building: $building) {
    id
  }
}
    `;
export type BuildingCreateMutationFn = Apollo.MutationFunction<BuildingCreateMutation, BuildingCreateMutationVariables>;

/**
 * __useBuildingCreateMutation__
 *
 * To run a mutation, you first call `useBuildingCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildingCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildingCreateMutation, { data, loading, error }] = useBuildingCreateMutation({
 *   variables: {
 *      building: // value for 'building'
 *   },
 * });
 */
export function useBuildingCreateMutation(baseOptions?: Apollo.MutationHookOptions<BuildingCreateMutation, BuildingCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuildingCreateMutation, BuildingCreateMutationVariables>(BuildingCreateDocument, options);
      }
export type BuildingCreateMutationHookResult = ReturnType<typeof useBuildingCreateMutation>;
export type BuildingCreateMutationResult = Apollo.MutationResult<BuildingCreateMutation>;
export type BuildingCreateMutationOptions = Apollo.BaseMutationOptions<BuildingCreateMutation, BuildingCreateMutationVariables>;
export const BuildingCreateLocationCreateDocument = gql`
    mutation BuildingCreateLocationCreate($locationInput: LocationCreateType!) {
  createLocation(location: $locationInput) {
    id
  }
}
    `;
export type BuildingCreateLocationCreateMutationFn = Apollo.MutationFunction<BuildingCreateLocationCreateMutation, BuildingCreateLocationCreateMutationVariables>;

/**
 * __useBuildingCreateLocationCreateMutation__
 *
 * To run a mutation, you first call `useBuildingCreateLocationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildingCreateLocationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildingCreateLocationCreateMutation, { data, loading, error }] = useBuildingCreateLocationCreateMutation({
 *   variables: {
 *      locationInput: // value for 'locationInput'
 *   },
 * });
 */
export function useBuildingCreateLocationCreateMutation(baseOptions?: Apollo.MutationHookOptions<BuildingCreateLocationCreateMutation, BuildingCreateLocationCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuildingCreateLocationCreateMutation, BuildingCreateLocationCreateMutationVariables>(BuildingCreateLocationCreateDocument, options);
      }
export type BuildingCreateLocationCreateMutationHookResult = ReturnType<typeof useBuildingCreateLocationCreateMutation>;
export type BuildingCreateLocationCreateMutationResult = Apollo.MutationResult<BuildingCreateLocationCreateMutation>;
export type BuildingCreateLocationCreateMutationOptions = Apollo.BaseMutationOptions<BuildingCreateLocationCreateMutation, BuildingCreateLocationCreateMutationVariables>;
export const BuildingDeleteDocument = gql`
    mutation BuildingDelete($buildingId: UUID!) {
  deleteBuilding(id: $buildingId) {
    id
  }
}
    `;
export type BuildingDeleteMutationFn = Apollo.MutationFunction<BuildingDeleteMutation, BuildingDeleteMutationVariables>;

/**
 * __useBuildingDeleteMutation__
 *
 * To run a mutation, you first call `useBuildingDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildingDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildingDeleteMutation, { data, loading, error }] = useBuildingDeleteMutation({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useBuildingDeleteMutation(baseOptions?: Apollo.MutationHookOptions<BuildingDeleteMutation, BuildingDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuildingDeleteMutation, BuildingDeleteMutationVariables>(BuildingDeleteDocument, options);
      }
export type BuildingDeleteMutationHookResult = ReturnType<typeof useBuildingDeleteMutation>;
export type BuildingDeleteMutationResult = Apollo.MutationResult<BuildingDeleteMutation>;
export type BuildingDeleteMutationOptions = Apollo.BaseMutationOptions<BuildingDeleteMutation, BuildingDeleteMutationVariables>;
export const BuildingDetailsDocument = gql`
    query BuildingDetails($buildingId: UUID!, $where: StoreyFilterInput) {
  building(id: $buildingId) {
    id
    name
    dimension
    description
    image {
      name
      url
    }
    location {
      id
      street
      no
      zip
      city
      county
      country
    }
    site {
      id
      name
    }
  }
  storeys(skip: 0, take: 1, where: $where) {
    items {
      id
      buildingId
    }
    totalCount
  }
}
    `;

/**
 * __useBuildingDetailsQuery__
 *
 * To run a query within a React component, call `useBuildingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingDetailsQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBuildingDetailsQuery(baseOptions: Apollo.QueryHookOptions<BuildingDetailsQuery, BuildingDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingDetailsQuery, BuildingDetailsQueryVariables>(BuildingDetailsDocument, options);
      }
export function useBuildingDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingDetailsQuery, BuildingDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingDetailsQuery, BuildingDetailsQueryVariables>(BuildingDetailsDocument, options);
        }
export type BuildingDetailsQueryHookResult = ReturnType<typeof useBuildingDetailsQuery>;
export type BuildingDetailsLazyQueryHookResult = ReturnType<typeof useBuildingDetailsLazyQuery>;
export type BuildingDetailsQueryResult = Apollo.QueryResult<BuildingDetailsQuery, BuildingDetailsQueryVariables>;
export const BuildingDetailsDevicesDocument = gql`
    query BuildingDetailsDevices($skip: Int, $take: Int, $where: DevicesByBuildingFilterInput) {
  devicesByBuildings(skip: $skip, take: $take, where: $where) {
    items {
      device {
        id
        name
        deviceModel {
          name
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __useBuildingDetailsDevicesQuery__
 *
 * To run a query within a React component, call `useBuildingDetailsDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingDetailsDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingDetailsDevicesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBuildingDetailsDevicesQuery(baseOptions?: Apollo.QueryHookOptions<BuildingDetailsDevicesQuery, BuildingDetailsDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingDetailsDevicesQuery, BuildingDetailsDevicesQueryVariables>(BuildingDetailsDevicesDocument, options);
      }
export function useBuildingDetailsDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingDetailsDevicesQuery, BuildingDetailsDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingDetailsDevicesQuery, BuildingDetailsDevicesQueryVariables>(BuildingDetailsDevicesDocument, options);
        }
export type BuildingDetailsDevicesQueryHookResult = ReturnType<typeof useBuildingDetailsDevicesQuery>;
export type BuildingDetailsDevicesLazyQueryHookResult = ReturnType<typeof useBuildingDetailsDevicesLazyQuery>;
export type BuildingDetailsDevicesQueryResult = Apollo.QueryResult<BuildingDetailsDevicesQuery, BuildingDetailsDevicesQueryVariables>;
export const BuildingDetailsStoreysDocument = gql`
    query BuildingDetailsStoreys($skip: Int, $take: Int, $where: StoreyFilterInput) {
  storeys(skip: $skip, take: $take, where: $where) {
    items {
      id
      name
      buildingId
    }
    totalCount
  }
}
    `;

/**
 * __useBuildingDetailsStoreysQuery__
 *
 * To run a query within a React component, call `useBuildingDetailsStoreysQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingDetailsStoreysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingDetailsStoreysQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBuildingDetailsStoreysQuery(baseOptions?: Apollo.QueryHookOptions<BuildingDetailsStoreysQuery, BuildingDetailsStoreysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingDetailsStoreysQuery, BuildingDetailsStoreysQueryVariables>(BuildingDetailsStoreysDocument, options);
      }
export function useBuildingDetailsStoreysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingDetailsStoreysQuery, BuildingDetailsStoreysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingDetailsStoreysQuery, BuildingDetailsStoreysQueryVariables>(BuildingDetailsStoreysDocument, options);
        }
export type BuildingDetailsStoreysQueryHookResult = ReturnType<typeof useBuildingDetailsStoreysQuery>;
export type BuildingDetailsStoreysLazyQueryHookResult = ReturnType<typeof useBuildingDetailsStoreysLazyQuery>;
export type BuildingDetailsStoreysQueryResult = Apollo.QueryResult<BuildingDetailsStoreysQuery, BuildingDetailsStoreysQueryVariables>;
export const BuildingEditDetailsDocument = gql`
    query BuildingEditDetails($buildingId: UUID!) {
  building(id: $buildingId) {
    id
    name
    description
    dimension
    site {
      id
      name
    }
  }
}
    `;

/**
 * __useBuildingEditDetailsQuery__
 *
 * To run a query within a React component, call `useBuildingEditDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingEditDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingEditDetailsQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useBuildingEditDetailsQuery(baseOptions: Apollo.QueryHookOptions<BuildingEditDetailsQuery, BuildingEditDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingEditDetailsQuery, BuildingEditDetailsQueryVariables>(BuildingEditDetailsDocument, options);
      }
export function useBuildingEditDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingEditDetailsQuery, BuildingEditDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingEditDetailsQuery, BuildingEditDetailsQueryVariables>(BuildingEditDetailsDocument, options);
        }
export type BuildingEditDetailsQueryHookResult = ReturnType<typeof useBuildingEditDetailsQuery>;
export type BuildingEditDetailsLazyQueryHookResult = ReturnType<typeof useBuildingEditDetailsLazyQuery>;
export type BuildingEditDetailsQueryResult = Apollo.QueryResult<BuildingEditDetailsQuery, BuildingEditDetailsQueryVariables>;
export const BuildingUpdateDocument = gql`
    mutation BuildingUpdate($building: BuildingUpdateType!) {
  updateBuilding(building: $building) {
    id
  }
}
    `;
export type BuildingUpdateMutationFn = Apollo.MutationFunction<BuildingUpdateMutation, BuildingUpdateMutationVariables>;

/**
 * __useBuildingUpdateMutation__
 *
 * To run a mutation, you first call `useBuildingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildingUpdateMutation, { data, loading, error }] = useBuildingUpdateMutation({
 *   variables: {
 *      building: // value for 'building'
 *   },
 * });
 */
export function useBuildingUpdateMutation(baseOptions?: Apollo.MutationHookOptions<BuildingUpdateMutation, BuildingUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuildingUpdateMutation, BuildingUpdateMutationVariables>(BuildingUpdateDocument, options);
      }
export type BuildingUpdateMutationHookResult = ReturnType<typeof useBuildingUpdateMutation>;
export type BuildingUpdateMutationResult = Apollo.MutationResult<BuildingUpdateMutation>;
export type BuildingUpdateMutationOptions = Apollo.BaseMutationOptions<BuildingUpdateMutation, BuildingUpdateMutationVariables>;
export const BuildingLocationDetailsDocument = gql`
    query BuildingLocationDetails($buildingId: UUID!) {
  building(id: $buildingId) {
    id
    name
    locationId
    location {
      id
      street
      no
      zip
      city
      county
      country
    }
    site {
      id
      name
    }
  }
}
    `;

/**
 * __useBuildingLocationDetailsQuery__
 *
 * To run a query within a React component, call `useBuildingLocationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingLocationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingLocationDetailsQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useBuildingLocationDetailsQuery(baseOptions: Apollo.QueryHookOptions<BuildingLocationDetailsQuery, BuildingLocationDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingLocationDetailsQuery, BuildingLocationDetailsQueryVariables>(BuildingLocationDetailsDocument, options);
      }
export function useBuildingLocationDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingLocationDetailsQuery, BuildingLocationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingLocationDetailsQuery, BuildingLocationDetailsQueryVariables>(BuildingLocationDetailsDocument, options);
        }
export type BuildingLocationDetailsQueryHookResult = ReturnType<typeof useBuildingLocationDetailsQuery>;
export type BuildingLocationDetailsLazyQueryHookResult = ReturnType<typeof useBuildingLocationDetailsLazyQuery>;
export type BuildingLocationDetailsQueryResult = Apollo.QueryResult<BuildingLocationDetailsQuery, BuildingLocationDetailsQueryVariables>;
export const BuildingLocationBuildingUpdateDocument = gql`
    mutation BuildingLocationBuildingUpdate($building: BuildingUpdateType!) {
  updateBuilding(building: $building) {
    id
  }
}
    `;
export type BuildingLocationBuildingUpdateMutationFn = Apollo.MutationFunction<BuildingLocationBuildingUpdateMutation, BuildingLocationBuildingUpdateMutationVariables>;

/**
 * __useBuildingLocationBuildingUpdateMutation__
 *
 * To run a mutation, you first call `useBuildingLocationBuildingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildingLocationBuildingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildingLocationBuildingUpdateMutation, { data, loading, error }] = useBuildingLocationBuildingUpdateMutation({
 *   variables: {
 *      building: // value for 'building'
 *   },
 * });
 */
export function useBuildingLocationBuildingUpdateMutation(baseOptions?: Apollo.MutationHookOptions<BuildingLocationBuildingUpdateMutation, BuildingLocationBuildingUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuildingLocationBuildingUpdateMutation, BuildingLocationBuildingUpdateMutationVariables>(BuildingLocationBuildingUpdateDocument, options);
      }
export type BuildingLocationBuildingUpdateMutationHookResult = ReturnType<typeof useBuildingLocationBuildingUpdateMutation>;
export type BuildingLocationBuildingUpdateMutationResult = Apollo.MutationResult<BuildingLocationBuildingUpdateMutation>;
export type BuildingLocationBuildingUpdateMutationOptions = Apollo.BaseMutationOptions<BuildingLocationBuildingUpdateMutation, BuildingLocationBuildingUpdateMutationVariables>;
export const BuildingLocationCreateDocument = gql`
    mutation BuildingLocationCreate($location: LocationCreateType!) {
  createLocation(location: $location) {
    id
  }
}
    `;
export type BuildingLocationCreateMutationFn = Apollo.MutationFunction<BuildingLocationCreateMutation, BuildingLocationCreateMutationVariables>;

/**
 * __useBuildingLocationCreateMutation__
 *
 * To run a mutation, you first call `useBuildingLocationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildingLocationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildingLocationCreateMutation, { data, loading, error }] = useBuildingLocationCreateMutation({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useBuildingLocationCreateMutation(baseOptions?: Apollo.MutationHookOptions<BuildingLocationCreateMutation, BuildingLocationCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuildingLocationCreateMutation, BuildingLocationCreateMutationVariables>(BuildingLocationCreateDocument, options);
      }
export type BuildingLocationCreateMutationHookResult = ReturnType<typeof useBuildingLocationCreateMutation>;
export type BuildingLocationCreateMutationResult = Apollo.MutationResult<BuildingLocationCreateMutation>;
export type BuildingLocationCreateMutationOptions = Apollo.BaseMutationOptions<BuildingLocationCreateMutation, BuildingLocationCreateMutationVariables>;
export const BuildingLocationUpdateDocument = gql`
    mutation BuildingLocationUpdate($location: LocationUpdateType!) {
  updateLocation(location: $location) {
    id
  }
}
    `;
export type BuildingLocationUpdateMutationFn = Apollo.MutationFunction<BuildingLocationUpdateMutation, BuildingLocationUpdateMutationVariables>;

/**
 * __useBuildingLocationUpdateMutation__
 *
 * To run a mutation, you first call `useBuildingLocationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildingLocationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildingLocationUpdateMutation, { data, loading, error }] = useBuildingLocationUpdateMutation({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useBuildingLocationUpdateMutation(baseOptions?: Apollo.MutationHookOptions<BuildingLocationUpdateMutation, BuildingLocationUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuildingLocationUpdateMutation, BuildingLocationUpdateMutationVariables>(BuildingLocationUpdateDocument, options);
      }
export type BuildingLocationUpdateMutationHookResult = ReturnType<typeof useBuildingLocationUpdateMutation>;
export type BuildingLocationUpdateMutationResult = Apollo.MutationResult<BuildingLocationUpdateMutation>;
export type BuildingLocationUpdateMutationOptions = Apollo.BaseMutationOptions<BuildingLocationUpdateMutation, BuildingLocationUpdateMutationVariables>;
export const CapabilityCreateDocument = gql`
    mutation CapabilityCreate($capabilityInput: CapabilityCreateType!) {
  createCapability(capability: $capabilityInput) {
    id
  }
}
    `;
export type CapabilityCreateMutationFn = Apollo.MutationFunction<CapabilityCreateMutation, CapabilityCreateMutationVariables>;

/**
 * __useCapabilityCreateMutation__
 *
 * To run a mutation, you first call `useCapabilityCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCapabilityCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [capabilityCreateMutation, { data, loading, error }] = useCapabilityCreateMutation({
 *   variables: {
 *      capabilityInput: // value for 'capabilityInput'
 *   },
 * });
 */
export function useCapabilityCreateMutation(baseOptions?: Apollo.MutationHookOptions<CapabilityCreateMutation, CapabilityCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CapabilityCreateMutation, CapabilityCreateMutationVariables>(CapabilityCreateDocument, options);
      }
export type CapabilityCreateMutationHookResult = ReturnType<typeof useCapabilityCreateMutation>;
export type CapabilityCreateMutationResult = Apollo.MutationResult<CapabilityCreateMutation>;
export type CapabilityCreateMutationOptions = Apollo.BaseMutationOptions<CapabilityCreateMutation, CapabilityCreateMutationVariables>;
export const CapabilityDeleteDocument = gql`
    mutation CapabilityDelete($capabilityId: UUID!) {
  deleteCapability(id: $capabilityId) {
    id
  }
}
    `;
export type CapabilityDeleteMutationFn = Apollo.MutationFunction<CapabilityDeleteMutation, CapabilityDeleteMutationVariables>;

/**
 * __useCapabilityDeleteMutation__
 *
 * To run a mutation, you first call `useCapabilityDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCapabilityDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [capabilityDeleteMutation, { data, loading, error }] = useCapabilityDeleteMutation({
 *   variables: {
 *      capabilityId: // value for 'capabilityId'
 *   },
 * });
 */
export function useCapabilityDeleteMutation(baseOptions?: Apollo.MutationHookOptions<CapabilityDeleteMutation, CapabilityDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CapabilityDeleteMutation, CapabilityDeleteMutationVariables>(CapabilityDeleteDocument, options);
      }
export type CapabilityDeleteMutationHookResult = ReturnType<typeof useCapabilityDeleteMutation>;
export type CapabilityDeleteMutationResult = Apollo.MutationResult<CapabilityDeleteMutation>;
export type CapabilityDeleteMutationOptions = Apollo.BaseMutationOptions<CapabilityDeleteMutation, CapabilityDeleteMutationVariables>;
export const CapabilityDetailsDocument = gql`
    query CapabilityDetails($capabilityId: UUID!) {
  capability(id: $capabilityId) {
    id
    name
    description
    dataType
    isGlobal
    isLocked
  }
}
    `;

/**
 * __useCapabilityDetailsQuery__
 *
 * To run a query within a React component, call `useCapabilityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCapabilityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCapabilityDetailsQuery({
 *   variables: {
 *      capabilityId: // value for 'capabilityId'
 *   },
 * });
 */
export function useCapabilityDetailsQuery(baseOptions: Apollo.QueryHookOptions<CapabilityDetailsQuery, CapabilityDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CapabilityDetailsQuery, CapabilityDetailsQueryVariables>(CapabilityDetailsDocument, options);
      }
export function useCapabilityDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CapabilityDetailsQuery, CapabilityDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CapabilityDetailsQuery, CapabilityDetailsQueryVariables>(CapabilityDetailsDocument, options);
        }
export type CapabilityDetailsQueryHookResult = ReturnType<typeof useCapabilityDetailsQuery>;
export type CapabilityDetailsLazyQueryHookResult = ReturnType<typeof useCapabilityDetailsLazyQuery>;
export type CapabilityDetailsQueryResult = Apollo.QueryResult<CapabilityDetailsQuery, CapabilityDetailsQueryVariables>;
export const CapabilityEditDetailsDocument = gql`
    query CapabilityEditDetails($capabilityId: UUID!) {
  capability(id: $capabilityId) {
    id
    name
    description
    dataType
    isGlobal
  }
}
    `;

/**
 * __useCapabilityEditDetailsQuery__
 *
 * To run a query within a React component, call `useCapabilityEditDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCapabilityEditDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCapabilityEditDetailsQuery({
 *   variables: {
 *      capabilityId: // value for 'capabilityId'
 *   },
 * });
 */
export function useCapabilityEditDetailsQuery(baseOptions: Apollo.QueryHookOptions<CapabilityEditDetailsQuery, CapabilityEditDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CapabilityEditDetailsQuery, CapabilityEditDetailsQueryVariables>(CapabilityEditDetailsDocument, options);
      }
export function useCapabilityEditDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CapabilityEditDetailsQuery, CapabilityEditDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CapabilityEditDetailsQuery, CapabilityEditDetailsQueryVariables>(CapabilityEditDetailsDocument, options);
        }
export type CapabilityEditDetailsQueryHookResult = ReturnType<typeof useCapabilityEditDetailsQuery>;
export type CapabilityEditDetailsLazyQueryHookResult = ReturnType<typeof useCapabilityEditDetailsLazyQuery>;
export type CapabilityEditDetailsQueryResult = Apollo.QueryResult<CapabilityEditDetailsQuery, CapabilityEditDetailsQueryVariables>;
export const CapabilityUpdateDocument = gql`
    mutation CapabilityUpdate($capabilityInput: CapabilityUpdateType!) {
  updateCapability(capability: $capabilityInput) {
    id
  }
}
    `;
export type CapabilityUpdateMutationFn = Apollo.MutationFunction<CapabilityUpdateMutation, CapabilityUpdateMutationVariables>;

/**
 * __useCapabilityUpdateMutation__
 *
 * To run a mutation, you first call `useCapabilityUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCapabilityUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [capabilityUpdateMutation, { data, loading, error }] = useCapabilityUpdateMutation({
 *   variables: {
 *      capabilityInput: // value for 'capabilityInput'
 *   },
 * });
 */
export function useCapabilityUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CapabilityUpdateMutation, CapabilityUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CapabilityUpdateMutation, CapabilityUpdateMutationVariables>(CapabilityUpdateDocument, options);
      }
export type CapabilityUpdateMutationHookResult = ReturnType<typeof useCapabilityUpdateMutation>;
export type CapabilityUpdateMutationResult = Apollo.MutationResult<CapabilityUpdateMutation>;
export type CapabilityUpdateMutationOptions = Apollo.BaseMutationOptions<CapabilityUpdateMutation, CapabilityUpdateMutationVariables>;
export const CapabilityListDocument = gql`
    query CapabilityList($skip: Int!, $take: Int!, $where: CapabilityFilterInput) {
  capabilities(skip: $skip, take: $take, where: $where, order: {name: ASC}) {
    items {
      id
      name
      description
      isGlobal
      deviceModelCapabilities {
        id
      }
    }
    totalCount
  }
}
    `;

/**
 * __useCapabilityListQuery__
 *
 * To run a query within a React component, call `useCapabilityListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCapabilityListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCapabilityListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCapabilityListQuery(baseOptions: Apollo.QueryHookOptions<CapabilityListQuery, CapabilityListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CapabilityListQuery, CapabilityListQueryVariables>(CapabilityListDocument, options);
      }
export function useCapabilityListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CapabilityListQuery, CapabilityListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CapabilityListQuery, CapabilityListQueryVariables>(CapabilityListDocument, options);
        }
export type CapabilityListQueryHookResult = ReturnType<typeof useCapabilityListQuery>;
export type CapabilityListLazyQueryHookResult = ReturnType<typeof useCapabilityListLazyQuery>;
export type CapabilityListQueryResult = Apollo.QueryResult<CapabilityListQuery, CapabilityListQueryVariables>;
export const DeviceModelAttributeSetCreateDetailsDocument = gql`
    query DeviceModelAttributeSetCreateDetails($deviceModelId: UUID!) {
  deviceModel(id: $deviceModelId) {
    id
    name
    isGlobal
  }
}
    `;

/**
 * __useDeviceModelAttributeSetCreateDetailsQuery__
 *
 * To run a query within a React component, call `useDeviceModelAttributeSetCreateDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelAttributeSetCreateDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceModelAttributeSetCreateDetailsQuery({
 *   variables: {
 *      deviceModelId: // value for 'deviceModelId'
 *   },
 * });
 */
export function useDeviceModelAttributeSetCreateDetailsQuery(baseOptions: Apollo.QueryHookOptions<DeviceModelAttributeSetCreateDetailsQuery, DeviceModelAttributeSetCreateDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceModelAttributeSetCreateDetailsQuery, DeviceModelAttributeSetCreateDetailsQueryVariables>(DeviceModelAttributeSetCreateDetailsDocument, options);
      }
export function useDeviceModelAttributeSetCreateDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceModelAttributeSetCreateDetailsQuery, DeviceModelAttributeSetCreateDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceModelAttributeSetCreateDetailsQuery, DeviceModelAttributeSetCreateDetailsQueryVariables>(DeviceModelAttributeSetCreateDetailsDocument, options);
        }
export type DeviceModelAttributeSetCreateDetailsQueryHookResult = ReturnType<typeof useDeviceModelAttributeSetCreateDetailsQuery>;
export type DeviceModelAttributeSetCreateDetailsLazyQueryHookResult = ReturnType<typeof useDeviceModelAttributeSetCreateDetailsLazyQuery>;
export type DeviceModelAttributeSetCreateDetailsQueryResult = Apollo.QueryResult<DeviceModelAttributeSetCreateDetailsQuery, DeviceModelAttributeSetCreateDetailsQueryVariables>;
export const DeviceModelAttributeSetCreateDocument = gql`
    mutation DeviceModelAttributeSetCreate($deviceModelAttributeSetInput: DeviceModelAttributeSetCreateType!) {
  createDeviceModelAttributeSet(
    deviceModelAttributeSet: $deviceModelAttributeSetInput
  ) {
    id
  }
}
    `;
export type DeviceModelAttributeSetCreateMutationFn = Apollo.MutationFunction<DeviceModelAttributeSetCreateMutation, DeviceModelAttributeSetCreateMutationVariables>;

/**
 * __useDeviceModelAttributeSetCreateMutation__
 *
 * To run a mutation, you first call `useDeviceModelAttributeSetCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelAttributeSetCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceModelAttributeSetCreateMutation, { data, loading, error }] = useDeviceModelAttributeSetCreateMutation({
 *   variables: {
 *      deviceModelAttributeSetInput: // value for 'deviceModelAttributeSetInput'
 *   },
 * });
 */
export function useDeviceModelAttributeSetCreateMutation(baseOptions?: Apollo.MutationHookOptions<DeviceModelAttributeSetCreateMutation, DeviceModelAttributeSetCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceModelAttributeSetCreateMutation, DeviceModelAttributeSetCreateMutationVariables>(DeviceModelAttributeSetCreateDocument, options);
      }
export type DeviceModelAttributeSetCreateMutationHookResult = ReturnType<typeof useDeviceModelAttributeSetCreateMutation>;
export type DeviceModelAttributeSetCreateMutationResult = Apollo.MutationResult<DeviceModelAttributeSetCreateMutation>;
export type DeviceModelAttributeSetCreateMutationOptions = Apollo.BaseMutationOptions<DeviceModelAttributeSetCreateMutation, DeviceModelAttributeSetCreateMutationVariables>;
export const DeviceModelAttributeSetDeleteDocument = gql`
    mutation DeviceModelAttributeSetDelete($deviceModelAttributeSetId: UUID!) {
  deleteDeviceModelAttributeSet(id: $deviceModelAttributeSetId) {
    id
  }
}
    `;
export type DeviceModelAttributeSetDeleteMutationFn = Apollo.MutationFunction<DeviceModelAttributeSetDeleteMutation, DeviceModelAttributeSetDeleteMutationVariables>;

/**
 * __useDeviceModelAttributeSetDeleteMutation__
 *
 * To run a mutation, you first call `useDeviceModelAttributeSetDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelAttributeSetDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceModelAttributeSetDeleteMutation, { data, loading, error }] = useDeviceModelAttributeSetDeleteMutation({
 *   variables: {
 *      deviceModelAttributeSetId: // value for 'deviceModelAttributeSetId'
 *   },
 * });
 */
export function useDeviceModelAttributeSetDeleteMutation(baseOptions?: Apollo.MutationHookOptions<DeviceModelAttributeSetDeleteMutation, DeviceModelAttributeSetDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceModelAttributeSetDeleteMutation, DeviceModelAttributeSetDeleteMutationVariables>(DeviceModelAttributeSetDeleteDocument, options);
      }
export type DeviceModelAttributeSetDeleteMutationHookResult = ReturnType<typeof useDeviceModelAttributeSetDeleteMutation>;
export type DeviceModelAttributeSetDeleteMutationResult = Apollo.MutationResult<DeviceModelAttributeSetDeleteMutation>;
export type DeviceModelAttributeSetDeleteMutationOptions = Apollo.BaseMutationOptions<DeviceModelAttributeSetDeleteMutation, DeviceModelAttributeSetDeleteMutationVariables>;
export const DeviceModelAttributeSetDetailsDocument = gql`
    query DeviceModelAttributeSetDetails($deviceModelAttributeSetId: UUID!) {
  deviceModelAttributeSet(id: $deviceModelAttributeSetId) {
    id
    name
    deviceModelId
    deviceModel {
      name
      isGlobal
    }
  }
}
    `;

/**
 * __useDeviceModelAttributeSetDetailsQuery__
 *
 * To run a query within a React component, call `useDeviceModelAttributeSetDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelAttributeSetDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceModelAttributeSetDetailsQuery({
 *   variables: {
 *      deviceModelAttributeSetId: // value for 'deviceModelAttributeSetId'
 *   },
 * });
 */
export function useDeviceModelAttributeSetDetailsQuery(baseOptions: Apollo.QueryHookOptions<DeviceModelAttributeSetDetailsQuery, DeviceModelAttributeSetDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceModelAttributeSetDetailsQuery, DeviceModelAttributeSetDetailsQueryVariables>(DeviceModelAttributeSetDetailsDocument, options);
      }
export function useDeviceModelAttributeSetDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceModelAttributeSetDetailsQuery, DeviceModelAttributeSetDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceModelAttributeSetDetailsQuery, DeviceModelAttributeSetDetailsQueryVariables>(DeviceModelAttributeSetDetailsDocument, options);
        }
export type DeviceModelAttributeSetDetailsQueryHookResult = ReturnType<typeof useDeviceModelAttributeSetDetailsQuery>;
export type DeviceModelAttributeSetDetailsLazyQueryHookResult = ReturnType<typeof useDeviceModelAttributeSetDetailsLazyQuery>;
export type DeviceModelAttributeSetDetailsQueryResult = Apollo.QueryResult<DeviceModelAttributeSetDetailsQuery, DeviceModelAttributeSetDetailsQueryVariables>;
export const DeviceModelAttributeSetDetailsAttributesDocument = gql`
    query DeviceModelAttributeSetDetailsAttributes($deviceModelAttributeSetId: UUID!) {
  deviceModelAttributeSet(id: $deviceModelAttributeSetId) {
    id
    name
    deviceModelId
    deviceModel {
      id
      name
      isGlobal
      deviceModelAttributeSets {
        id
        mappingAttributeDeviceModelAttributeSets {
          attributeId
        }
      }
    }
    mappingAttributeDeviceModelAttributeSets {
      id
      attributeId
      attribute {
        id
        name
        dataType
        required
      }
    }
  }
  attributes {
    items {
      id
      name
      dataType
      required
    }
  }
}
    `;

/**
 * __useDeviceModelAttributeSetDetailsAttributesQuery__
 *
 * To run a query within a React component, call `useDeviceModelAttributeSetDetailsAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelAttributeSetDetailsAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceModelAttributeSetDetailsAttributesQuery({
 *   variables: {
 *      deviceModelAttributeSetId: // value for 'deviceModelAttributeSetId'
 *   },
 * });
 */
export function useDeviceModelAttributeSetDetailsAttributesQuery(baseOptions: Apollo.QueryHookOptions<DeviceModelAttributeSetDetailsAttributesQuery, DeviceModelAttributeSetDetailsAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceModelAttributeSetDetailsAttributesQuery, DeviceModelAttributeSetDetailsAttributesQueryVariables>(DeviceModelAttributeSetDetailsAttributesDocument, options);
      }
export function useDeviceModelAttributeSetDetailsAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceModelAttributeSetDetailsAttributesQuery, DeviceModelAttributeSetDetailsAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceModelAttributeSetDetailsAttributesQuery, DeviceModelAttributeSetDetailsAttributesQueryVariables>(DeviceModelAttributeSetDetailsAttributesDocument, options);
        }
export type DeviceModelAttributeSetDetailsAttributesQueryHookResult = ReturnType<typeof useDeviceModelAttributeSetDetailsAttributesQuery>;
export type DeviceModelAttributeSetDetailsAttributesLazyQueryHookResult = ReturnType<typeof useDeviceModelAttributeSetDetailsAttributesLazyQuery>;
export type DeviceModelAttributeSetDetailsAttributesQueryResult = Apollo.QueryResult<DeviceModelAttributeSetDetailsAttributesQuery, DeviceModelAttributeSetDetailsAttributesQueryVariables>;
export const DeviceModelAttributeSetDetailsAttributeCreateDocument = gql`
    mutation DeviceModelAttributeSetDetailsAttributeCreate($mappingInput: MappingAttributeDeviceModelAttributeSetCreateType!) {
  createMappingAttributeDeviceModelAttributeSet(
    mappingAttributeDeviceModelAttributeSet: $mappingInput
  ) {
    id
  }
}
    `;
export type DeviceModelAttributeSetDetailsAttributeCreateMutationFn = Apollo.MutationFunction<DeviceModelAttributeSetDetailsAttributeCreateMutation, DeviceModelAttributeSetDetailsAttributeCreateMutationVariables>;

/**
 * __useDeviceModelAttributeSetDetailsAttributeCreateMutation__
 *
 * To run a mutation, you first call `useDeviceModelAttributeSetDetailsAttributeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelAttributeSetDetailsAttributeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceModelAttributeSetDetailsAttributeCreateMutation, { data, loading, error }] = useDeviceModelAttributeSetDetailsAttributeCreateMutation({
 *   variables: {
 *      mappingInput: // value for 'mappingInput'
 *   },
 * });
 */
export function useDeviceModelAttributeSetDetailsAttributeCreateMutation(baseOptions?: Apollo.MutationHookOptions<DeviceModelAttributeSetDetailsAttributeCreateMutation, DeviceModelAttributeSetDetailsAttributeCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceModelAttributeSetDetailsAttributeCreateMutation, DeviceModelAttributeSetDetailsAttributeCreateMutationVariables>(DeviceModelAttributeSetDetailsAttributeCreateDocument, options);
      }
export type DeviceModelAttributeSetDetailsAttributeCreateMutationHookResult = ReturnType<typeof useDeviceModelAttributeSetDetailsAttributeCreateMutation>;
export type DeviceModelAttributeSetDetailsAttributeCreateMutationResult = Apollo.MutationResult<DeviceModelAttributeSetDetailsAttributeCreateMutation>;
export type DeviceModelAttributeSetDetailsAttributeCreateMutationOptions = Apollo.BaseMutationOptions<DeviceModelAttributeSetDetailsAttributeCreateMutation, DeviceModelAttributeSetDetailsAttributeCreateMutationVariables>;
export const DeviceModelAttributeSetDetailsAttributeUpdateDocument = gql`
    mutation DeviceModelAttributeSetDetailsAttributeUpdate($mappingInput: MappingAttributeDeviceModelAttributeSetUpdateType!) {
  updateMappingAttributeDeviceModelAttributeSet(
    mappingAttributeDeviceModelAttributeSet: $mappingInput
  ) {
    id
  }
}
    `;
export type DeviceModelAttributeSetDetailsAttributeUpdateMutationFn = Apollo.MutationFunction<DeviceModelAttributeSetDetailsAttributeUpdateMutation, DeviceModelAttributeSetDetailsAttributeUpdateMutationVariables>;

/**
 * __useDeviceModelAttributeSetDetailsAttributeUpdateMutation__
 *
 * To run a mutation, you first call `useDeviceModelAttributeSetDetailsAttributeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelAttributeSetDetailsAttributeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceModelAttributeSetDetailsAttributeUpdateMutation, { data, loading, error }] = useDeviceModelAttributeSetDetailsAttributeUpdateMutation({
 *   variables: {
 *      mappingInput: // value for 'mappingInput'
 *   },
 * });
 */
export function useDeviceModelAttributeSetDetailsAttributeUpdateMutation(baseOptions?: Apollo.MutationHookOptions<DeviceModelAttributeSetDetailsAttributeUpdateMutation, DeviceModelAttributeSetDetailsAttributeUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceModelAttributeSetDetailsAttributeUpdateMutation, DeviceModelAttributeSetDetailsAttributeUpdateMutationVariables>(DeviceModelAttributeSetDetailsAttributeUpdateDocument, options);
      }
export type DeviceModelAttributeSetDetailsAttributeUpdateMutationHookResult = ReturnType<typeof useDeviceModelAttributeSetDetailsAttributeUpdateMutation>;
export type DeviceModelAttributeSetDetailsAttributeUpdateMutationResult = Apollo.MutationResult<DeviceModelAttributeSetDetailsAttributeUpdateMutation>;
export type DeviceModelAttributeSetDetailsAttributeUpdateMutationOptions = Apollo.BaseMutationOptions<DeviceModelAttributeSetDetailsAttributeUpdateMutation, DeviceModelAttributeSetDetailsAttributeUpdateMutationVariables>;
export const DeviceModelAttributeSetDetailsAttributeDeleteDocument = gql`
    mutation DeviceModelAttributeSetDetailsAttributeDelete($mappingId: UUID!) {
  deleteMappingAttributeDeviceModelAttributeSet(id: $mappingId) {
    id
  }
}
    `;
export type DeviceModelAttributeSetDetailsAttributeDeleteMutationFn = Apollo.MutationFunction<DeviceModelAttributeSetDetailsAttributeDeleteMutation, DeviceModelAttributeSetDetailsAttributeDeleteMutationVariables>;

/**
 * __useDeviceModelAttributeSetDetailsAttributeDeleteMutation__
 *
 * To run a mutation, you first call `useDeviceModelAttributeSetDetailsAttributeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelAttributeSetDetailsAttributeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceModelAttributeSetDetailsAttributeDeleteMutation, { data, loading, error }] = useDeviceModelAttributeSetDetailsAttributeDeleteMutation({
 *   variables: {
 *      mappingId: // value for 'mappingId'
 *   },
 * });
 */
export function useDeviceModelAttributeSetDetailsAttributeDeleteMutation(baseOptions?: Apollo.MutationHookOptions<DeviceModelAttributeSetDetailsAttributeDeleteMutation, DeviceModelAttributeSetDetailsAttributeDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceModelAttributeSetDetailsAttributeDeleteMutation, DeviceModelAttributeSetDetailsAttributeDeleteMutationVariables>(DeviceModelAttributeSetDetailsAttributeDeleteDocument, options);
      }
export type DeviceModelAttributeSetDetailsAttributeDeleteMutationHookResult = ReturnType<typeof useDeviceModelAttributeSetDetailsAttributeDeleteMutation>;
export type DeviceModelAttributeSetDetailsAttributeDeleteMutationResult = Apollo.MutationResult<DeviceModelAttributeSetDetailsAttributeDeleteMutation>;
export type DeviceModelAttributeSetDetailsAttributeDeleteMutationOptions = Apollo.BaseMutationOptions<DeviceModelAttributeSetDetailsAttributeDeleteMutation, DeviceModelAttributeSetDetailsAttributeDeleteMutationVariables>;
export const DeviceModelAttributeSetEditDetailsDocument = gql`
    query DeviceModelAttributeSetEditDetails($deviceModelAttributeSetId: UUID!) {
  deviceModelAttributeSet(id: $deviceModelAttributeSetId) {
    id
    deviceModelId
    name
    deviceModel {
      name
      isGlobal
    }
  }
}
    `;

/**
 * __useDeviceModelAttributeSetEditDetailsQuery__
 *
 * To run a query within a React component, call `useDeviceModelAttributeSetEditDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelAttributeSetEditDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceModelAttributeSetEditDetailsQuery({
 *   variables: {
 *      deviceModelAttributeSetId: // value for 'deviceModelAttributeSetId'
 *   },
 * });
 */
export function useDeviceModelAttributeSetEditDetailsQuery(baseOptions: Apollo.QueryHookOptions<DeviceModelAttributeSetEditDetailsQuery, DeviceModelAttributeSetEditDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceModelAttributeSetEditDetailsQuery, DeviceModelAttributeSetEditDetailsQueryVariables>(DeviceModelAttributeSetEditDetailsDocument, options);
      }
export function useDeviceModelAttributeSetEditDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceModelAttributeSetEditDetailsQuery, DeviceModelAttributeSetEditDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceModelAttributeSetEditDetailsQuery, DeviceModelAttributeSetEditDetailsQueryVariables>(DeviceModelAttributeSetEditDetailsDocument, options);
        }
export type DeviceModelAttributeSetEditDetailsQueryHookResult = ReturnType<typeof useDeviceModelAttributeSetEditDetailsQuery>;
export type DeviceModelAttributeSetEditDetailsLazyQueryHookResult = ReturnType<typeof useDeviceModelAttributeSetEditDetailsLazyQuery>;
export type DeviceModelAttributeSetEditDetailsQueryResult = Apollo.QueryResult<DeviceModelAttributeSetEditDetailsQuery, DeviceModelAttributeSetEditDetailsQueryVariables>;
export const DeviceModelAttributeSetUpdateDocument = gql`
    mutation DeviceModelAttributeSetUpdate($deviceModelAttributeSetInput: DeviceModelAttributeSetUpdateType!) {
  updateDeviceModelAttributeSet(
    deviceModelAttributeSet: $deviceModelAttributeSetInput
  ) {
    id
  }
}
    `;
export type DeviceModelAttributeSetUpdateMutationFn = Apollo.MutationFunction<DeviceModelAttributeSetUpdateMutation, DeviceModelAttributeSetUpdateMutationVariables>;

/**
 * __useDeviceModelAttributeSetUpdateMutation__
 *
 * To run a mutation, you first call `useDeviceModelAttributeSetUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelAttributeSetUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceModelAttributeSetUpdateMutation, { data, loading, error }] = useDeviceModelAttributeSetUpdateMutation({
 *   variables: {
 *      deviceModelAttributeSetInput: // value for 'deviceModelAttributeSetInput'
 *   },
 * });
 */
export function useDeviceModelAttributeSetUpdateMutation(baseOptions?: Apollo.MutationHookOptions<DeviceModelAttributeSetUpdateMutation, DeviceModelAttributeSetUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceModelAttributeSetUpdateMutation, DeviceModelAttributeSetUpdateMutationVariables>(DeviceModelAttributeSetUpdateDocument, options);
      }
export type DeviceModelAttributeSetUpdateMutationHookResult = ReturnType<typeof useDeviceModelAttributeSetUpdateMutation>;
export type DeviceModelAttributeSetUpdateMutationResult = Apollo.MutationResult<DeviceModelAttributeSetUpdateMutation>;
export type DeviceModelAttributeSetUpdateMutationOptions = Apollo.BaseMutationOptions<DeviceModelAttributeSetUpdateMutation, DeviceModelAttributeSetUpdateMutationVariables>;
export const DeviceModelCapabilityCreateDetailsDocument = gql`
    query DeviceModelCapabilityCreateDetails($deviceModelId: UUID!) {
  deviceModel(id: $deviceModelId) {
    id
    name
    isGlobal
    deviceModelCapabilities {
      fieldSelector
    }
  }
  capabilities {
    items {
      id
      name
      dataType
    }
  }
  units {
    items {
      id
      name
      unitSymbol
      uris
    }
  }
}
    `;

/**
 * __useDeviceModelCapabilityCreateDetailsQuery__
 *
 * To run a query within a React component, call `useDeviceModelCapabilityCreateDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelCapabilityCreateDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceModelCapabilityCreateDetailsQuery({
 *   variables: {
 *      deviceModelId: // value for 'deviceModelId'
 *   },
 * });
 */
export function useDeviceModelCapabilityCreateDetailsQuery(baseOptions: Apollo.QueryHookOptions<DeviceModelCapabilityCreateDetailsQuery, DeviceModelCapabilityCreateDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceModelCapabilityCreateDetailsQuery, DeviceModelCapabilityCreateDetailsQueryVariables>(DeviceModelCapabilityCreateDetailsDocument, options);
      }
export function useDeviceModelCapabilityCreateDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceModelCapabilityCreateDetailsQuery, DeviceModelCapabilityCreateDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceModelCapabilityCreateDetailsQuery, DeviceModelCapabilityCreateDetailsQueryVariables>(DeviceModelCapabilityCreateDetailsDocument, options);
        }
export type DeviceModelCapabilityCreateDetailsQueryHookResult = ReturnType<typeof useDeviceModelCapabilityCreateDetailsQuery>;
export type DeviceModelCapabilityCreateDetailsLazyQueryHookResult = ReturnType<typeof useDeviceModelCapabilityCreateDetailsLazyQuery>;
export type DeviceModelCapabilityCreateDetailsQueryResult = Apollo.QueryResult<DeviceModelCapabilityCreateDetailsQuery, DeviceModelCapabilityCreateDetailsQueryVariables>;
export const DeviceModelCapabilityCreateDocument = gql`
    mutation DeviceModelCapabilityCreate($deviceModelCapabilityInput: DeviceModelCapabilityCreateType!) {
  createDeviceModelCapability(deviceModelCapability: $deviceModelCapabilityInput) {
    id
  }
}
    `;
export type DeviceModelCapabilityCreateMutationFn = Apollo.MutationFunction<DeviceModelCapabilityCreateMutation, DeviceModelCapabilityCreateMutationVariables>;

/**
 * __useDeviceModelCapabilityCreateMutation__
 *
 * To run a mutation, you first call `useDeviceModelCapabilityCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelCapabilityCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceModelCapabilityCreateMutation, { data, loading, error }] = useDeviceModelCapabilityCreateMutation({
 *   variables: {
 *      deviceModelCapabilityInput: // value for 'deviceModelCapabilityInput'
 *   },
 * });
 */
export function useDeviceModelCapabilityCreateMutation(baseOptions?: Apollo.MutationHookOptions<DeviceModelCapabilityCreateMutation, DeviceModelCapabilityCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceModelCapabilityCreateMutation, DeviceModelCapabilityCreateMutationVariables>(DeviceModelCapabilityCreateDocument, options);
      }
export type DeviceModelCapabilityCreateMutationHookResult = ReturnType<typeof useDeviceModelCapabilityCreateMutation>;
export type DeviceModelCapabilityCreateMutationResult = Apollo.MutationResult<DeviceModelCapabilityCreateMutation>;
export type DeviceModelCapabilityCreateMutationOptions = Apollo.BaseMutationOptions<DeviceModelCapabilityCreateMutation, DeviceModelCapabilityCreateMutationVariables>;
export const DeviceModelCapabilityDeleteDocument = gql`
    mutation DeviceModelCapabilityDelete($deviceModelCapabilityId: UUID!) {
  deleteDeviceModelCapability(id: $deviceModelCapabilityId) {
    id
  }
}
    `;
export type DeviceModelCapabilityDeleteMutationFn = Apollo.MutationFunction<DeviceModelCapabilityDeleteMutation, DeviceModelCapabilityDeleteMutationVariables>;

/**
 * __useDeviceModelCapabilityDeleteMutation__
 *
 * To run a mutation, you first call `useDeviceModelCapabilityDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelCapabilityDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceModelCapabilityDeleteMutation, { data, loading, error }] = useDeviceModelCapabilityDeleteMutation({
 *   variables: {
 *      deviceModelCapabilityId: // value for 'deviceModelCapabilityId'
 *   },
 * });
 */
export function useDeviceModelCapabilityDeleteMutation(baseOptions?: Apollo.MutationHookOptions<DeviceModelCapabilityDeleteMutation, DeviceModelCapabilityDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceModelCapabilityDeleteMutation, DeviceModelCapabilityDeleteMutationVariables>(DeviceModelCapabilityDeleteDocument, options);
      }
export type DeviceModelCapabilityDeleteMutationHookResult = ReturnType<typeof useDeviceModelCapabilityDeleteMutation>;
export type DeviceModelCapabilityDeleteMutationResult = Apollo.MutationResult<DeviceModelCapabilityDeleteMutation>;
export type DeviceModelCapabilityDeleteMutationOptions = Apollo.BaseMutationOptions<DeviceModelCapabilityDeleteMutation, DeviceModelCapabilityDeleteMutationVariables>;
export const DeviceModelCapabilityDetailsDocument = gql`
    query DeviceModelCapabilityDetails($deviceModelCapabilityId: UUID!) {
  deviceModelCapability(id: $deviceModelCapabilityId) {
    id
    isLocked
    deviceModelId
    deviceModel {
      name
      isGlobal
    }
    capability {
      name
      dataType
    }
    fieldSelector
    technicalMax
    technicalMin
    unit {
      name
      unitSymbol
      uris
    }
  }
}
    `;

/**
 * __useDeviceModelCapabilityDetailsQuery__
 *
 * To run a query within a React component, call `useDeviceModelCapabilityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelCapabilityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceModelCapabilityDetailsQuery({
 *   variables: {
 *      deviceModelCapabilityId: // value for 'deviceModelCapabilityId'
 *   },
 * });
 */
export function useDeviceModelCapabilityDetailsQuery(baseOptions: Apollo.QueryHookOptions<DeviceModelCapabilityDetailsQuery, DeviceModelCapabilityDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceModelCapabilityDetailsQuery, DeviceModelCapabilityDetailsQueryVariables>(DeviceModelCapabilityDetailsDocument, options);
      }
export function useDeviceModelCapabilityDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceModelCapabilityDetailsQuery, DeviceModelCapabilityDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceModelCapabilityDetailsQuery, DeviceModelCapabilityDetailsQueryVariables>(DeviceModelCapabilityDetailsDocument, options);
        }
export type DeviceModelCapabilityDetailsQueryHookResult = ReturnType<typeof useDeviceModelCapabilityDetailsQuery>;
export type DeviceModelCapabilityDetailsLazyQueryHookResult = ReturnType<typeof useDeviceModelCapabilityDetailsLazyQuery>;
export type DeviceModelCapabilityDetailsQueryResult = Apollo.QueryResult<DeviceModelCapabilityDetailsQuery, DeviceModelCapabilityDetailsQueryVariables>;
export const DeviceModelCapabilityEditDetailsDocument = gql`
    query DeviceModelCapabilityEditDetails($deviceModelCapabilityId: UUID!) {
  deviceModelCapability(id: $deviceModelCapabilityId) {
    id
    deviceModelId
    deviceModel {
      id
      name
      isGlobal
      deviceModelCapabilities {
        fieldSelector
      }
    }
    capability {
      id
      name
    }
    fieldSelector
    technicalMax
    technicalMin
    unit {
      id
      name
      unitSymbol
    }
  }
  capabilities {
    items {
      id
      name
      dataType
    }
  }
  units {
    items {
      id
      name
      unitSymbol
      uris
    }
  }
}
    `;

/**
 * __useDeviceModelCapabilityEditDetailsQuery__
 *
 * To run a query within a React component, call `useDeviceModelCapabilityEditDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelCapabilityEditDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceModelCapabilityEditDetailsQuery({
 *   variables: {
 *      deviceModelCapabilityId: // value for 'deviceModelCapabilityId'
 *   },
 * });
 */
export function useDeviceModelCapabilityEditDetailsQuery(baseOptions: Apollo.QueryHookOptions<DeviceModelCapabilityEditDetailsQuery, DeviceModelCapabilityEditDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceModelCapabilityEditDetailsQuery, DeviceModelCapabilityEditDetailsQueryVariables>(DeviceModelCapabilityEditDetailsDocument, options);
      }
export function useDeviceModelCapabilityEditDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceModelCapabilityEditDetailsQuery, DeviceModelCapabilityEditDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceModelCapabilityEditDetailsQuery, DeviceModelCapabilityEditDetailsQueryVariables>(DeviceModelCapabilityEditDetailsDocument, options);
        }
export type DeviceModelCapabilityEditDetailsQueryHookResult = ReturnType<typeof useDeviceModelCapabilityEditDetailsQuery>;
export type DeviceModelCapabilityEditDetailsLazyQueryHookResult = ReturnType<typeof useDeviceModelCapabilityEditDetailsLazyQuery>;
export type DeviceModelCapabilityEditDetailsQueryResult = Apollo.QueryResult<DeviceModelCapabilityEditDetailsQuery, DeviceModelCapabilityEditDetailsQueryVariables>;
export const DeviceModelCapabilityUpdateDocument = gql`
    mutation DeviceModelCapabilityUpdate($deviceModelCapabilityInput: DeviceModelCapabilityUpdateType!) {
  updateDeviceModelCapability(deviceModelCapability: $deviceModelCapabilityInput) {
    id
  }
}
    `;
export type DeviceModelCapabilityUpdateMutationFn = Apollo.MutationFunction<DeviceModelCapabilityUpdateMutation, DeviceModelCapabilityUpdateMutationVariables>;

/**
 * __useDeviceModelCapabilityUpdateMutation__
 *
 * To run a mutation, you first call `useDeviceModelCapabilityUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelCapabilityUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceModelCapabilityUpdateMutation, { data, loading, error }] = useDeviceModelCapabilityUpdateMutation({
 *   variables: {
 *      deviceModelCapabilityInput: // value for 'deviceModelCapabilityInput'
 *   },
 * });
 */
export function useDeviceModelCapabilityUpdateMutation(baseOptions?: Apollo.MutationHookOptions<DeviceModelCapabilityUpdateMutation, DeviceModelCapabilityUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceModelCapabilityUpdateMutation, DeviceModelCapabilityUpdateMutationVariables>(DeviceModelCapabilityUpdateDocument, options);
      }
export type DeviceModelCapabilityUpdateMutationHookResult = ReturnType<typeof useDeviceModelCapabilityUpdateMutation>;
export type DeviceModelCapabilityUpdateMutationResult = Apollo.MutationResult<DeviceModelCapabilityUpdateMutation>;
export type DeviceModelCapabilityUpdateMutationOptions = Apollo.BaseMutationOptions<DeviceModelCapabilityUpdateMutation, DeviceModelCapabilityUpdateMutationVariables>;
export const DeviceTypesDocument = gql`
    query DeviceTypes {
  deviceTypes {
    items {
      id
      name
      isGateway
    }
  }
}
    `;

/**
 * __useDeviceTypesQuery__
 *
 * To run a query within a React component, call `useDeviceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeviceTypesQuery(baseOptions?: Apollo.QueryHookOptions<DeviceTypesQuery, DeviceTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceTypesQuery, DeviceTypesQueryVariables>(DeviceTypesDocument, options);
      }
export function useDeviceTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceTypesQuery, DeviceTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceTypesQuery, DeviceTypesQueryVariables>(DeviceTypesDocument, options);
        }
export type DeviceTypesQueryHookResult = ReturnType<typeof useDeviceTypesQuery>;
export type DeviceTypesLazyQueryHookResult = ReturnType<typeof useDeviceTypesLazyQuery>;
export type DeviceTypesQueryResult = Apollo.QueryResult<DeviceTypesQuery, DeviceTypesQueryVariables>;
export const DeviceModelCreateDocument = gql`
    mutation DeviceModelCreate($deviceModel: DeviceModelCreateType!) {
  createDeviceModel(deviceModel: $deviceModel) {
    id
  }
}
    `;
export type DeviceModelCreateMutationFn = Apollo.MutationFunction<DeviceModelCreateMutation, DeviceModelCreateMutationVariables>;

/**
 * __useDeviceModelCreateMutation__
 *
 * To run a mutation, you first call `useDeviceModelCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceModelCreateMutation, { data, loading, error }] = useDeviceModelCreateMutation({
 *   variables: {
 *      deviceModel: // value for 'deviceModel'
 *   },
 * });
 */
export function useDeviceModelCreateMutation(baseOptions?: Apollo.MutationHookOptions<DeviceModelCreateMutation, DeviceModelCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceModelCreateMutation, DeviceModelCreateMutationVariables>(DeviceModelCreateDocument, options);
      }
export type DeviceModelCreateMutationHookResult = ReturnType<typeof useDeviceModelCreateMutation>;
export type DeviceModelCreateMutationResult = Apollo.MutationResult<DeviceModelCreateMutation>;
export type DeviceModelCreateMutationOptions = Apollo.BaseMutationOptions<DeviceModelCreateMutation, DeviceModelCreateMutationVariables>;
export const DeviceModelDeleteDocument = gql`
    mutation DeviceModelDelete($deviceModelId: UUID!) {
  deleteDeviceModel(id: $deviceModelId) {
    id
  }
}
    `;
export type DeviceModelDeleteMutationFn = Apollo.MutationFunction<DeviceModelDeleteMutation, DeviceModelDeleteMutationVariables>;

/**
 * __useDeviceModelDeleteMutation__
 *
 * To run a mutation, you first call `useDeviceModelDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceModelDeleteMutation, { data, loading, error }] = useDeviceModelDeleteMutation({
 *   variables: {
 *      deviceModelId: // value for 'deviceModelId'
 *   },
 * });
 */
export function useDeviceModelDeleteMutation(baseOptions?: Apollo.MutationHookOptions<DeviceModelDeleteMutation, DeviceModelDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceModelDeleteMutation, DeviceModelDeleteMutationVariables>(DeviceModelDeleteDocument, options);
      }
export type DeviceModelDeleteMutationHookResult = ReturnType<typeof useDeviceModelDeleteMutation>;
export type DeviceModelDeleteMutationResult = Apollo.MutationResult<DeviceModelDeleteMutation>;
export type DeviceModelDeleteMutationOptions = Apollo.BaseMutationOptions<DeviceModelDeleteMutation, DeviceModelDeleteMutationVariables>;
export const DeviceModelDetailsDocument = gql`
    query DeviceModelDetails($deviceModelId: UUID!) {
  deviceModel(id: $deviceModelId) {
    id
    name
    deviceIdentifierFieldSelector
    isGlobal
    isLocked
    deviceModelAttributeSets {
      id
      name
      mappingAttributeDeviceModelAttributeSets {
        id
        attributeId
        attribute {
          id
          name
          required
        }
      }
    }
    deviceType {
      id
      name
      isGateway
    }
  }
}
    `;

/**
 * __useDeviceModelDetailsQuery__
 *
 * To run a query within a React component, call `useDeviceModelDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceModelDetailsQuery({
 *   variables: {
 *      deviceModelId: // value for 'deviceModelId'
 *   },
 * });
 */
export function useDeviceModelDetailsQuery(baseOptions: Apollo.QueryHookOptions<DeviceModelDetailsQuery, DeviceModelDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceModelDetailsQuery, DeviceModelDetailsQueryVariables>(DeviceModelDetailsDocument, options);
      }
export function useDeviceModelDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceModelDetailsQuery, DeviceModelDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceModelDetailsQuery, DeviceModelDetailsQueryVariables>(DeviceModelDetailsDocument, options);
        }
export type DeviceModelDetailsQueryHookResult = ReturnType<typeof useDeviceModelDetailsQuery>;
export type DeviceModelDetailsLazyQueryHookResult = ReturnType<typeof useDeviceModelDetailsLazyQuery>;
export type DeviceModelDetailsQueryResult = Apollo.QueryResult<DeviceModelDetailsQuery, DeviceModelDetailsQueryVariables>;
export const DeviceModelDetailsAttributeSetsDocument = gql`
    query DeviceModelDetailsAttributeSets($deviceModelId: UUID!) {
  deviceModel(id: $deviceModelId) {
    id
    isGlobal
    deviceModelAttributeSets {
      id
      name
      mappingAttributeDeviceModelAttributeSets {
        id
        attributeId
      }
    }
  }
}
    `;

/**
 * __useDeviceModelDetailsAttributeSetsQuery__
 *
 * To run a query within a React component, call `useDeviceModelDetailsAttributeSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelDetailsAttributeSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceModelDetailsAttributeSetsQuery({
 *   variables: {
 *      deviceModelId: // value for 'deviceModelId'
 *   },
 * });
 */
export function useDeviceModelDetailsAttributeSetsQuery(baseOptions: Apollo.QueryHookOptions<DeviceModelDetailsAttributeSetsQuery, DeviceModelDetailsAttributeSetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceModelDetailsAttributeSetsQuery, DeviceModelDetailsAttributeSetsQueryVariables>(DeviceModelDetailsAttributeSetsDocument, options);
      }
export function useDeviceModelDetailsAttributeSetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceModelDetailsAttributeSetsQuery, DeviceModelDetailsAttributeSetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceModelDetailsAttributeSetsQuery, DeviceModelDetailsAttributeSetsQueryVariables>(DeviceModelDetailsAttributeSetsDocument, options);
        }
export type DeviceModelDetailsAttributeSetsQueryHookResult = ReturnType<typeof useDeviceModelDetailsAttributeSetsQuery>;
export type DeviceModelDetailsAttributeSetsLazyQueryHookResult = ReturnType<typeof useDeviceModelDetailsAttributeSetsLazyQuery>;
export type DeviceModelDetailsAttributeSetsQueryResult = Apollo.QueryResult<DeviceModelDetailsAttributeSetsQuery, DeviceModelDetailsAttributeSetsQueryVariables>;
export const DeviceModelDetailsCapabilitiesDocument = gql`
    query DeviceModelDetailsCapabilities($deviceModelId: UUID!) {
  deviceModel(id: $deviceModelId) {
    id
    name
    isGlobal
    deviceModelCapabilities {
      id
      capability {
        id
        name
      }
      technicalMax
      technicalMin
      unit {
        id
        name
        unitSymbol
      }
    }
  }
}
    `;

/**
 * __useDeviceModelDetailsCapabilitiesQuery__
 *
 * To run a query within a React component, call `useDeviceModelDetailsCapabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelDetailsCapabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceModelDetailsCapabilitiesQuery({
 *   variables: {
 *      deviceModelId: // value for 'deviceModelId'
 *   },
 * });
 */
export function useDeviceModelDetailsCapabilitiesQuery(baseOptions: Apollo.QueryHookOptions<DeviceModelDetailsCapabilitiesQuery, DeviceModelDetailsCapabilitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceModelDetailsCapabilitiesQuery, DeviceModelDetailsCapabilitiesQueryVariables>(DeviceModelDetailsCapabilitiesDocument, options);
      }
export function useDeviceModelDetailsCapabilitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceModelDetailsCapabilitiesQuery, DeviceModelDetailsCapabilitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceModelDetailsCapabilitiesQuery, DeviceModelDetailsCapabilitiesQueryVariables>(DeviceModelDetailsCapabilitiesDocument, options);
        }
export type DeviceModelDetailsCapabilitiesQueryHookResult = ReturnType<typeof useDeviceModelDetailsCapabilitiesQuery>;
export type DeviceModelDetailsCapabilitiesLazyQueryHookResult = ReturnType<typeof useDeviceModelDetailsCapabilitiesLazyQuery>;
export type DeviceModelDetailsCapabilitiesQueryResult = Apollo.QueryResult<DeviceModelDetailsCapabilitiesQuery, DeviceModelDetailsCapabilitiesQueryVariables>;
export const DeviceModelEditDetailsDocument = gql`
    query DeviceModelEditDetails($deviceModelId: UUID!) {
  deviceModel(id: $deviceModelId) {
    id
    name
    deviceIdentifierFieldSelector
    isGlobal
    isLocked
    deviceType {
      id
      name
      isGateway
    }
  }
}
    `;

/**
 * __useDeviceModelEditDetailsQuery__
 *
 * To run a query within a React component, call `useDeviceModelEditDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelEditDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceModelEditDetailsQuery({
 *   variables: {
 *      deviceModelId: // value for 'deviceModelId'
 *   },
 * });
 */
export function useDeviceModelEditDetailsQuery(baseOptions: Apollo.QueryHookOptions<DeviceModelEditDetailsQuery, DeviceModelEditDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceModelEditDetailsQuery, DeviceModelEditDetailsQueryVariables>(DeviceModelEditDetailsDocument, options);
      }
export function useDeviceModelEditDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceModelEditDetailsQuery, DeviceModelEditDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceModelEditDetailsQuery, DeviceModelEditDetailsQueryVariables>(DeviceModelEditDetailsDocument, options);
        }
export type DeviceModelEditDetailsQueryHookResult = ReturnType<typeof useDeviceModelEditDetailsQuery>;
export type DeviceModelEditDetailsLazyQueryHookResult = ReturnType<typeof useDeviceModelEditDetailsLazyQuery>;
export type DeviceModelEditDetailsQueryResult = Apollo.QueryResult<DeviceModelEditDetailsQuery, DeviceModelEditDetailsQueryVariables>;
export const DeviceModelUpdateDocument = gql`
    mutation DeviceModelUpdate($deviceModelInput: DeviceModelUpdateType!) {
  updateDeviceModel(deviceModel: $deviceModelInput) {
    id
  }
}
    `;
export type DeviceModelUpdateMutationFn = Apollo.MutationFunction<DeviceModelUpdateMutation, DeviceModelUpdateMutationVariables>;

/**
 * __useDeviceModelUpdateMutation__
 *
 * To run a mutation, you first call `useDeviceModelUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceModelUpdateMutation, { data, loading, error }] = useDeviceModelUpdateMutation({
 *   variables: {
 *      deviceModelInput: // value for 'deviceModelInput'
 *   },
 * });
 */
export function useDeviceModelUpdateMutation(baseOptions?: Apollo.MutationHookOptions<DeviceModelUpdateMutation, DeviceModelUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceModelUpdateMutation, DeviceModelUpdateMutationVariables>(DeviceModelUpdateDocument, options);
      }
export type DeviceModelUpdateMutationHookResult = ReturnType<typeof useDeviceModelUpdateMutation>;
export type DeviceModelUpdateMutationResult = Apollo.MutationResult<DeviceModelUpdateMutation>;
export type DeviceModelUpdateMutationOptions = Apollo.BaseMutationOptions<DeviceModelUpdateMutation, DeviceModelUpdateMutationVariables>;
export const DeviceModelsListDocument = gql`
    query DeviceModelsList($skip: Int!, $take: Int!, $where: DeviceModelFilterInput) {
  deviceModels(skip: $skip, take: $take, where: $where, order: {name: ASC}) {
    items {
      id
      name
      isGlobal
      deviceModelCapabilities {
        capability {
          id
          name
        }
      }
      devices {
        id
      }
    }
    totalCount
  }
}
    `;

/**
 * __useDeviceModelsListQuery__
 *
 * To run a query within a React component, call `useDeviceModelsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceModelsListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeviceModelsListQuery(baseOptions: Apollo.QueryHookOptions<DeviceModelsListQuery, DeviceModelsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceModelsListQuery, DeviceModelsListQueryVariables>(DeviceModelsListDocument, options);
      }
export function useDeviceModelsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceModelsListQuery, DeviceModelsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceModelsListQuery, DeviceModelsListQueryVariables>(DeviceModelsListDocument, options);
        }
export type DeviceModelsListQueryHookResult = ReturnType<typeof useDeviceModelsListQuery>;
export type DeviceModelsListLazyQueryHookResult = ReturnType<typeof useDeviceModelsListLazyQuery>;
export type DeviceModelsListQueryResult = Apollo.QueryResult<DeviceModelsListQuery, DeviceModelsListQueryVariables>;
export const DeviceAttributesDocument = gql`
    query DeviceAttributes($deviceId: UUID!) {
  device(id: $deviceId) {
    name
    deviceModel {
      deviceModelAttributeSets {
        id
        name
        mappingAttributeDeviceModelAttributeSets {
          attribute {
            name
            id
            required
            dataType
          }
        }
      }
    }
    deviceAttributeValues {
      id: attributeId
      value
    }
  }
}
    `;

/**
 * __useDeviceAttributesQuery__
 *
 * To run a query within a React component, call `useDeviceAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceAttributesQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeviceAttributesQuery(baseOptions: Apollo.QueryHookOptions<DeviceAttributesQuery, DeviceAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceAttributesQuery, DeviceAttributesQueryVariables>(DeviceAttributesDocument, options);
      }
export function useDeviceAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceAttributesQuery, DeviceAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceAttributesQuery, DeviceAttributesQueryVariables>(DeviceAttributesDocument, options);
        }
export type DeviceAttributesQueryHookResult = ReturnType<typeof useDeviceAttributesQuery>;
export type DeviceAttributesLazyQueryHookResult = ReturnType<typeof useDeviceAttributesLazyQuery>;
export type DeviceAttributesQueryResult = Apollo.QueryResult<DeviceAttributesQuery, DeviceAttributesQueryVariables>;
export const SetDeviceAttributeValueDocument = gql`
    mutation SetDeviceAttributeValue($deviceId: UUID!, $attributeId: UUID!, $value: String!) {
  setDeviceAttributeValue(
    value: $value
    deviceId: $deviceId
    attributeId: $attributeId
  ) {
    deviceId
    attributeId
    value
  }
}
    `;
export type SetDeviceAttributeValueMutationFn = Apollo.MutationFunction<SetDeviceAttributeValueMutation, SetDeviceAttributeValueMutationVariables>;

/**
 * __useSetDeviceAttributeValueMutation__
 *
 * To run a mutation, you first call `useSetDeviceAttributeValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDeviceAttributeValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDeviceAttributeValueMutation, { data, loading, error }] = useSetDeviceAttributeValueMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      attributeId: // value for 'attributeId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetDeviceAttributeValueMutation(baseOptions?: Apollo.MutationHookOptions<SetDeviceAttributeValueMutation, SetDeviceAttributeValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDeviceAttributeValueMutation, SetDeviceAttributeValueMutationVariables>(SetDeviceAttributeValueDocument, options);
      }
export type SetDeviceAttributeValueMutationHookResult = ReturnType<typeof useSetDeviceAttributeValueMutation>;
export type SetDeviceAttributeValueMutationResult = Apollo.MutationResult<SetDeviceAttributeValueMutation>;
export type SetDeviceAttributeValueMutationOptions = Apollo.BaseMutationOptions<SetDeviceAttributeValueMutation, SetDeviceAttributeValueMutationVariables>;
export const UnsetDeviceAttributeValueDocument = gql`
    mutation UnsetDeviceAttributeValue($deviceId: UUID!, $attributeId: UUID!) {
  unsetDeviceAttributeValue(deviceId: $deviceId, attributeId: $attributeId) {
    deviceId
    attributeId
    value
  }
}
    `;
export type UnsetDeviceAttributeValueMutationFn = Apollo.MutationFunction<UnsetDeviceAttributeValueMutation, UnsetDeviceAttributeValueMutationVariables>;

/**
 * __useUnsetDeviceAttributeValueMutation__
 *
 * To run a mutation, you first call `useUnsetDeviceAttributeValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsetDeviceAttributeValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsetDeviceAttributeValueMutation, { data, loading, error }] = useUnsetDeviceAttributeValueMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      attributeId: // value for 'attributeId'
 *   },
 * });
 */
export function useUnsetDeviceAttributeValueMutation(baseOptions?: Apollo.MutationHookOptions<UnsetDeviceAttributeValueMutation, UnsetDeviceAttributeValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsetDeviceAttributeValueMutation, UnsetDeviceAttributeValueMutationVariables>(UnsetDeviceAttributeValueDocument, options);
      }
export type UnsetDeviceAttributeValueMutationHookResult = ReturnType<typeof useUnsetDeviceAttributeValueMutation>;
export type UnsetDeviceAttributeValueMutationResult = Apollo.MutationResult<UnsetDeviceAttributeValueMutation>;
export type UnsetDeviceAttributeValueMutationOptions = Apollo.BaseMutationOptions<UnsetDeviceAttributeValueMutation, UnsetDeviceAttributeValueMutationVariables>;
export const DeviceIdentifierExistsDocument = gql`
    query DeviceIdentifierExists($deviceIdentifier: String!) {
  devices: devices(where: {deviceIdentifier: {eq: $deviceIdentifier}}) {
    items {
      id
      deviceIdentifier
    }
  }
}
    `;

/**
 * __useDeviceIdentifierExistsQuery__
 *
 * To run a query within a React component, call `useDeviceIdentifierExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceIdentifierExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceIdentifierExistsQuery({
 *   variables: {
 *      deviceIdentifier: // value for 'deviceIdentifier'
 *   },
 * });
 */
export function useDeviceIdentifierExistsQuery(baseOptions: Apollo.QueryHookOptions<DeviceIdentifierExistsQuery, DeviceIdentifierExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceIdentifierExistsQuery, DeviceIdentifierExistsQueryVariables>(DeviceIdentifierExistsDocument, options);
      }
export function useDeviceIdentifierExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceIdentifierExistsQuery, DeviceIdentifierExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceIdentifierExistsQuery, DeviceIdentifierExistsQueryVariables>(DeviceIdentifierExistsDocument, options);
        }
export type DeviceIdentifierExistsQueryHookResult = ReturnType<typeof useDeviceIdentifierExistsQuery>;
export type DeviceIdentifierExistsLazyQueryHookResult = ReturnType<typeof useDeviceIdentifierExistsLazyQuery>;
export type DeviceIdentifierExistsQueryResult = Apollo.QueryResult<DeviceIdentifierExistsQuery, DeviceIdentifierExistsQueryVariables>;
export const FieldDeviceCreateDeviceModelsAndSitesDocument = gql`
    query FieldDeviceCreateDeviceModelsAndSites {
  deviceModels(where: {deviceType: {isGateway: {eq: false}}}) {
    items {
      id
      name
      deviceType {
        isGateway
      }
      deviceModelAttributeSets {
        id
        name
        mappingAttributeDeviceModelAttributeSets {
          attribute {
            name
            id
            required
            dataType
          }
        }
      }
    }
  }
  sites {
    items {
      id
      name
      buildings {
        id
        name
        siteId
        storeys {
          id
          name
          buildingId
          zones {
            id
            name
            storeyId
          }
        }
      }
    }
  }
}
    `;

/**
 * __useFieldDeviceCreateDeviceModelsAndSitesQuery__
 *
 * To run a query within a React component, call `useFieldDeviceCreateDeviceModelsAndSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldDeviceCreateDeviceModelsAndSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldDeviceCreateDeviceModelsAndSitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFieldDeviceCreateDeviceModelsAndSitesQuery(baseOptions?: Apollo.QueryHookOptions<FieldDeviceCreateDeviceModelsAndSitesQuery, FieldDeviceCreateDeviceModelsAndSitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FieldDeviceCreateDeviceModelsAndSitesQuery, FieldDeviceCreateDeviceModelsAndSitesQueryVariables>(FieldDeviceCreateDeviceModelsAndSitesDocument, options);
      }
export function useFieldDeviceCreateDeviceModelsAndSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FieldDeviceCreateDeviceModelsAndSitesQuery, FieldDeviceCreateDeviceModelsAndSitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FieldDeviceCreateDeviceModelsAndSitesQuery, FieldDeviceCreateDeviceModelsAndSitesQueryVariables>(FieldDeviceCreateDeviceModelsAndSitesDocument, options);
        }
export type FieldDeviceCreateDeviceModelsAndSitesQueryHookResult = ReturnType<typeof useFieldDeviceCreateDeviceModelsAndSitesQuery>;
export type FieldDeviceCreateDeviceModelsAndSitesLazyQueryHookResult = ReturnType<typeof useFieldDeviceCreateDeviceModelsAndSitesLazyQuery>;
export type FieldDeviceCreateDeviceModelsAndSitesQueryResult = Apollo.QueryResult<FieldDeviceCreateDeviceModelsAndSitesQuery, FieldDeviceCreateDeviceModelsAndSitesQueryVariables>;
export const FieldDeviceCreateGatewayDevicesDocument = gql`
    query FieldDeviceCreateGatewayDevices {
  devices(where: {deviceModel: {deviceType: {isGateway: {eq: true}}}}) {
    items {
      id
      name
      deviceModel {
        deviceType {
          isGateway
        }
      }
    }
  }
}
    `;

/**
 * __useFieldDeviceCreateGatewayDevicesQuery__
 *
 * To run a query within a React component, call `useFieldDeviceCreateGatewayDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldDeviceCreateGatewayDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldDeviceCreateGatewayDevicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFieldDeviceCreateGatewayDevicesQuery(baseOptions?: Apollo.QueryHookOptions<FieldDeviceCreateGatewayDevicesQuery, FieldDeviceCreateGatewayDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FieldDeviceCreateGatewayDevicesQuery, FieldDeviceCreateGatewayDevicesQueryVariables>(FieldDeviceCreateGatewayDevicesDocument, options);
      }
export function useFieldDeviceCreateGatewayDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FieldDeviceCreateGatewayDevicesQuery, FieldDeviceCreateGatewayDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FieldDeviceCreateGatewayDevicesQuery, FieldDeviceCreateGatewayDevicesQueryVariables>(FieldDeviceCreateGatewayDevicesDocument, options);
        }
export type FieldDeviceCreateGatewayDevicesQueryHookResult = ReturnType<typeof useFieldDeviceCreateGatewayDevicesQuery>;
export type FieldDeviceCreateGatewayDevicesLazyQueryHookResult = ReturnType<typeof useFieldDeviceCreateGatewayDevicesLazyQuery>;
export type FieldDeviceCreateGatewayDevicesQueryResult = Apollo.QueryResult<FieldDeviceCreateGatewayDevicesQuery, FieldDeviceCreateGatewayDevicesQueryVariables>;
export const FieldDeviceCreateWithPlacementDocument = gql`
    mutation FieldDeviceCreateWithPlacement($device: DeviceCreateInputType!, $placementId: UUID!, $placementType: PlacementKind!, $attributeValues: [DeviceCreateAttributeValueType!]!) {
  createDeviceWithPlacement(
    device: $device
    placementId: $placementId
    placementType: $placementType
    attributeValues: $attributeValues
  ) {
    id
  }
}
    `;
export type FieldDeviceCreateWithPlacementMutationFn = Apollo.MutationFunction<FieldDeviceCreateWithPlacementMutation, FieldDeviceCreateWithPlacementMutationVariables>;

/**
 * __useFieldDeviceCreateWithPlacementMutation__
 *
 * To run a mutation, you first call `useFieldDeviceCreateWithPlacementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFieldDeviceCreateWithPlacementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fieldDeviceCreateWithPlacementMutation, { data, loading, error }] = useFieldDeviceCreateWithPlacementMutation({
 *   variables: {
 *      device: // value for 'device'
 *      placementId: // value for 'placementId'
 *      placementType: // value for 'placementType'
 *      attributeValues: // value for 'attributeValues'
 *   },
 * });
 */
export function useFieldDeviceCreateWithPlacementMutation(baseOptions?: Apollo.MutationHookOptions<FieldDeviceCreateWithPlacementMutation, FieldDeviceCreateWithPlacementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FieldDeviceCreateWithPlacementMutation, FieldDeviceCreateWithPlacementMutationVariables>(FieldDeviceCreateWithPlacementDocument, options);
      }
export type FieldDeviceCreateWithPlacementMutationHookResult = ReturnType<typeof useFieldDeviceCreateWithPlacementMutation>;
export type FieldDeviceCreateWithPlacementMutationResult = Apollo.MutationResult<FieldDeviceCreateWithPlacementMutation>;
export type FieldDeviceCreateWithPlacementMutationOptions = Apollo.BaseMutationOptions<FieldDeviceCreateWithPlacementMutation, FieldDeviceCreateWithPlacementMutationVariables>;
export const GatewayCreateDeviceModelsAndSitesDocument = gql`
    query GatewayCreateDeviceModelsAndSites {
  deviceModels(where: {deviceType: {isGateway: {eq: true}}}) {
    items {
      id
      name
      deviceType {
        isGateway
      }
      deviceModelAttributeSets {
        id
        name
        mappingAttributeDeviceModelAttributeSets {
          attribute {
            name
            id
            required
            dataType
          }
        }
      }
    }
  }
  sites {
    items {
      id
      name
      buildings {
        id
        name
        siteId
        storeys {
          id
          name
          buildingId
          zones {
            id
            name
            storeyId
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGatewayCreateDeviceModelsAndSitesQuery__
 *
 * To run a query within a React component, call `useGatewayCreateDeviceModelsAndSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGatewayCreateDeviceModelsAndSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGatewayCreateDeviceModelsAndSitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGatewayCreateDeviceModelsAndSitesQuery(baseOptions?: Apollo.QueryHookOptions<GatewayCreateDeviceModelsAndSitesQuery, GatewayCreateDeviceModelsAndSitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GatewayCreateDeviceModelsAndSitesQuery, GatewayCreateDeviceModelsAndSitesQueryVariables>(GatewayCreateDeviceModelsAndSitesDocument, options);
      }
export function useGatewayCreateDeviceModelsAndSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GatewayCreateDeviceModelsAndSitesQuery, GatewayCreateDeviceModelsAndSitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GatewayCreateDeviceModelsAndSitesQuery, GatewayCreateDeviceModelsAndSitesQueryVariables>(GatewayCreateDeviceModelsAndSitesDocument, options);
        }
export type GatewayCreateDeviceModelsAndSitesQueryHookResult = ReturnType<typeof useGatewayCreateDeviceModelsAndSitesQuery>;
export type GatewayCreateDeviceModelsAndSitesLazyQueryHookResult = ReturnType<typeof useGatewayCreateDeviceModelsAndSitesLazyQuery>;
export type GatewayCreateDeviceModelsAndSitesQueryResult = Apollo.QueryResult<GatewayCreateDeviceModelsAndSitesQuery, GatewayCreateDeviceModelsAndSitesQueryVariables>;
export const GatewayCreateWithPlacementDocument = gql`
    mutation GatewayCreateWithPlacement($device: DeviceCreateInputType!, $placementId: UUID!, $placementType: PlacementKind!, $attributeValues: [DeviceCreateAttributeValueType!]!) {
  createDeviceWithPlacement(
    device: $device
    placementId: $placementId
    placementType: $placementType
    attributeValues: $attributeValues
  ) {
    id
  }
}
    `;
export type GatewayCreateWithPlacementMutationFn = Apollo.MutationFunction<GatewayCreateWithPlacementMutation, GatewayCreateWithPlacementMutationVariables>;

/**
 * __useGatewayCreateWithPlacementMutation__
 *
 * To run a mutation, you first call `useGatewayCreateWithPlacementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGatewayCreateWithPlacementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gatewayCreateWithPlacementMutation, { data, loading, error }] = useGatewayCreateWithPlacementMutation({
 *   variables: {
 *      device: // value for 'device'
 *      placementId: // value for 'placementId'
 *      placementType: // value for 'placementType'
 *      attributeValues: // value for 'attributeValues'
 *   },
 * });
 */
export function useGatewayCreateWithPlacementMutation(baseOptions?: Apollo.MutationHookOptions<GatewayCreateWithPlacementMutation, GatewayCreateWithPlacementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GatewayCreateWithPlacementMutation, GatewayCreateWithPlacementMutationVariables>(GatewayCreateWithPlacementDocument, options);
      }
export type GatewayCreateWithPlacementMutationHookResult = ReturnType<typeof useGatewayCreateWithPlacementMutation>;
export type GatewayCreateWithPlacementMutationResult = Apollo.MutationResult<GatewayCreateWithPlacementMutation>;
export type GatewayCreateWithPlacementMutationOptions = Apollo.BaseMutationOptions<GatewayCreateWithPlacementMutation, GatewayCreateWithPlacementMutationVariables>;
export const DeviceDeleteDocument = gql`
    mutation DeviceDelete($deviceId: UUID!, $deleteChildDevices: Boolean) {
  deleteDevice(id: $deviceId, deleteChildDevices: $deleteChildDevices) {
    id
  }
}
    `;
export type DeviceDeleteMutationFn = Apollo.MutationFunction<DeviceDeleteMutation, DeviceDeleteMutationVariables>;

/**
 * __useDeviceDeleteMutation__
 *
 * To run a mutation, you first call `useDeviceDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceDeleteMutation, { data, loading, error }] = useDeviceDeleteMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      deleteChildDevices: // value for 'deleteChildDevices'
 *   },
 * });
 */
export function useDeviceDeleteMutation(baseOptions?: Apollo.MutationHookOptions<DeviceDeleteMutation, DeviceDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceDeleteMutation, DeviceDeleteMutationVariables>(DeviceDeleteDocument, options);
      }
export type DeviceDeleteMutationHookResult = ReturnType<typeof useDeviceDeleteMutation>;
export type DeviceDeleteMutationResult = Apollo.MutationResult<DeviceDeleteMutation>;
export type DeviceDeleteMutationOptions = Apollo.BaseMutationOptions<DeviceDeleteMutation, DeviceDeleteMutationVariables>;
export const DeploymentsGatewayDetailsDocument = gql`
    query DeploymentsGatewayDetails($gatewayId: UUID!) {
  device(id: $gatewayId) {
    id
    name
  }
  placementOfDevice(deviceId: $gatewayId) {
    buildingName
    siteName
  }
}
    `;

/**
 * __useDeploymentsGatewayDetailsQuery__
 *
 * To run a query within a React component, call `useDeploymentsGatewayDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentsGatewayDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentsGatewayDetailsQuery({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *   },
 * });
 */
export function useDeploymentsGatewayDetailsQuery(baseOptions: Apollo.QueryHookOptions<DeploymentsGatewayDetailsQuery, DeploymentsGatewayDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeploymentsGatewayDetailsQuery, DeploymentsGatewayDetailsQueryVariables>(DeploymentsGatewayDetailsDocument, options);
      }
export function useDeploymentsGatewayDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeploymentsGatewayDetailsQuery, DeploymentsGatewayDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeploymentsGatewayDetailsQuery, DeploymentsGatewayDetailsQueryVariables>(DeploymentsGatewayDetailsDocument, options);
        }
export type DeploymentsGatewayDetailsQueryHookResult = ReturnType<typeof useDeploymentsGatewayDetailsQuery>;
export type DeploymentsGatewayDetailsLazyQueryHookResult = ReturnType<typeof useDeploymentsGatewayDetailsLazyQuery>;
export type DeploymentsGatewayDetailsQueryResult = Apollo.QueryResult<DeploymentsGatewayDetailsQuery, DeploymentsGatewayDetailsQueryVariables>;
export const CapabilityDataDocument = gql`
    query CapabilityData($readFromUtc: DateTime, $limit: Int, $deviceId: UUID!, $id: UUID!) {
  sensorMeasurements(
    where: {readFromUtc: $readFromUtc, limit: $limit, deviceId: $deviceId, deviceModelCapabilityIds: [$id]}
  ) {
    items {
      deviceId
      deviceModelCapabilityId
      utcTimeMeasured
      valueString
    }
  }
}
    `;

/**
 * __useCapabilityDataQuery__
 *
 * To run a query within a React component, call `useCapabilityDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCapabilityDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCapabilityDataQuery({
 *   variables: {
 *      readFromUtc: // value for 'readFromUtc'
 *      limit: // value for 'limit'
 *      deviceId: // value for 'deviceId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCapabilityDataQuery(baseOptions: Apollo.QueryHookOptions<CapabilityDataQuery, CapabilityDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CapabilityDataQuery, CapabilityDataQueryVariables>(CapabilityDataDocument, options);
      }
export function useCapabilityDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CapabilityDataQuery, CapabilityDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CapabilityDataQuery, CapabilityDataQueryVariables>(CapabilityDataDocument, options);
        }
export type CapabilityDataQueryHookResult = ReturnType<typeof useCapabilityDataQuery>;
export type CapabilityDataLazyQueryHookResult = ReturnType<typeof useCapabilityDataLazyQuery>;
export type CapabilityDataQueryResult = Apollo.QueryResult<CapabilityDataQuery, CapabilityDataQueryVariables>;
export const DeviceDetailsDocument = gql`
    query DeviceDetails($deviceId: UUID!) {
  device(id: $deviceId) {
    id
    name
    isLocked
    deviceModelId
    description
    serialNo
    deviceIdentifier
    deviceModel {
      id
      name
      deviceModelCapabilities {
        id
        technicalMax
        technicalMin
        unit {
          id
          name
          unitSymbol
        }
        capability {
          id
          name
        }
      }
      deviceTypeId
      deviceType {
        id
        name
        isGateway
      }
      deviceModelAttributeSets {
        id
        name
        mappingAttributeDeviceModelAttributeSets {
          attribute {
            id
            name
            required
            dataType
          }
        }
      }
    }
    deviceAttributeValues {
      id: attributeId
      value
    }
    parentDeviceId
    parentDevice {
      id
      name
    }
  }
  placementOfDevice(deviceId: $deviceId) {
    zoneId
    zoneName
    buildingId
    buildingName
    locationCity
    locationCountry
    siteId
    siteName
    storeyId
    storeyName
  }
}
    `;

/**
 * __useDeviceDetailsQuery__
 *
 * To run a query within a React component, call `useDeviceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceDetailsQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeviceDetailsQuery(baseOptions: Apollo.QueryHookOptions<DeviceDetailsQuery, DeviceDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceDetailsQuery, DeviceDetailsQueryVariables>(DeviceDetailsDocument, options);
      }
export function useDeviceDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceDetailsQuery, DeviceDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceDetailsQuery, DeviceDetailsQueryVariables>(DeviceDetailsDocument, options);
        }
export type DeviceDetailsQueryHookResult = ReturnType<typeof useDeviceDetailsQuery>;
export type DeviceDetailsLazyQueryHookResult = ReturnType<typeof useDeviceDetailsLazyQuery>;
export type DeviceDetailsQueryResult = Apollo.QueryResult<DeviceDetailsQuery, DeviceDetailsQueryVariables>;
export const DeviceDetailsGatewayConfigDocument = gql`
    query DeviceDetailsGatewayConfig($deviceId: UUID!) {
  device(id: $deviceId) {
    id
    gatewayDeviceConnections {
      name
      user
      host
      port
      sharedAccessSignatureExpiry
    }
  }
}
    `;

/**
 * __useDeviceDetailsGatewayConfigQuery__
 *
 * To run a query within a React component, call `useDeviceDetailsGatewayConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceDetailsGatewayConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceDetailsGatewayConfigQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeviceDetailsGatewayConfigQuery(baseOptions: Apollo.QueryHookOptions<DeviceDetailsGatewayConfigQuery, DeviceDetailsGatewayConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceDetailsGatewayConfigQuery, DeviceDetailsGatewayConfigQueryVariables>(DeviceDetailsGatewayConfigDocument, options);
      }
export function useDeviceDetailsGatewayConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceDetailsGatewayConfigQuery, DeviceDetailsGatewayConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceDetailsGatewayConfigQuery, DeviceDetailsGatewayConfigQueryVariables>(DeviceDetailsGatewayConfigDocument, options);
        }
export type DeviceDetailsGatewayConfigQueryHookResult = ReturnType<typeof useDeviceDetailsGatewayConfigQuery>;
export type DeviceDetailsGatewayConfigLazyQueryHookResult = ReturnType<typeof useDeviceDetailsGatewayConfigLazyQuery>;
export type DeviceDetailsGatewayConfigQueryResult = Apollo.QueryResult<DeviceDetailsGatewayConfigQuery, DeviceDetailsGatewayConfigQueryVariables>;
export const RegenerateSharedAccessSignatureDocument = gql`
    mutation RegenerateSharedAccessSignature($deviceId: UUID!, $expirySeconds: Int!) {
  regenerateGatewayDeviceSharedAccessSignature(
    deviceId: $deviceId
    expirySeconds: $expirySeconds
  ) {
    sharedAccessSignature
    sharedAccessSignatureExpiry
  }
}
    `;
export type RegenerateSharedAccessSignatureMutationFn = Apollo.MutationFunction<RegenerateSharedAccessSignatureMutation, RegenerateSharedAccessSignatureMutationVariables>;

/**
 * __useRegenerateSharedAccessSignatureMutation__
 *
 * To run a mutation, you first call `useRegenerateSharedAccessSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateSharedAccessSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateSharedAccessSignatureMutation, { data, loading, error }] = useRegenerateSharedAccessSignatureMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      expirySeconds: // value for 'expirySeconds'
 *   },
 * });
 */
export function useRegenerateSharedAccessSignatureMutation(baseOptions?: Apollo.MutationHookOptions<RegenerateSharedAccessSignatureMutation, RegenerateSharedAccessSignatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegenerateSharedAccessSignatureMutation, RegenerateSharedAccessSignatureMutationVariables>(RegenerateSharedAccessSignatureDocument, options);
      }
export type RegenerateSharedAccessSignatureMutationHookResult = ReturnType<typeof useRegenerateSharedAccessSignatureMutation>;
export type RegenerateSharedAccessSignatureMutationResult = Apollo.MutationResult<RegenerateSharedAccessSignatureMutation>;
export type RegenerateSharedAccessSignatureMutationOptions = Apollo.BaseMutationOptions<RegenerateSharedAccessSignatureMutation, RegenerateSharedAccessSignatureMutationVariables>;
export const GatewayStatusDocument = gql`
    query GatewayStatus($deviceId: UUID!) {
  device(id: $deviceId) {
    id
    connectionState
    heartbeatState
    firstConnected
    lastConnected
  }
}
    `;

/**
 * __useGatewayStatusQuery__
 *
 * To run a query within a React component, call `useGatewayStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGatewayStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGatewayStatusQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useGatewayStatusQuery(baseOptions: Apollo.QueryHookOptions<GatewayStatusQuery, GatewayStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GatewayStatusQuery, GatewayStatusQueryVariables>(GatewayStatusDocument, options);
      }
export function useGatewayStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GatewayStatusQuery, GatewayStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GatewayStatusQuery, GatewayStatusQueryVariables>(GatewayStatusDocument, options);
        }
export type GatewayStatusQueryHookResult = ReturnType<typeof useGatewayStatusQuery>;
export type GatewayStatusLazyQueryHookResult = ReturnType<typeof useGatewayStatusLazyQuery>;
export type GatewayStatusQueryResult = Apollo.QueryResult<GatewayStatusQuery, GatewayStatusQueryVariables>;
export const GatewayNameDocument = gql`
    query GatewayName($deviceId: UUID!) {
  device(id: $deviceId) {
    id
    name
  }
}
    `;

/**
 * __useGatewayNameQuery__
 *
 * To run a query within a React component, call `useGatewayNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGatewayNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGatewayNameQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useGatewayNameQuery(baseOptions: Apollo.QueryHookOptions<GatewayNameQuery, GatewayNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GatewayNameQuery, GatewayNameQueryVariables>(GatewayNameDocument, options);
      }
export function useGatewayNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GatewayNameQuery, GatewayNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GatewayNameQuery, GatewayNameQueryVariables>(GatewayNameDocument, options);
        }
export type GatewayNameQueryHookResult = ReturnType<typeof useGatewayNameQuery>;
export type GatewayNameLazyQueryHookResult = ReturnType<typeof useGatewayNameLazyQuery>;
export type GatewayNameQueryResult = Apollo.QueryResult<GatewayNameQuery, GatewayNameQueryVariables>;
export const DeviceEditDetailsDocument = gql`
    query DeviceEditDetails($deviceId: UUID!) {
  device(id: $deviceId) {
    id
    name
    description
    deviceModelId
    parentDeviceId
    parentDevice {
      id
      name
    }
    serialNo
    deviceIdentifier
    deviceModel {
      id
      name
      deviceType {
        isGateway
      }
    }
  }
}
    `;

/**
 * __useDeviceEditDetailsQuery__
 *
 * To run a query within a React component, call `useDeviceEditDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceEditDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceEditDetailsQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeviceEditDetailsQuery(baseOptions: Apollo.QueryHookOptions<DeviceEditDetailsQuery, DeviceEditDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceEditDetailsQuery, DeviceEditDetailsQueryVariables>(DeviceEditDetailsDocument, options);
      }
export function useDeviceEditDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceEditDetailsQuery, DeviceEditDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceEditDetailsQuery, DeviceEditDetailsQueryVariables>(DeviceEditDetailsDocument, options);
        }
export type DeviceEditDetailsQueryHookResult = ReturnType<typeof useDeviceEditDetailsQuery>;
export type DeviceEditDetailsLazyQueryHookResult = ReturnType<typeof useDeviceEditDetailsLazyQuery>;
export type DeviceEditDetailsQueryResult = Apollo.QueryResult<DeviceEditDetailsQuery, DeviceEditDetailsQueryVariables>;
export const DeviceEditGatewayDevicesDocument = gql`
    query DeviceEditGatewayDevices {
  devices(where: {deviceModel: {deviceType: {isGateway: {eq: true}}}}) {
    items {
      id
      name
      deviceModel {
        deviceType {
          isGateway
        }
      }
    }
  }
}
    `;

/**
 * __useDeviceEditGatewayDevicesQuery__
 *
 * To run a query within a React component, call `useDeviceEditGatewayDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceEditGatewayDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceEditGatewayDevicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeviceEditGatewayDevicesQuery(baseOptions?: Apollo.QueryHookOptions<DeviceEditGatewayDevicesQuery, DeviceEditGatewayDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceEditGatewayDevicesQuery, DeviceEditGatewayDevicesQueryVariables>(DeviceEditGatewayDevicesDocument, options);
      }
export function useDeviceEditGatewayDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceEditGatewayDevicesQuery, DeviceEditGatewayDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceEditGatewayDevicesQuery, DeviceEditGatewayDevicesQueryVariables>(DeviceEditGatewayDevicesDocument, options);
        }
export type DeviceEditGatewayDevicesQueryHookResult = ReturnType<typeof useDeviceEditGatewayDevicesQuery>;
export type DeviceEditGatewayDevicesLazyQueryHookResult = ReturnType<typeof useDeviceEditGatewayDevicesLazyQuery>;
export type DeviceEditGatewayDevicesQueryResult = Apollo.QueryResult<DeviceEditGatewayDevicesQuery, DeviceEditGatewayDevicesQueryVariables>;
export const DeviceUpdateDocument = gql`
    mutation DeviceUpdate($device: DeviceUpdateType!) {
  updateDevice(device: $device) {
    id
  }
}
    `;
export type DeviceUpdateMutationFn = Apollo.MutationFunction<DeviceUpdateMutation, DeviceUpdateMutationVariables>;

/**
 * __useDeviceUpdateMutation__
 *
 * To run a mutation, you first call `useDeviceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceUpdateMutation, { data, loading, error }] = useDeviceUpdateMutation({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useDeviceUpdateMutation(baseOptions?: Apollo.MutationHookOptions<DeviceUpdateMutation, DeviceUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceUpdateMutation, DeviceUpdateMutationVariables>(DeviceUpdateDocument, options);
      }
export type DeviceUpdateMutationHookResult = ReturnType<typeof useDeviceUpdateMutation>;
export type DeviceUpdateMutationResult = Apollo.MutationResult<DeviceUpdateMutation>;
export type DeviceUpdateMutationOptions = Apollo.BaseMutationOptions<DeviceUpdateMutation, DeviceUpdateMutationVariables>;
export const DevicesListDocument = gql`
    query DevicesList($skip: Int!, $take: Int!, $where: DeviceFilterInput) {
  devices(skip: $skip, take: $take, where: $where, order: {name: ASC}) {
    items {
      name
      id
      isLocked
      deviceModelId
      deviceModel {
        id
        name
        deviceType {
          id
          name
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __useDevicesListQuery__
 *
 * To run a query within a React component, call `useDevicesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDevicesListQuery(baseOptions: Apollo.QueryHookOptions<DevicesListQuery, DevicesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DevicesListQuery, DevicesListQueryVariables>(DevicesListDocument, options);
      }
export function useDevicesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DevicesListQuery, DevicesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DevicesListQuery, DevicesListQueryVariables>(DevicesListDocument, options);
        }
export type DevicesListQueryHookResult = ReturnType<typeof useDevicesListQuery>;
export type DevicesListLazyQueryHookResult = ReturnType<typeof useDevicesListLazyQuery>;
export type DevicesListQueryResult = Apollo.QueryResult<DevicesListQuery, DevicesListQueryVariables>;
export const DeviceLocationDocument = gql`
    query DeviceLocation($deviceId: UUID!) {
  device(id: $deviceId) {
    id
    name
  }
  sites {
    items {
      id
      name
      buildings {
        id
        name
        siteId
        storeys {
          id
          name
          buildingId
          zones {
            id
            name
            storeyId
          }
        }
      }
    }
  }
  placementOfDevice(deviceId: $deviceId) {
    siteId
    siteName
    buildingId
    buildingName
    storeyId
    storeyName
    zoneId
    zoneName
  }
}
    `;

/**
 * __useDeviceLocationQuery__
 *
 * To run a query within a React component, call `useDeviceLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceLocationQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeviceLocationQuery(baseOptions: Apollo.QueryHookOptions<DeviceLocationQuery, DeviceLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceLocationQuery, DeviceLocationQueryVariables>(DeviceLocationDocument, options);
      }
export function useDeviceLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceLocationQuery, DeviceLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceLocationQuery, DeviceLocationQueryVariables>(DeviceLocationDocument, options);
        }
export type DeviceLocationQueryHookResult = ReturnType<typeof useDeviceLocationQuery>;
export type DeviceLocationLazyQueryHookResult = ReturnType<typeof useDeviceLocationLazyQuery>;
export type DeviceLocationQueryResult = Apollo.QueryResult<DeviceLocationQuery, DeviceLocationQueryVariables>;
export const DeviceLocationUpdateDocument = gql`
    mutation DeviceLocationUpdate($deviceId: UUID!, $locationId: UUID!, $locationType: PlacementKind!) {
  updatePlacementOfDevice(
    deviceId: $deviceId
    placementId: $locationId
    placementType: $locationType
  ) {
    id
  }
}
    `;
export type DeviceLocationUpdateMutationFn = Apollo.MutationFunction<DeviceLocationUpdateMutation, DeviceLocationUpdateMutationVariables>;

/**
 * __useDeviceLocationUpdateMutation__
 *
 * To run a mutation, you first call `useDeviceLocationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceLocationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceLocationUpdateMutation, { data, loading, error }] = useDeviceLocationUpdateMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      locationId: // value for 'locationId'
 *      locationType: // value for 'locationType'
 *   },
 * });
 */
export function useDeviceLocationUpdateMutation(baseOptions?: Apollo.MutationHookOptions<DeviceLocationUpdateMutation, DeviceLocationUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceLocationUpdateMutation, DeviceLocationUpdateMutationVariables>(DeviceLocationUpdateDocument, options);
      }
export type DeviceLocationUpdateMutationHookResult = ReturnType<typeof useDeviceLocationUpdateMutation>;
export type DeviceLocationUpdateMutationResult = Apollo.MutationResult<DeviceLocationUpdateMutation>;
export type DeviceLocationUpdateMutationOptions = Apollo.BaseMutationOptions<DeviceLocationUpdateMutation, DeviceLocationUpdateMutationVariables>;
export const MappingsCreateGatewayDetailsDocument = gql`
    query MappingsCreateGatewayDetails($gatewayId: UUID!) {
  device(id: $gatewayId) {
    id
    name
  }
  placementOfDevice(deviceId: $gatewayId) {
    mappingPlacementType
    buildingName
    buildingId
    siteName
    zoneId
  }
}
    `;

/**
 * __useMappingsCreateGatewayDetailsQuery__
 *
 * To run a query within a React component, call `useMappingsCreateGatewayDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMappingsCreateGatewayDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMappingsCreateGatewayDetailsQuery({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *   },
 * });
 */
export function useMappingsCreateGatewayDetailsQuery(baseOptions: Apollo.QueryHookOptions<MappingsCreateGatewayDetailsQuery, MappingsCreateGatewayDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MappingsCreateGatewayDetailsQuery, MappingsCreateGatewayDetailsQueryVariables>(MappingsCreateGatewayDetailsDocument, options);
      }
export function useMappingsCreateGatewayDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MappingsCreateGatewayDetailsQuery, MappingsCreateGatewayDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MappingsCreateGatewayDetailsQuery, MappingsCreateGatewayDetailsQueryVariables>(MappingsCreateGatewayDetailsDocument, options);
        }
export type MappingsCreateGatewayDetailsQueryHookResult = ReturnType<typeof useMappingsCreateGatewayDetailsQuery>;
export type MappingsCreateGatewayDetailsLazyQueryHookResult = ReturnType<typeof useMappingsCreateGatewayDetailsLazyQuery>;
export type MappingsCreateGatewayDetailsQueryResult = Apollo.QueryResult<MappingsCreateGatewayDetailsQuery, MappingsCreateGatewayDetailsQueryVariables>;
export const MappingsCreateDeviceModelsDocument = gql`
    query MappingsCreateDeviceModels($modelIds: [UUID!]) {
  deviceModels(where: {deviceModelId: {in: $modelIds}}) {
    items {
      id
      name
      deviceModelCapabilities {
        id
        fieldSelector
        technicalMax
        technicalMin
        capability {
          id
          name
          description
          dataType
        }
        unit {
          id
          name
          unitSymbol
          uris
        }
      }
    }
  }
}
    `;

/**
 * __useMappingsCreateDeviceModelsQuery__
 *
 * To run a query within a React component, call `useMappingsCreateDeviceModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMappingsCreateDeviceModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMappingsCreateDeviceModelsQuery({
 *   variables: {
 *      modelIds: // value for 'modelIds'
 *   },
 * });
 */
export function useMappingsCreateDeviceModelsQuery(baseOptions?: Apollo.QueryHookOptions<MappingsCreateDeviceModelsQuery, MappingsCreateDeviceModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MappingsCreateDeviceModelsQuery, MappingsCreateDeviceModelsQueryVariables>(MappingsCreateDeviceModelsDocument, options);
      }
export function useMappingsCreateDeviceModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MappingsCreateDeviceModelsQuery, MappingsCreateDeviceModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MappingsCreateDeviceModelsQuery, MappingsCreateDeviceModelsQueryVariables>(MappingsCreateDeviceModelsDocument, options);
        }
export type MappingsCreateDeviceModelsQueryHookResult = ReturnType<typeof useMappingsCreateDeviceModelsQuery>;
export type MappingsCreateDeviceModelsLazyQueryHookResult = ReturnType<typeof useMappingsCreateDeviceModelsLazyQuery>;
export type MappingsCreateDeviceModelsQueryResult = Apollo.QueryResult<MappingsCreateDeviceModelsQuery, MappingsCreateDeviceModelsQueryVariables>;
export const MappingsCreateDeviceByNameDocument = gql`
    query MappingsCreateDeviceByName($deviceNames: [String!]) {
  devices(where: {name: {in: $deviceNames}}) {
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useMappingsCreateDeviceByNameQuery__
 *
 * To run a query within a React component, call `useMappingsCreateDeviceByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useMappingsCreateDeviceByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMappingsCreateDeviceByNameQuery({
 *   variables: {
 *      deviceNames: // value for 'deviceNames'
 *   },
 * });
 */
export function useMappingsCreateDeviceByNameQuery(baseOptions?: Apollo.QueryHookOptions<MappingsCreateDeviceByNameQuery, MappingsCreateDeviceByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MappingsCreateDeviceByNameQuery, MappingsCreateDeviceByNameQueryVariables>(MappingsCreateDeviceByNameDocument, options);
      }
export function useMappingsCreateDeviceByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MappingsCreateDeviceByNameQuery, MappingsCreateDeviceByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MappingsCreateDeviceByNameQuery, MappingsCreateDeviceByNameQueryVariables>(MappingsCreateDeviceByNameDocument, options);
        }
export type MappingsCreateDeviceByNameQueryHookResult = ReturnType<typeof useMappingsCreateDeviceByNameQuery>;
export type MappingsCreateDeviceByNameLazyQueryHookResult = ReturnType<typeof useMappingsCreateDeviceByNameLazyQuery>;
export type MappingsCreateDeviceByNameQueryResult = Apollo.QueryResult<MappingsCreateDeviceByNameQuery, MappingsCreateDeviceByNameQueryVariables>;
export const MappingsEditGatewayDetailsDocument = gql`
    query MappingsEditGatewayDetails($gatewayId: UUID!) {
  device(id: $gatewayId) {
    id
    name
  }
  placementOfDevice(deviceId: $gatewayId) {
    mappingPlacementType
    buildingName
    buildingId
    siteName
    zoneId
  }
}
    `;

/**
 * __useMappingsEditGatewayDetailsQuery__
 *
 * To run a query within a React component, call `useMappingsEditGatewayDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMappingsEditGatewayDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMappingsEditGatewayDetailsQuery({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *   },
 * });
 */
export function useMappingsEditGatewayDetailsQuery(baseOptions: Apollo.QueryHookOptions<MappingsEditGatewayDetailsQuery, MappingsEditGatewayDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MappingsEditGatewayDetailsQuery, MappingsEditGatewayDetailsQueryVariables>(MappingsEditGatewayDetailsDocument, options);
      }
export function useMappingsEditGatewayDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MappingsEditGatewayDetailsQuery, MappingsEditGatewayDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MappingsEditGatewayDetailsQuery, MappingsEditGatewayDetailsQueryVariables>(MappingsEditGatewayDetailsDocument, options);
        }
export type MappingsEditGatewayDetailsQueryHookResult = ReturnType<typeof useMappingsEditGatewayDetailsQuery>;
export type MappingsEditGatewayDetailsLazyQueryHookResult = ReturnType<typeof useMappingsEditGatewayDetailsLazyQuery>;
export type MappingsEditGatewayDetailsQueryResult = Apollo.QueryResult<MappingsEditGatewayDetailsQuery, MappingsEditGatewayDetailsQueryVariables>;
export const MappingsEditAllDevicesDocument = gql`
    query MappingsEditAllDevices($gatewayId: UUID) {
  devices(
    where: {and: [{deviceModel: {deviceType: {isGateway: {eq: false}}}}, {parentDeviceId: {eq: $gatewayId}}]}
    order: {name: ASC}
  ) {
    items {
      id
      name
      deviceModelId
      deviceModel {
        id
        name
        deviceModelCapabilities {
          id
          technicalMax
          technicalMin
          capability {
            id
            name
            description
            dataType
          }
          unit {
            id
            name
            unitSymbol
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMappingsEditAllDevicesQuery__
 *
 * To run a query within a React component, call `useMappingsEditAllDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMappingsEditAllDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMappingsEditAllDevicesQuery({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *   },
 * });
 */
export function useMappingsEditAllDevicesQuery(baseOptions?: Apollo.QueryHookOptions<MappingsEditAllDevicesQuery, MappingsEditAllDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MappingsEditAllDevicesQuery, MappingsEditAllDevicesQueryVariables>(MappingsEditAllDevicesDocument, options);
      }
export function useMappingsEditAllDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MappingsEditAllDevicesQuery, MappingsEditAllDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MappingsEditAllDevicesQuery, MappingsEditAllDevicesQueryVariables>(MappingsEditAllDevicesDocument, options);
        }
export type MappingsEditAllDevicesQueryHookResult = ReturnType<typeof useMappingsEditAllDevicesQuery>;
export type MappingsEditAllDevicesLazyQueryHookResult = ReturnType<typeof useMappingsEditAllDevicesLazyQuery>;
export type MappingsEditAllDevicesQueryResult = Apollo.QueryResult<MappingsEditAllDevicesQuery, MappingsEditAllDevicesQueryVariables>;
export const MappingsEditDeviceCreateWithPlacementDocument = gql`
    mutation MappingsEditDeviceCreateWithPlacement($device: DeviceCreateInputType!, $placementId: UUID!, $placementType: PlacementKind!, $attributeValues: [DeviceCreateAttributeValueType!]!) {
  createDeviceWithPlacement(
    device: $device
    placementId: $placementId
    placementType: $placementType
    attributeValues: $attributeValues
  ) {
    id
    name
    deviceModelId
    deviceModel {
      id
      name
      deviceModelCapabilities {
        id
        technicalMax
        technicalMin
        capability {
          id
          name
          description
          dataType
        }
        unit {
          id
          name
          unitSymbol
        }
      }
    }
  }
}
    `;
export type MappingsEditDeviceCreateWithPlacementMutationFn = Apollo.MutationFunction<MappingsEditDeviceCreateWithPlacementMutation, MappingsEditDeviceCreateWithPlacementMutationVariables>;

/**
 * __useMappingsEditDeviceCreateWithPlacementMutation__
 *
 * To run a mutation, you first call `useMappingsEditDeviceCreateWithPlacementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMappingsEditDeviceCreateWithPlacementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mappingsEditDeviceCreateWithPlacementMutation, { data, loading, error }] = useMappingsEditDeviceCreateWithPlacementMutation({
 *   variables: {
 *      device: // value for 'device'
 *      placementId: // value for 'placementId'
 *      placementType: // value for 'placementType'
 *      attributeValues: // value for 'attributeValues'
 *   },
 * });
 */
export function useMappingsEditDeviceCreateWithPlacementMutation(baseOptions?: Apollo.MutationHookOptions<MappingsEditDeviceCreateWithPlacementMutation, MappingsEditDeviceCreateWithPlacementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MappingsEditDeviceCreateWithPlacementMutation, MappingsEditDeviceCreateWithPlacementMutationVariables>(MappingsEditDeviceCreateWithPlacementDocument, options);
      }
export type MappingsEditDeviceCreateWithPlacementMutationHookResult = ReturnType<typeof useMappingsEditDeviceCreateWithPlacementMutation>;
export type MappingsEditDeviceCreateWithPlacementMutationResult = Apollo.MutationResult<MappingsEditDeviceCreateWithPlacementMutation>;
export type MappingsEditDeviceCreateWithPlacementMutationOptions = Apollo.BaseMutationOptions<MappingsEditDeviceCreateWithPlacementMutation, MappingsEditDeviceCreateWithPlacementMutationVariables>;
export const DeviceDetailsForDataDocument = gql`
    query DeviceDetailsForData($deviceId: UUID!) {
  placementOfDevice(deviceId: $deviceId) {
    zoneId
    zoneName
    buildingId
    buildingName
    locationCity
    locationCountry
    siteId
    siteName
    storeyId
    storeyName
  }
  device(id: $deviceId) {
    id
    name
    deviceModelId
    deviceModel {
      id
      name
      deviceModelCapabilities {
        id
        technicalMax
        technicalMin
        unit {
          id
          name
          unitSymbol
        }
        capability {
          id
          name
        }
      }
    }
    parentDevice {
      id
      name
    }
    inverseParentDevice {
      name
      id
    }
    serialNo
  }
}
    `;

/**
 * __useDeviceDetailsForDataQuery__
 *
 * To run a query within a React component, call `useDeviceDetailsForDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceDetailsForDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceDetailsForDataQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeviceDetailsForDataQuery(baseOptions: Apollo.QueryHookOptions<DeviceDetailsForDataQuery, DeviceDetailsForDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceDetailsForDataQuery, DeviceDetailsForDataQueryVariables>(DeviceDetailsForDataDocument, options);
      }
export function useDeviceDetailsForDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceDetailsForDataQuery, DeviceDetailsForDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceDetailsForDataQuery, DeviceDetailsForDataQueryVariables>(DeviceDetailsForDataDocument, options);
        }
export type DeviceDetailsForDataQueryHookResult = ReturnType<typeof useDeviceDetailsForDataQuery>;
export type DeviceDetailsForDataLazyQueryHookResult = ReturnType<typeof useDeviceDetailsForDataLazyQuery>;
export type DeviceDetailsForDataQueryResult = Apollo.QueryResult<DeviceDetailsForDataQuery, DeviceDetailsForDataQueryVariables>;
export const HistoricalDataDocument = gql`
    query HistoricalData($readFromUtc: DateTime, $limit: Int, $deviceId: UUID!, $deviceModelCapabilityIds: [UUID!]!) {
  sensorMeasurements(
    where: {readFromUtc: $readFromUtc, limit: $limit, deviceId: $deviceId, deviceModelCapabilityIds: $deviceModelCapabilityIds}
  ) {
    items {
      deviceId
      deviceModelCapabilityId
      utcTimeMeasured
      valueString
    }
  }
}
    `;

/**
 * __useHistoricalDataQuery__
 *
 * To run a query within a React component, call `useHistoricalDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricalDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricalDataQuery({
 *   variables: {
 *      readFromUtc: // value for 'readFromUtc'
 *      limit: // value for 'limit'
 *      deviceId: // value for 'deviceId'
 *      deviceModelCapabilityIds: // value for 'deviceModelCapabilityIds'
 *   },
 * });
 */
export function useHistoricalDataQuery(baseOptions: Apollo.QueryHookOptions<HistoricalDataQuery, HistoricalDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HistoricalDataQuery, HistoricalDataQueryVariables>(HistoricalDataDocument, options);
      }
export function useHistoricalDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HistoricalDataQuery, HistoricalDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HistoricalDataQuery, HistoricalDataQueryVariables>(HistoricalDataDocument, options);
        }
export type HistoricalDataQueryHookResult = ReturnType<typeof useHistoricalDataQuery>;
export type HistoricalDataLazyQueryHookResult = ReturnType<typeof useHistoricalDataLazyQuery>;
export type HistoricalDataQueryResult = Apollo.QueryResult<HistoricalDataQuery, HistoricalDataQueryVariables>;
export const SiteCreateDocument = gql`
    mutation SiteCreate($site: SiteCreateType!) {
  createSite(site: $site) {
    id
  }
}
    `;
export type SiteCreateMutationFn = Apollo.MutationFunction<SiteCreateMutation, SiteCreateMutationVariables>;

/**
 * __useSiteCreateMutation__
 *
 * To run a mutation, you first call `useSiteCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteCreateMutation, { data, loading, error }] = useSiteCreateMutation({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useSiteCreateMutation(baseOptions?: Apollo.MutationHookOptions<SiteCreateMutation, SiteCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SiteCreateMutation, SiteCreateMutationVariables>(SiteCreateDocument, options);
      }
export type SiteCreateMutationHookResult = ReturnType<typeof useSiteCreateMutation>;
export type SiteCreateMutationResult = Apollo.MutationResult<SiteCreateMutation>;
export type SiteCreateMutationOptions = Apollo.BaseMutationOptions<SiteCreateMutation, SiteCreateMutationVariables>;
export const SiteCreateBuildingDocument = gql`
    mutation SiteCreateBuilding($building: BuildingCreateType!) {
  createBuilding(building: $building) {
    id
  }
}
    `;
export type SiteCreateBuildingMutationFn = Apollo.MutationFunction<SiteCreateBuildingMutation, SiteCreateBuildingMutationVariables>;

/**
 * __useSiteCreateBuildingMutation__
 *
 * To run a mutation, you first call `useSiteCreateBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteCreateBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteCreateBuildingMutation, { data, loading, error }] = useSiteCreateBuildingMutation({
 *   variables: {
 *      building: // value for 'building'
 *   },
 * });
 */
export function useSiteCreateBuildingMutation(baseOptions?: Apollo.MutationHookOptions<SiteCreateBuildingMutation, SiteCreateBuildingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SiteCreateBuildingMutation, SiteCreateBuildingMutationVariables>(SiteCreateBuildingDocument, options);
      }
export type SiteCreateBuildingMutationHookResult = ReturnType<typeof useSiteCreateBuildingMutation>;
export type SiteCreateBuildingMutationResult = Apollo.MutationResult<SiteCreateBuildingMutation>;
export type SiteCreateBuildingMutationOptions = Apollo.BaseMutationOptions<SiteCreateBuildingMutation, SiteCreateBuildingMutationVariables>;
export const SiteCreateLocationCreateDocument = gql`
    mutation SiteCreateLocationCreate($locationInput: LocationCreateType!) {
  createLocation(location: $locationInput) {
    id
  }
}
    `;
export type SiteCreateLocationCreateMutationFn = Apollo.MutationFunction<SiteCreateLocationCreateMutation, SiteCreateLocationCreateMutationVariables>;

/**
 * __useSiteCreateLocationCreateMutation__
 *
 * To run a mutation, you first call `useSiteCreateLocationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteCreateLocationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteCreateLocationCreateMutation, { data, loading, error }] = useSiteCreateLocationCreateMutation({
 *   variables: {
 *      locationInput: // value for 'locationInput'
 *   },
 * });
 */
export function useSiteCreateLocationCreateMutation(baseOptions?: Apollo.MutationHookOptions<SiteCreateLocationCreateMutation, SiteCreateLocationCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SiteCreateLocationCreateMutation, SiteCreateLocationCreateMutationVariables>(SiteCreateLocationCreateDocument, options);
      }
export type SiteCreateLocationCreateMutationHookResult = ReturnType<typeof useSiteCreateLocationCreateMutation>;
export type SiteCreateLocationCreateMutationResult = Apollo.MutationResult<SiteCreateLocationCreateMutation>;
export type SiteCreateLocationCreateMutationOptions = Apollo.BaseMutationOptions<SiteCreateLocationCreateMutation, SiteCreateLocationCreateMutationVariables>;
export const SiteDeleteDocument = gql`
    mutation SiteDelete($siteId: UUID!) {
  deleteSite(id: $siteId) {
    id
  }
}
    `;
export type SiteDeleteMutationFn = Apollo.MutationFunction<SiteDeleteMutation, SiteDeleteMutationVariables>;

/**
 * __useSiteDeleteMutation__
 *
 * To run a mutation, you first call `useSiteDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteDeleteMutation, { data, loading, error }] = useSiteDeleteMutation({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useSiteDeleteMutation(baseOptions?: Apollo.MutationHookOptions<SiteDeleteMutation, SiteDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SiteDeleteMutation, SiteDeleteMutationVariables>(SiteDeleteDocument, options);
      }
export type SiteDeleteMutationHookResult = ReturnType<typeof useSiteDeleteMutation>;
export type SiteDeleteMutationResult = Apollo.MutationResult<SiteDeleteMutation>;
export type SiteDeleteMutationOptions = Apollo.BaseMutationOptions<SiteDeleteMutation, SiteDeleteMutationVariables>;
export const SiteDetailsDocument = gql`
    query SiteDetails($siteId: UUID!) {
  site(id: $siteId) {
    id
    name
    description
    image {
      name
      url
    }
    locationId
    location {
      id
      street
      no
      zip
      city
      county
      country
    }
  }
}
    `;

/**
 * __useSiteDetailsQuery__
 *
 * To run a query within a React component, call `useSiteDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteDetailsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useSiteDetailsQuery(baseOptions: Apollo.QueryHookOptions<SiteDetailsQuery, SiteDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteDetailsQuery, SiteDetailsQueryVariables>(SiteDetailsDocument, options);
      }
export function useSiteDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteDetailsQuery, SiteDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteDetailsQuery, SiteDetailsQueryVariables>(SiteDetailsDocument, options);
        }
export type SiteDetailsQueryHookResult = ReturnType<typeof useSiteDetailsQuery>;
export type SiteDetailsLazyQueryHookResult = ReturnType<typeof useSiteDetailsLazyQuery>;
export type SiteDetailsQueryResult = Apollo.QueryResult<SiteDetailsQuery, SiteDetailsQueryVariables>;
export const SiteDetailsBuildingsDocument = gql`
    query SiteDetailsBuildings($skip: Int, $take: Int, $where: BuildingFilterInput) {
  buildings(skip: $skip, take: $take, where: $where) {
    items {
      id
      name
      dimension
      description
      storeys {
        id
        name
      }
      locationId
      location {
        id
        street
        no
        zip
        city
        county
        country
      }
      siteId
      site {
        id
        name
      }
    }
    totalCount
  }
}
    `;

/**
 * __useSiteDetailsBuildingsQuery__
 *
 * To run a query within a React component, call `useSiteDetailsBuildingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteDetailsBuildingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteDetailsBuildingsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSiteDetailsBuildingsQuery(baseOptions?: Apollo.QueryHookOptions<SiteDetailsBuildingsQuery, SiteDetailsBuildingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteDetailsBuildingsQuery, SiteDetailsBuildingsQueryVariables>(SiteDetailsBuildingsDocument, options);
      }
export function useSiteDetailsBuildingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteDetailsBuildingsQuery, SiteDetailsBuildingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteDetailsBuildingsQuery, SiteDetailsBuildingsQueryVariables>(SiteDetailsBuildingsDocument, options);
        }
export type SiteDetailsBuildingsQueryHookResult = ReturnType<typeof useSiteDetailsBuildingsQuery>;
export type SiteDetailsBuildingsLazyQueryHookResult = ReturnType<typeof useSiteDetailsBuildingsLazyQuery>;
export type SiteDetailsBuildingsQueryResult = Apollo.QueryResult<SiteDetailsBuildingsQuery, SiteDetailsBuildingsQueryVariables>;
export const SiteEditDetailsDocument = gql`
    query SiteEditDetails($siteId: UUID!) {
  site(id: $siteId) {
    id
    name
    description
  }
}
    `;

/**
 * __useSiteEditDetailsQuery__
 *
 * To run a query within a React component, call `useSiteEditDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteEditDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteEditDetailsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useSiteEditDetailsQuery(baseOptions: Apollo.QueryHookOptions<SiteEditDetailsQuery, SiteEditDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteEditDetailsQuery, SiteEditDetailsQueryVariables>(SiteEditDetailsDocument, options);
      }
export function useSiteEditDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteEditDetailsQuery, SiteEditDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteEditDetailsQuery, SiteEditDetailsQueryVariables>(SiteEditDetailsDocument, options);
        }
export type SiteEditDetailsQueryHookResult = ReturnType<typeof useSiteEditDetailsQuery>;
export type SiteEditDetailsLazyQueryHookResult = ReturnType<typeof useSiteEditDetailsLazyQuery>;
export type SiteEditDetailsQueryResult = Apollo.QueryResult<SiteEditDetailsQuery, SiteEditDetailsQueryVariables>;
export const SiteEditUpdateDocument = gql`
    mutation SiteEditUpdate($site: SiteUpdateType!) {
  updateSite(site: $site) {
    id
  }
}
    `;
export type SiteEditUpdateMutationFn = Apollo.MutationFunction<SiteEditUpdateMutation, SiteEditUpdateMutationVariables>;

/**
 * __useSiteEditUpdateMutation__
 *
 * To run a mutation, you first call `useSiteEditUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteEditUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteEditUpdateMutation, { data, loading, error }] = useSiteEditUpdateMutation({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useSiteEditUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SiteEditUpdateMutation, SiteEditUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SiteEditUpdateMutation, SiteEditUpdateMutationVariables>(SiteEditUpdateDocument, options);
      }
export type SiteEditUpdateMutationHookResult = ReturnType<typeof useSiteEditUpdateMutation>;
export type SiteEditUpdateMutationResult = Apollo.MutationResult<SiteEditUpdateMutation>;
export type SiteEditUpdateMutationOptions = Apollo.BaseMutationOptions<SiteEditUpdateMutation, SiteEditUpdateMutationVariables>;
export const SiteListDocument = gql`
    query SiteList($skip: Int!, $take: Int!, $where: SiteFilterInput) {
  sites(skip: $skip, take: $take, where: $where, order: {name: ASC}) {
    items {
      id
      name
      locationId
      location {
        id
        street
        no
        zip
        city
        county
        country
      }
    }
    totalCount
  }
}
    `;

/**
 * __useSiteListQuery__
 *
 * To run a query within a React component, call `useSiteListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSiteListQuery(baseOptions: Apollo.QueryHookOptions<SiteListQuery, SiteListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteListQuery, SiteListQueryVariables>(SiteListDocument, options);
      }
export function useSiteListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteListQuery, SiteListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteListQuery, SiteListQueryVariables>(SiteListDocument, options);
        }
export type SiteListQueryHookResult = ReturnType<typeof useSiteListQuery>;
export type SiteListLazyQueryHookResult = ReturnType<typeof useSiteListLazyQuery>;
export type SiteListQueryResult = Apollo.QueryResult<SiteListQuery, SiteListQueryVariables>;
export const SiteLocationDetailsDocument = gql`
    query SiteLocationDetails($siteId: UUID!) {
  site(id: $siteId) {
    id
    name
    locationId
    location {
      id
      street
      no
      zip
      city
      county
      country
    }
  }
}
    `;

/**
 * __useSiteLocationDetailsQuery__
 *
 * To run a query within a React component, call `useSiteLocationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteLocationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteLocationDetailsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useSiteLocationDetailsQuery(baseOptions: Apollo.QueryHookOptions<SiteLocationDetailsQuery, SiteLocationDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteLocationDetailsQuery, SiteLocationDetailsQueryVariables>(SiteLocationDetailsDocument, options);
      }
export function useSiteLocationDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteLocationDetailsQuery, SiteLocationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteLocationDetailsQuery, SiteLocationDetailsQueryVariables>(SiteLocationDetailsDocument, options);
        }
export type SiteLocationDetailsQueryHookResult = ReturnType<typeof useSiteLocationDetailsQuery>;
export type SiteLocationDetailsLazyQueryHookResult = ReturnType<typeof useSiteLocationDetailsLazyQuery>;
export type SiteLocationDetailsQueryResult = Apollo.QueryResult<SiteLocationDetailsQuery, SiteLocationDetailsQueryVariables>;
export const SiteLocationSiteUpdateDocument = gql`
    mutation SiteLocationSiteUpdate($site: SiteUpdateType!) {
  updateSite(site: $site) {
    id
  }
}
    `;
export type SiteLocationSiteUpdateMutationFn = Apollo.MutationFunction<SiteLocationSiteUpdateMutation, SiteLocationSiteUpdateMutationVariables>;

/**
 * __useSiteLocationSiteUpdateMutation__
 *
 * To run a mutation, you first call `useSiteLocationSiteUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteLocationSiteUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteLocationSiteUpdateMutation, { data, loading, error }] = useSiteLocationSiteUpdateMutation({
 *   variables: {
 *      site: // value for 'site'
 *   },
 * });
 */
export function useSiteLocationSiteUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SiteLocationSiteUpdateMutation, SiteLocationSiteUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SiteLocationSiteUpdateMutation, SiteLocationSiteUpdateMutationVariables>(SiteLocationSiteUpdateDocument, options);
      }
export type SiteLocationSiteUpdateMutationHookResult = ReturnType<typeof useSiteLocationSiteUpdateMutation>;
export type SiteLocationSiteUpdateMutationResult = Apollo.MutationResult<SiteLocationSiteUpdateMutation>;
export type SiteLocationSiteUpdateMutationOptions = Apollo.BaseMutationOptions<SiteLocationSiteUpdateMutation, SiteLocationSiteUpdateMutationVariables>;
export const SiteLocationCreateDocument = gql`
    mutation SiteLocationCreate($location: LocationCreateType!) {
  createLocation(location: $location) {
    id
  }
}
    `;
export type SiteLocationCreateMutationFn = Apollo.MutationFunction<SiteLocationCreateMutation, SiteLocationCreateMutationVariables>;

/**
 * __useSiteLocationCreateMutation__
 *
 * To run a mutation, you first call `useSiteLocationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteLocationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteLocationCreateMutation, { data, loading, error }] = useSiteLocationCreateMutation({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useSiteLocationCreateMutation(baseOptions?: Apollo.MutationHookOptions<SiteLocationCreateMutation, SiteLocationCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SiteLocationCreateMutation, SiteLocationCreateMutationVariables>(SiteLocationCreateDocument, options);
      }
export type SiteLocationCreateMutationHookResult = ReturnType<typeof useSiteLocationCreateMutation>;
export type SiteLocationCreateMutationResult = Apollo.MutationResult<SiteLocationCreateMutation>;
export type SiteLocationCreateMutationOptions = Apollo.BaseMutationOptions<SiteLocationCreateMutation, SiteLocationCreateMutationVariables>;
export const SiteLocationUpdateDocument = gql`
    mutation SiteLocationUpdate($location: LocationUpdateType!) {
  updateLocation(location: $location) {
    id
  }
}
    `;
export type SiteLocationUpdateMutationFn = Apollo.MutationFunction<SiteLocationUpdateMutation, SiteLocationUpdateMutationVariables>;

/**
 * __useSiteLocationUpdateMutation__
 *
 * To run a mutation, you first call `useSiteLocationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteLocationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteLocationUpdateMutation, { data, loading, error }] = useSiteLocationUpdateMutation({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useSiteLocationUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SiteLocationUpdateMutation, SiteLocationUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SiteLocationUpdateMutation, SiteLocationUpdateMutationVariables>(SiteLocationUpdateDocument, options);
      }
export type SiteLocationUpdateMutationHookResult = ReturnType<typeof useSiteLocationUpdateMutation>;
export type SiteLocationUpdateMutationResult = Apollo.MutationResult<SiteLocationUpdateMutation>;
export type SiteLocationUpdateMutationOptions = Apollo.BaseMutationOptions<SiteLocationUpdateMutation, SiteLocationUpdateMutationVariables>;
export const DeviceDataDocument = gql`
    query DeviceData($deviceId: UUID!, $deviceModelCapabilityIds: [UUID!]!) {
  latestSensorData(
    deviceId: $deviceId
    deviceModelCapabilityIds: $deviceModelCapabilityIds
    limitPerCapability: 1
  ) {
    deviceId
    deviceModelCapabilityId
    utcTimeMeasured
    valueString
  }
}
    `;

/**
 * __useDeviceDataQuery__
 *
 * To run a query within a React component, call `useDeviceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceDataQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      deviceModelCapabilityIds: // value for 'deviceModelCapabilityIds'
 *   },
 * });
 */
export function useDeviceDataQuery(baseOptions: Apollo.QueryHookOptions<DeviceDataQuery, DeviceDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceDataQuery, DeviceDataQueryVariables>(DeviceDataDocument, options);
      }
export function useDeviceDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceDataQuery, DeviceDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceDataQuery, DeviceDataQueryVariables>(DeviceDataDocument, options);
        }
export type DeviceDataQueryHookResult = ReturnType<typeof useDeviceDataQuery>;
export type DeviceDataLazyQueryHookResult = ReturnType<typeof useDeviceDataLazyQuery>;
export type DeviceDataQueryResult = Apollo.QueryResult<DeviceDataQuery, DeviceDataQueryVariables>;
export const StatusGatewayDetailsDocument = gql`
    query StatusGatewayDetails($gatewayId: UUID!) {
  device(id: $gatewayId) {
    id
    name
    inverseParentDevice {
      id
      name
      description
      deviceModel {
        id
        name
        deviceModelCapabilities {
          id
          capability {
            id
            name
          }
          unit {
            id
            name
            unitSymbol
          }
        }
      }
    }
  }
}
    `;

/**
 * __useStatusGatewayDetailsQuery__
 *
 * To run a query within a React component, call `useStatusGatewayDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatusGatewayDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatusGatewayDetailsQuery({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *   },
 * });
 */
export function useStatusGatewayDetailsQuery(baseOptions: Apollo.QueryHookOptions<StatusGatewayDetailsQuery, StatusGatewayDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatusGatewayDetailsQuery, StatusGatewayDetailsQueryVariables>(StatusGatewayDetailsDocument, options);
      }
export function useStatusGatewayDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatusGatewayDetailsQuery, StatusGatewayDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatusGatewayDetailsQuery, StatusGatewayDetailsQueryVariables>(StatusGatewayDetailsDocument, options);
        }
export type StatusGatewayDetailsQueryHookResult = ReturnType<typeof useStatusGatewayDetailsQuery>;
export type StatusGatewayDetailsLazyQueryHookResult = ReturnType<typeof useStatusGatewayDetailsLazyQuery>;
export type StatusGatewayDetailsQueryResult = Apollo.QueryResult<StatusGatewayDetailsQuery, StatusGatewayDetailsQueryVariables>;
export const StoreyCreateParentBuildingDocument = gql`
    query StoreyCreateParentBuilding($buildingId: UUID!) {
  building(id: $buildingId) {
    id
    name
    site {
      id
      name
    }
  }
}
    `;

/**
 * __useStoreyCreateParentBuildingQuery__
 *
 * To run a query within a React component, call `useStoreyCreateParentBuildingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreyCreateParentBuildingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreyCreateParentBuildingQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useStoreyCreateParentBuildingQuery(baseOptions: Apollo.QueryHookOptions<StoreyCreateParentBuildingQuery, StoreyCreateParentBuildingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoreyCreateParentBuildingQuery, StoreyCreateParentBuildingQueryVariables>(StoreyCreateParentBuildingDocument, options);
      }
export function useStoreyCreateParentBuildingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoreyCreateParentBuildingQuery, StoreyCreateParentBuildingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoreyCreateParentBuildingQuery, StoreyCreateParentBuildingQueryVariables>(StoreyCreateParentBuildingDocument, options);
        }
export type StoreyCreateParentBuildingQueryHookResult = ReturnType<typeof useStoreyCreateParentBuildingQuery>;
export type StoreyCreateParentBuildingLazyQueryHookResult = ReturnType<typeof useStoreyCreateParentBuildingLazyQuery>;
export type StoreyCreateParentBuildingQueryResult = Apollo.QueryResult<StoreyCreateParentBuildingQuery, StoreyCreateParentBuildingQueryVariables>;
export const StoreyCreateDocument = gql`
    mutation StoreyCreate($storey: StoreyCreateType!) {
  createStorey(storey: $storey) {
    id
    name
    buildingId
  }
}
    `;
export type StoreyCreateMutationFn = Apollo.MutationFunction<StoreyCreateMutation, StoreyCreateMutationVariables>;

/**
 * __useStoreyCreateMutation__
 *
 * To run a mutation, you first call `useStoreyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeyCreateMutation, { data, loading, error }] = useStoreyCreateMutation({
 *   variables: {
 *      storey: // value for 'storey'
 *   },
 * });
 */
export function useStoreyCreateMutation(baseOptions?: Apollo.MutationHookOptions<StoreyCreateMutation, StoreyCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StoreyCreateMutation, StoreyCreateMutationVariables>(StoreyCreateDocument, options);
      }
export type StoreyCreateMutationHookResult = ReturnType<typeof useStoreyCreateMutation>;
export type StoreyCreateMutationResult = Apollo.MutationResult<StoreyCreateMutation>;
export type StoreyCreateMutationOptions = Apollo.BaseMutationOptions<StoreyCreateMutation, StoreyCreateMutationVariables>;
export const StoreyDeleteDocument = gql`
    mutation StoreyDelete($id: UUID!) {
  deleteStorey(id: $id) {
    id
  }
}
    `;
export type StoreyDeleteMutationFn = Apollo.MutationFunction<StoreyDeleteMutation, StoreyDeleteMutationVariables>;

/**
 * __useStoreyDeleteMutation__
 *
 * To run a mutation, you first call `useStoreyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeyDeleteMutation, { data, loading, error }] = useStoreyDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStoreyDeleteMutation(baseOptions?: Apollo.MutationHookOptions<StoreyDeleteMutation, StoreyDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StoreyDeleteMutation, StoreyDeleteMutationVariables>(StoreyDeleteDocument, options);
      }
export type StoreyDeleteMutationHookResult = ReturnType<typeof useStoreyDeleteMutation>;
export type StoreyDeleteMutationResult = Apollo.MutationResult<StoreyDeleteMutation>;
export type StoreyDeleteMutationOptions = Apollo.BaseMutationOptions<StoreyDeleteMutation, StoreyDeleteMutationVariables>;
export const StoreyDetailsDocument = gql`
    query StoreyDetails($storeyId: UUID!) {
  storey(id: $storeyId) {
    id
    name
    description
    image {
      name
      url
    }
    building {
      id
      name
      site {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useStoreyDetailsQuery__
 *
 * To run a query within a React component, call `useStoreyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreyDetailsQuery({
 *   variables: {
 *      storeyId: // value for 'storeyId'
 *   },
 * });
 */
export function useStoreyDetailsQuery(baseOptions: Apollo.QueryHookOptions<StoreyDetailsQuery, StoreyDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoreyDetailsQuery, StoreyDetailsQueryVariables>(StoreyDetailsDocument, options);
      }
export function useStoreyDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoreyDetailsQuery, StoreyDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoreyDetailsQuery, StoreyDetailsQueryVariables>(StoreyDetailsDocument, options);
        }
export type StoreyDetailsQueryHookResult = ReturnType<typeof useStoreyDetailsQuery>;
export type StoreyDetailsLazyQueryHookResult = ReturnType<typeof useStoreyDetailsLazyQuery>;
export type StoreyDetailsQueryResult = Apollo.QueryResult<StoreyDetailsQuery, StoreyDetailsQueryVariables>;
export const StoreyDetailsZonesDocument = gql`
    query StoreyDetailsZones($skip: Int, $take: Int, $where: ZoneFilterInput) {
  zones(skip: $skip, take: $take, where: $where) {
    items {
      id
      storeyId
      name
      parentZoneId
      dimension
    }
    totalCount
  }
}
    `;

/**
 * __useStoreyDetailsZonesQuery__
 *
 * To run a query within a React component, call `useStoreyDetailsZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreyDetailsZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreyDetailsZonesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStoreyDetailsZonesQuery(baseOptions?: Apollo.QueryHookOptions<StoreyDetailsZonesQuery, StoreyDetailsZonesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoreyDetailsZonesQuery, StoreyDetailsZonesQueryVariables>(StoreyDetailsZonesDocument, options);
      }
export function useStoreyDetailsZonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoreyDetailsZonesQuery, StoreyDetailsZonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoreyDetailsZonesQuery, StoreyDetailsZonesQueryVariables>(StoreyDetailsZonesDocument, options);
        }
export type StoreyDetailsZonesQueryHookResult = ReturnType<typeof useStoreyDetailsZonesQuery>;
export type StoreyDetailsZonesLazyQueryHookResult = ReturnType<typeof useStoreyDetailsZonesLazyQuery>;
export type StoreyDetailsZonesQueryResult = Apollo.QueryResult<StoreyDetailsZonesQuery, StoreyDetailsZonesQueryVariables>;
export const StoreyEditDocument = gql`
    query StoreyEdit($storeyId: UUID!) {
  storey(id: $storeyId) {
    id
    name
    description
    image {
      name
      url
    }
    building {
      id
      name
      site {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useStoreyEditQuery__
 *
 * To run a query within a React component, call `useStoreyEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreyEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreyEditQuery({
 *   variables: {
 *      storeyId: // value for 'storeyId'
 *   },
 * });
 */
export function useStoreyEditQuery(baseOptions: Apollo.QueryHookOptions<StoreyEditQuery, StoreyEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoreyEditQuery, StoreyEditQueryVariables>(StoreyEditDocument, options);
      }
export function useStoreyEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoreyEditQuery, StoreyEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoreyEditQuery, StoreyEditQueryVariables>(StoreyEditDocument, options);
        }
export type StoreyEditQueryHookResult = ReturnType<typeof useStoreyEditQuery>;
export type StoreyEditLazyQueryHookResult = ReturnType<typeof useStoreyEditLazyQuery>;
export type StoreyEditQueryResult = Apollo.QueryResult<StoreyEditQuery, StoreyEditQueryVariables>;
export const StoreyUpdateDocument = gql`
    mutation StoreyUpdate($storeyInput: StoreyUpdateType!) {
  updateStorey(storey: $storeyInput) {
    id
    name
    buildingId
  }
}
    `;
export type StoreyUpdateMutationFn = Apollo.MutationFunction<StoreyUpdateMutation, StoreyUpdateMutationVariables>;

/**
 * __useStoreyUpdateMutation__
 *
 * To run a mutation, you first call `useStoreyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeyUpdateMutation, { data, loading, error }] = useStoreyUpdateMutation({
 *   variables: {
 *      storeyInput: // value for 'storeyInput'
 *   },
 * });
 */
export function useStoreyUpdateMutation(baseOptions?: Apollo.MutationHookOptions<StoreyUpdateMutation, StoreyUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StoreyUpdateMutation, StoreyUpdateMutationVariables>(StoreyUpdateDocument, options);
      }
export type StoreyUpdateMutationHookResult = ReturnType<typeof useStoreyUpdateMutation>;
export type StoreyUpdateMutationResult = Apollo.MutationResult<StoreyUpdateMutation>;
export type StoreyUpdateMutationOptions = Apollo.BaseMutationOptions<StoreyUpdateMutation, StoreyUpdateMutationVariables>;
export const UnitCreateDocument = gql`
    mutation UnitCreate($unitInput: UnitCreateType!) {
  createUnit(unit: $unitInput) {
    id
  }
}
    `;
export type UnitCreateMutationFn = Apollo.MutationFunction<UnitCreateMutation, UnitCreateMutationVariables>;

/**
 * __useUnitCreateMutation__
 *
 * To run a mutation, you first call `useUnitCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnitCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unitCreateMutation, { data, loading, error }] = useUnitCreateMutation({
 *   variables: {
 *      unitInput: // value for 'unitInput'
 *   },
 * });
 */
export function useUnitCreateMutation(baseOptions?: Apollo.MutationHookOptions<UnitCreateMutation, UnitCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnitCreateMutation, UnitCreateMutationVariables>(UnitCreateDocument, options);
      }
export type UnitCreateMutationHookResult = ReturnType<typeof useUnitCreateMutation>;
export type UnitCreateMutationResult = Apollo.MutationResult<UnitCreateMutation>;
export type UnitCreateMutationOptions = Apollo.BaseMutationOptions<UnitCreateMutation, UnitCreateMutationVariables>;
export const UnitDeleteDocument = gql`
    mutation UnitDelete($unitId: UUID!) {
  deleteUnit(id: $unitId) {
    id
  }
}
    `;
export type UnitDeleteMutationFn = Apollo.MutationFunction<UnitDeleteMutation, UnitDeleteMutationVariables>;

/**
 * __useUnitDeleteMutation__
 *
 * To run a mutation, you first call `useUnitDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnitDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unitDeleteMutation, { data, loading, error }] = useUnitDeleteMutation({
 *   variables: {
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useUnitDeleteMutation(baseOptions?: Apollo.MutationHookOptions<UnitDeleteMutation, UnitDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnitDeleteMutation, UnitDeleteMutationVariables>(UnitDeleteDocument, options);
      }
export type UnitDeleteMutationHookResult = ReturnType<typeof useUnitDeleteMutation>;
export type UnitDeleteMutationResult = Apollo.MutationResult<UnitDeleteMutation>;
export type UnitDeleteMutationOptions = Apollo.BaseMutationOptions<UnitDeleteMutation, UnitDeleteMutationVariables>;
export const UnitDetailsDocument = gql`
    query UnitDetails($unitId: UUID!) {
  unit(id: $unitId) {
    id
    name
    unitSymbol
    isGlobal
    uris
  }
}
    `;

/**
 * __useUnitDetailsQuery__
 *
 * To run a query within a React component, call `useUnitDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitDetailsQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useUnitDetailsQuery(baseOptions: Apollo.QueryHookOptions<UnitDetailsQuery, UnitDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnitDetailsQuery, UnitDetailsQueryVariables>(UnitDetailsDocument, options);
      }
export function useUnitDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnitDetailsQuery, UnitDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnitDetailsQuery, UnitDetailsQueryVariables>(UnitDetailsDocument, options);
        }
export type UnitDetailsQueryHookResult = ReturnType<typeof useUnitDetailsQuery>;
export type UnitDetailsLazyQueryHookResult = ReturnType<typeof useUnitDetailsLazyQuery>;
export type UnitDetailsQueryResult = Apollo.QueryResult<UnitDetailsQuery, UnitDetailsQueryVariables>;
export const UnitEditDetailsDocument = gql`
    query UnitEditDetails($unitId: UUID!) {
  unit(id: $unitId) {
    id
    name
    unitSymbol
    isGlobal
    uris
  }
}
    `;

/**
 * __useUnitEditDetailsQuery__
 *
 * To run a query within a React component, call `useUnitEditDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitEditDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitEditDetailsQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *   },
 * });
 */
export function useUnitEditDetailsQuery(baseOptions: Apollo.QueryHookOptions<UnitEditDetailsQuery, UnitEditDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnitEditDetailsQuery, UnitEditDetailsQueryVariables>(UnitEditDetailsDocument, options);
      }
export function useUnitEditDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnitEditDetailsQuery, UnitEditDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnitEditDetailsQuery, UnitEditDetailsQueryVariables>(UnitEditDetailsDocument, options);
        }
export type UnitEditDetailsQueryHookResult = ReturnType<typeof useUnitEditDetailsQuery>;
export type UnitEditDetailsLazyQueryHookResult = ReturnType<typeof useUnitEditDetailsLazyQuery>;
export type UnitEditDetailsQueryResult = Apollo.QueryResult<UnitEditDetailsQuery, UnitEditDetailsQueryVariables>;
export const UnitUpdateDocument = gql`
    mutation UnitUpdate($unitInput: UnitUpdateType!) {
  updateUnit(unit: $unitInput) {
    id
  }
}
    `;
export type UnitUpdateMutationFn = Apollo.MutationFunction<UnitUpdateMutation, UnitUpdateMutationVariables>;

/**
 * __useUnitUpdateMutation__
 *
 * To run a mutation, you first call `useUnitUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnitUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unitUpdateMutation, { data, loading, error }] = useUnitUpdateMutation({
 *   variables: {
 *      unitInput: // value for 'unitInput'
 *   },
 * });
 */
export function useUnitUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UnitUpdateMutation, UnitUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnitUpdateMutation, UnitUpdateMutationVariables>(UnitUpdateDocument, options);
      }
export type UnitUpdateMutationHookResult = ReturnType<typeof useUnitUpdateMutation>;
export type UnitUpdateMutationResult = Apollo.MutationResult<UnitUpdateMutation>;
export type UnitUpdateMutationOptions = Apollo.BaseMutationOptions<UnitUpdateMutation, UnitUpdateMutationVariables>;
export const UnitListDocument = gql`
    query UnitList($skip: Int!, $take: Int!, $where: UnitFilterInput) {
  units(skip: $skip, take: $take, where: $where, order: {name: ASC}) {
    items {
      id
      name
      unitSymbol
      isGlobal
      deviceModelCapabilities {
        id
      }
    }
    totalCount
  }
}
    `;

/**
 * __useUnitListQuery__
 *
 * To run a query within a React component, call `useUnitListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUnitListQuery(baseOptions: Apollo.QueryHookOptions<UnitListQuery, UnitListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnitListQuery, UnitListQueryVariables>(UnitListDocument, options);
      }
export function useUnitListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnitListQuery, UnitListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnitListQuery, UnitListQueryVariables>(UnitListDocument, options);
        }
export type UnitListQueryHookResult = ReturnType<typeof useUnitListQuery>;
export type UnitListLazyQueryHookResult = ReturnType<typeof useUnitListLazyQuery>;
export type UnitListQueryResult = Apollo.QueryResult<UnitListQuery, UnitListQueryVariables>;
export const ZoneCreateParentStoreyDocument = gql`
    query ZoneCreateParentStorey($storeyId: UUID!) {
  storey(id: $storeyId) {
    id
    name
    building {
      id
      name
      site {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useZoneCreateParentStoreyQuery__
 *
 * To run a query within a React component, call `useZoneCreateParentStoreyQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoneCreateParentStoreyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoneCreateParentStoreyQuery({
 *   variables: {
 *      storeyId: // value for 'storeyId'
 *   },
 * });
 */
export function useZoneCreateParentStoreyQuery(baseOptions: Apollo.QueryHookOptions<ZoneCreateParentStoreyQuery, ZoneCreateParentStoreyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ZoneCreateParentStoreyQuery, ZoneCreateParentStoreyQueryVariables>(ZoneCreateParentStoreyDocument, options);
      }
export function useZoneCreateParentStoreyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZoneCreateParentStoreyQuery, ZoneCreateParentStoreyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ZoneCreateParentStoreyQuery, ZoneCreateParentStoreyQueryVariables>(ZoneCreateParentStoreyDocument, options);
        }
export type ZoneCreateParentStoreyQueryHookResult = ReturnType<typeof useZoneCreateParentStoreyQuery>;
export type ZoneCreateParentStoreyLazyQueryHookResult = ReturnType<typeof useZoneCreateParentStoreyLazyQuery>;
export type ZoneCreateParentStoreyQueryResult = Apollo.QueryResult<ZoneCreateParentStoreyQuery, ZoneCreateParentStoreyQueryVariables>;
export const ZoneCreateDocument = gql`
    mutation ZoneCreate($zone: ZoneCreateType!) {
  createZone(zone: $zone) {
    id
    name
    storeyId
  }
}
    `;
export type ZoneCreateMutationFn = Apollo.MutationFunction<ZoneCreateMutation, ZoneCreateMutationVariables>;

/**
 * __useZoneCreateMutation__
 *
 * To run a mutation, you first call `useZoneCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZoneCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zoneCreateMutation, { data, loading, error }] = useZoneCreateMutation({
 *   variables: {
 *      zone: // value for 'zone'
 *   },
 * });
 */
export function useZoneCreateMutation(baseOptions?: Apollo.MutationHookOptions<ZoneCreateMutation, ZoneCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ZoneCreateMutation, ZoneCreateMutationVariables>(ZoneCreateDocument, options);
      }
export type ZoneCreateMutationHookResult = ReturnType<typeof useZoneCreateMutation>;
export type ZoneCreateMutationResult = Apollo.MutationResult<ZoneCreateMutation>;
export type ZoneCreateMutationOptions = Apollo.BaseMutationOptions<ZoneCreateMutation, ZoneCreateMutationVariables>;
export const ZoneDeleteDocument = gql`
    mutation ZoneDelete($zoneId: UUID!) {
  deleteZone(id: $zoneId) {
    id
  }
}
    `;
export type ZoneDeleteMutationFn = Apollo.MutationFunction<ZoneDeleteMutation, ZoneDeleteMutationVariables>;

/**
 * __useZoneDeleteMutation__
 *
 * To run a mutation, you first call `useZoneDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZoneDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zoneDeleteMutation, { data, loading, error }] = useZoneDeleteMutation({
 *   variables: {
 *      zoneId: // value for 'zoneId'
 *   },
 * });
 */
export function useZoneDeleteMutation(baseOptions?: Apollo.MutationHookOptions<ZoneDeleteMutation, ZoneDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ZoneDeleteMutation, ZoneDeleteMutationVariables>(ZoneDeleteDocument, options);
      }
export type ZoneDeleteMutationHookResult = ReturnType<typeof useZoneDeleteMutation>;
export type ZoneDeleteMutationResult = Apollo.MutationResult<ZoneDeleteMutation>;
export type ZoneDeleteMutationOptions = Apollo.BaseMutationOptions<ZoneDeleteMutation, ZoneDeleteMutationVariables>;
export const ZoneDetailsDocument = gql`
    query ZoneDetails($zoneId: UUID!) {
  zone(id: $zoneId) {
    id
    name
    image {
      url
      name
    }
    description
    storey {
      id
      name
      building {
        id
        name
        site {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useZoneDetailsQuery__
 *
 * To run a query within a React component, call `useZoneDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoneDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoneDetailsQuery({
 *   variables: {
 *      zoneId: // value for 'zoneId'
 *   },
 * });
 */
export function useZoneDetailsQuery(baseOptions: Apollo.QueryHookOptions<ZoneDetailsQuery, ZoneDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ZoneDetailsQuery, ZoneDetailsQueryVariables>(ZoneDetailsDocument, options);
      }
export function useZoneDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZoneDetailsQuery, ZoneDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ZoneDetailsQuery, ZoneDetailsQueryVariables>(ZoneDetailsDocument, options);
        }
export type ZoneDetailsQueryHookResult = ReturnType<typeof useZoneDetailsQuery>;
export type ZoneDetailsLazyQueryHookResult = ReturnType<typeof useZoneDetailsLazyQuery>;
export type ZoneDetailsQueryResult = Apollo.QueryResult<ZoneDetailsQuery, ZoneDetailsQueryVariables>;
export const ZoneDetailsDevicesDocument = gql`
    query ZoneDetailsDevices($skip: Int, $take: Int, $where: DevicesByBuildingFilterInput) {
  devicesByBuildings(skip: $skip, take: $take, where: $where) {
    items {
      device {
        id
        name
        deviceModel {
          name
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __useZoneDetailsDevicesQuery__
 *
 * To run a query within a React component, call `useZoneDetailsDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoneDetailsDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoneDetailsDevicesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useZoneDetailsDevicesQuery(baseOptions?: Apollo.QueryHookOptions<ZoneDetailsDevicesQuery, ZoneDetailsDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ZoneDetailsDevicesQuery, ZoneDetailsDevicesQueryVariables>(ZoneDetailsDevicesDocument, options);
      }
export function useZoneDetailsDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZoneDetailsDevicesQuery, ZoneDetailsDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ZoneDetailsDevicesQuery, ZoneDetailsDevicesQueryVariables>(ZoneDetailsDevicesDocument, options);
        }
export type ZoneDetailsDevicesQueryHookResult = ReturnType<typeof useZoneDetailsDevicesQuery>;
export type ZoneDetailsDevicesLazyQueryHookResult = ReturnType<typeof useZoneDetailsDevicesLazyQuery>;
export type ZoneDetailsDevicesQueryResult = Apollo.QueryResult<ZoneDetailsDevicesQuery, ZoneDetailsDevicesQueryVariables>;
export const ZoneEditDocument = gql`
    query ZoneEdit($zoneId: UUID!) {
  zone(id: $zoneId) {
    id
    name
    image {
      url
      name
    }
    description
    storey {
      id
      name
      building {
        id
        name
        site {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useZoneEditQuery__
 *
 * To run a query within a React component, call `useZoneEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoneEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoneEditQuery({
 *   variables: {
 *      zoneId: // value for 'zoneId'
 *   },
 * });
 */
export function useZoneEditQuery(baseOptions: Apollo.QueryHookOptions<ZoneEditQuery, ZoneEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ZoneEditQuery, ZoneEditQueryVariables>(ZoneEditDocument, options);
      }
export function useZoneEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZoneEditQuery, ZoneEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ZoneEditQuery, ZoneEditQueryVariables>(ZoneEditDocument, options);
        }
export type ZoneEditQueryHookResult = ReturnType<typeof useZoneEditQuery>;
export type ZoneEditLazyQueryHookResult = ReturnType<typeof useZoneEditLazyQuery>;
export type ZoneEditQueryResult = Apollo.QueryResult<ZoneEditQuery, ZoneEditQueryVariables>;
export const ZoneUpdateDocument = gql`
    mutation ZoneUpdate($zoneInput: ZoneUpdateType!) {
  updateZone(zone: $zoneInput) {
    id
    name
    storeyId
  }
}
    `;
export type ZoneUpdateMutationFn = Apollo.MutationFunction<ZoneUpdateMutation, ZoneUpdateMutationVariables>;

/**
 * __useZoneUpdateMutation__
 *
 * To run a mutation, you first call `useZoneUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZoneUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zoneUpdateMutation, { data, loading, error }] = useZoneUpdateMutation({
 *   variables: {
 *      zoneInput: // value for 'zoneInput'
 *   },
 * });
 */
export function useZoneUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ZoneUpdateMutation, ZoneUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ZoneUpdateMutation, ZoneUpdateMutationVariables>(ZoneUpdateDocument, options);
      }
export type ZoneUpdateMutationHookResult = ReturnType<typeof useZoneUpdateMutation>;
export type ZoneUpdateMutationResult = Apollo.MutationResult<ZoneUpdateMutation>;
export type ZoneUpdateMutationOptions = Apollo.BaseMutationOptions<ZoneUpdateMutation, ZoneUpdateMutationVariables>;