import React from 'react';
import { Card, Container, Divider, Grid, Typography } from '@mui/material';
import { createClasses, LogoKP } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import { FancyCard } from '../../../components/FancyCard';
import { BUILDING_MASTER_APP_URI } from '../../../utils/env';
import { Color } from '../../../constants/Colors';

const classes = createClasses({
  root: {
    textAlign: 'left',
  },
  content: {
    padding: '24px 16px 8px 16px',
  },
  subtitle: {
    margin: '8px 0 24px 0',
  },
  detailsWrapper: {
    display: 'flex',
    height: '100%',
  },
  detailsSeparator: {
    height: '100%',
    marginRight: '24px',
    marginLeft: '16px',
  },
  detailsContent: {
    flexGrow: 1,
  },
  links: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  link: {
    width: '170px',
    height: '170px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '2px 2px 3px rgba(0,0,0,.1)',
    },
  },
  linkIcon: {
    width: '70px',
    height: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: Color.link,
    '& svg': {
      width: '70px',
      height: '70px',
    },
  },
  linkText: {
    marginTop: '20px',
    textAlign: 'center',
  },
  lastDivider: {
    margin: '16px 0 16px 0',
  },
  footer: {
    padding: '8px 16px 28px 16px',
    textAlign: 'right',
  },
  footerLink: {
    color: Color.link,
    fontWeight: 600,
  },
  linkWrapper: {
    textDecoration: 'none',
  },
  copyright: {
    marginTop: '15px',
    textAlign: 'center',
    color: Color.copyright,
  },
  logo: {
    display: 'flex',
    marginBottom: '15px',
    justifyContent: 'center',
  },
});

interface Application {
  title: string;
  link: string;
  icon: React.ElementType;
}

const APPLICATIONS: Application[] = [
  {
    title: 'Building Master App',
    link: BUILDING_MASTER_APP_URI,
    icon: Icons.LocationCity,
  },
];

interface AuthLandingProps {
  fromAcceptInvitation: boolean;
}

export const AuthLanding: React.FC<AuthLandingProps> = ({
  fromAcceptInvitation,
}) => {
  const { t } = useTranslation(['auth', 'general']);

  return (
    <Container maxWidth="sm">
      <div className={classes.root}>
        <div className={classes.logo}>
          <LogoKP background="dark" />
        </div>
        <FancyCard title={t('auth:landing.title')}>
          <div className={classes.content}>
            <Grid container spacing={1} direction="row">
              <Grid item xs={12} md={4}>
                <div className={classes.links}>
                  {APPLICATIONS.map(({ title, link, icon: Icon }) => (
                    <a href={link} className={classes.linkWrapper} key={link}>
                      <Card
                        elevation={0}
                        className={classes.link}
                        variant="outlined"
                      >
                        <div className={classes.linkIcon}>
                          <Icon />
                        </div>
                        <Typography
                          variant="subtitle2"
                          className={classes.linkText}
                        >
                          {title}
                        </Typography>
                      </Card>
                    </a>
                  ))}
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <div className={classes.detailsWrapper}>
                  <div className={classes.detailsSeparator}>
                    <Divider orientation="vertical" />
                  </div>
                  <div className={classes.detailsContent}>
                    <Typography
                      variant="subtitle1"
                      className={classes.subtitle}
                    >
                      {fromAcceptInvitation
                        ? t('auth:landing.subtitle.afterAcceptInvitation')
                        : t('auth:landing.subtitle.afterRegister')}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <Divider className={classes.lastDivider} />
          <div className={classes.footer}>
            {t('auth:landing.footer.1')}{' '}
            <a href="https://brix2bytes.io" className={classes.footerLink}>
              brix2bytes
            </a>{' '}
            {t('auth:landing.footer.2')}
          </div>
        </FancyCard>
        <div className={classes.copyright}>
          <Typography variant="caption">{t('general:companyInfo')}</Typography>
        </div>
      </div>
    </Container>
  );
};
