import React from 'react';
import { Button, Container, Divider, Grid, Typography } from '@mui/material';
import { createClasses, LogoKP } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { ActionsBar } from '../../../components/ActionsBar';
import { InfoList } from '../../../components/InfoList';
import { InfoListItem } from '../../../components/InfoListItem';
import { FancyCard } from '../../../components/FancyCard';
import { Color } from '../../../constants/Colors';

const classes = createClasses({
  root: {
    textAlign: 'left',
  },
  content: {
    padding: '4px 16px 8px 16px',
  },
  footer: {
    padding: '8px 16px 28px 16px',
  },
  actions: {
    marginTop: 0,
    marginBottom: 0,
  },
  subtitle: {
    padding: '16px 16px 0 16px',
  },
  lastDivider: {
    margin: '16px 0 16px 0',
  },
  detailItem: {
    paddingLeft: 0,
  },
  detailList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  error: {
    color: 'red',
    padding: '32px 16px 0 16px',
  },
  copyright: {
    marginTop: '15px',
    textAlign: 'center',
    color: Color.copyright,
  },
  logo: {
    display: 'flex',
    marginBottom: '15px',
    justifyContent: 'center',
  },
});

interface AuthAcceptInvitationProps {
  onSubmit: () => void;
  email: string;
  tenant: string;
  loading?: boolean;
  error?: string;
}

export const AuthAcceptInvitation: React.FC<AuthAcceptInvitationProps> = ({
  onSubmit,
  email,
  tenant,
  loading,
  error,
}) => {
  const { t } = useTranslation(['auth', 'general']);

  return (
    <Container maxWidth="sm">
      <div className={classes.root}>
        <div className={classes.logo}>
          <LogoKP background="dark" />
        </div>
        <FancyCard title={t('auth:acceptInvitation.title')} loading={loading}>
          {error && (
            <Typography variant="body2" className={classes.error}>
              {error}
            </Typography>
          )}
          <Typography variant="subtitle1" className={classes.subtitle}>
            {t('auth:acceptInvitation.subtitle')}
          </Typography>
          <Divider className={classes.lastDivider} />
          <div className={classes.content}>
            <Grid container spacing={1} direction="row">
              <Grid item xs={12} md={12}>
                <InfoList className={classes.detailList}>
                  <InfoListItem
                    term={t('auth:acceptInvitation.detail.email')}
                    itemClassName={classes.detailItem}
                  >
                    {email}
                  </InfoListItem>
                  <InfoListItem
                    term={t('auth:acceptInvitation.detail.tenant')}
                    itemClassName={classes.detailItem}
                  >
                    {tenant}
                  </InfoListItem>
                </InfoList>
              </Grid>
            </Grid>
          </div>
          <Divider className={classes.lastDivider} />
          <div className={classes.footer}>
            <ActionsBar className={classes.actions}>
              <Button
                onClick={onSubmit}
                disabled={loading}
                color="secondary"
                variant="outlined"
                size="large"
                aria-label="accept-button"
              >
                {t('auth:acceptInvitation.submit')}
              </Button>
            </ActionsBar>
          </div>
        </FancyCard>
        <div className={classes.copyright}>
          <Typography variant="caption">{t('general:companyInfo')}</Typography>
        </div>
      </div>
    </Container>
  );
};
